/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  ManageAccounts,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../../components/Tabs/TabPanel";
import { CustomerInfo } from "./CustomerInfo/CustomerInfo";
import { PriorPolicy } from "./PriorPolicy/PriorPolicy";
import { PolicyAdmin } from "./PolicyAdmin/PolicyAdmin";
import { Premium } from "./Premium/Premium";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { fetchCustomerInfoAsync } from "../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useDispatchWrapper } from "../../../../../../hooks";
import { BusinessActivityList } from "./BusinessTask/BusinessTaskList";
import { TabsList } from "../../../../../../components/Tabs/TabsList";
import { useParams } from "react-router-dom";
import { BusinessAttachmentList } from "./Attachment/AttachmentList";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { BusinessPolicyAdminManager } from "./PolicyAdminManager/PolicyAdminManager";
import { usePremissions } from "../../../../../../security/PremissionsProvider/PremissionsProvider";

export const BusinessPolicyBasicDetails = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const [tab, setTab] = React.useState(0);
  const { addBreadcrumb } = useBreadcrumbContext();
  const { premissionsList } = usePremissions();
  const showAdminManager = premissionsList.find((x) => {
    if (
      x.module_id === MODULE_IDS.BUSINESS_POLICY_ADMIN_MANAGER &&
      x.show_module
    ) {
      return true;
    }
    return false;
  });
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title:
        tab === 0
          ? "customer-info"
          : tab === 1
          ? "prior-policy"
          : tab === 2
          ? "policy-form"
          : "premium",
    },
  ];

  const { customerInfo, customerPoliycId: savedCustomerPolicyId } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );
  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: BCrumb,
    });
  }, [tab]);

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  if (!customerId) {
    return null;
  }

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={tab}
          tabs={[
            { label: "Customer Info", icon: <AccountCircle /> },
            {
              label: "Prior Policy",
              icon: <AdminPanelSettings />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Policy Admin",
              icon: <StickyNote2 />,
              disabled: !savedCustomerPolicyId,
            },
            {
              label: "Policy Admin Manager",
              icon: <ManageAccounts />,
              disabled: !savedCustomerPolicyId,
              hidden: !showAdminManager,
            },
            {
              label: "Premium",
              icon: <Receipt />,
              disabled: !savedCustomerPolicyId,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={tab} index={0}>
          <CustomerInfo
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        {savedCustomerPolicyId && (
          <>
            <TabPanel value={tab} index={1}>
              <PriorPolicy customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <PolicyAdmin customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <BusinessPolicyAdminManager
                customerpolicyId={savedCustomerPolicyId}
              />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Premium customerpolicyId={savedCustomerPolicyId} />
            </TabPanel>
          </>
        )}
      </StandadCard>
    </PageLoader>
  );
};
