import { useSelector } from "react-redux";
import { IStoreState } from "../redux/initialStoreState";

export const useCustomerPolicyId = () =>
  useSelector(
    (storeState: IStoreState) =>
      storeState.business.basicDetails.customerPoliycId || 0
  );

export const useIsAutomobileNewPolicy = () =>
  useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.isNewPolicy
  );

export const useIsHabitationalNewPolicy = () =>
  useSelector(
    (storeState: IStoreState) => storeState.habitational.basicDetails.isNewPolicy
  );

export const useIsLifeInsuranceNewPolicy = () =>
  useSelector(
    (storeState: IStoreState) =>
      storeState.lifeInsurance.basicDetails.isNewPolicy
  );
