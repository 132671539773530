import { LoadState } from "../../../constants/enums";

export interface IAccountingBillState {
  list: IAccountingBill[];
  listLoading: LoadState;
  data: IAccountingBill;
  loading: LoadState;
  error: string | null;
}

export interface IAccountingBillDetail {
  key: string;
  detail: string;
  account: string;
  description: string;
  tax_percentage: string;
  tax_amount: string;
  amount: string;
}

export interface IAccountingBill {
  customer_id: string | null;
  customer_policy_id: string | null;
  bill_id: string | null;
  bill_type: string | null;
  vendor: string | null;
  category: string | null;
  bill_date: string | null;
  due_date: string | null;
  payment_terms: string | null;
  bill_details: IAccountingBillDetail[];
  bill_information: string | null;
  bill_notes: string | null;
  bill_footer_information: string | null;
  sub_total_amount: string | null;
  discount_percentage: string | null;
  discount_amount: string | null;
  adjustment_percentage: string | null;
  adjustment_amount: string | null;
  total_amount: string | null;
  status: string;
}

export const initialAccountingBillState: IAccountingBillState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    customer_id: null,
    customer_policy_id: null,
    bill_id: null,
    bill_type: null,
    vendor: null,
    category: null,
    bill_date: null,
    due_date: null,
    payment_terms: null,
    bill_details: [],
    bill_information: null,
    bill_notes: null,
    bill_footer_information: null,
    sub_total_amount: null,
    discount_percentage: null,
    discount_amount: null,
    adjustment_percentage: null,
    adjustment_amount: null,
    total_amount: null,
    status: "Active",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
