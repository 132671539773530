import { LoadState } from "../../../constants/enums";

export interface IBillPaymentPayableState {
  data: IBillPaymentPayable;
  loading: LoadState;
  list: IBillPaymentPayable[];
  listLoading: LoadState;
  error: any;
}

export interface IBillPaymentDetails {
  key: string;
  date: string;
  bill_number: string;
  bill_amount: string;
  amount_due: string;
  payment: string;
}

export interface IBillPaymentPayable {
  create_payment_primary_id: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;
  payment_id: string | null;
  vendor: string | null;
  payment_made: string | null;
  bank_charges: string | null;
  payment_method: string | null;
  paid_through_account: string | null;
  reference_id: string | null;
  bill_payment_details: IBillPaymentDetails[];
  total_amount: string | null;
  amount_paid: string | null;
  amount_used_for_payments: string | null;
  amount_refunded: string | null;
  amount_in_excess: string | null;
  notes: string;
}

export const initialBillPaymentPayableState: IBillPaymentPayableState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    create_payment_primary_id: null,
    customer_id: null,
    customer_policy_id: null,
    payment_id: null,
    vendor: null,
    payment_made: null,
    bank_charges: null,
    payment_method: null,
    paid_through_account: null,
    reference_id: null,
    bill_payment_details: [],
    total_amount: null,
    amount_paid: null,
    amount_used_for_payments: null,
    amount_refunded: null,
    amount_in_excess: null,
    notes: "",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
