import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { IhabitationalStepOne } from "./habitationalApplication.types";

export const FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS =
  "FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS";
export const FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS =
  "FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS";
export const FETCH_HABITATIONAL_APPLICATION_LIST_FAILED =
  "FETCH_HABITATIONAL_APPLICATION_LIST_FAILED";

export const fetchHabitationalApplicationListProgress = () =>
  action(FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS);
export const fetchHabitationalApplicationListSuccess = (
  data: IhabitationalStepOne[]
) => action(FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS, { data });
export const fetchHabitationalApplicationListFailed = () =>
  action(FETCH_HABITATIONAL_APPLICATION_LIST_FAILED);

export const fetchHabitationalApplicationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalApplicationListProgress());
      const res = await api.get(
        `/policy/get-policy-habitational-application?customer_policy_id=${customerPolicyId}`
      );
      const data: IhabitationalStepOne[] = res.data.data;
      console.log("data from list view", data);
      dispatch(fetchHabitationalApplicationListSuccess(data));
    } catch (err: any) {
      dispatch(fetchHabitationalApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_HABITATIONAL_APPLICATION_STEP_ONE_PROGRESS =
  "FETCH_HABITATIONAL_APPLICATION_STEP_ONE_PROGRESS";
export const FETCH_HABITATIONAL_APPLICATION_STEP_ONE_SUCCESSS =
  "FETCH_HABITATIONAL_APPLICATION_STEP_ONE_SUCCESSS";
export const FETCH_HABITATIONAL_APPLICATION_STEP_ONE_FAILED =
  "FETCH_HABITATIONAL_APPLICATION_STEP_ONE_FAILED";

export const fetchHabitationalApplicationStepOneProgress = () =>
  action(FETCH_HABITATIONAL_APPLICATION_STEP_ONE_PROGRESS);
export const fetchHabitationalApplicationStepOneSuccess = (
  dataStepOne: IhabitationalStepOne
) => action(FETCH_HABITATIONAL_APPLICATION_STEP_ONE_SUCCESSS, { dataStepOne });
export const fetchHabitationalApplicationStepOneFailed = (
  errorMessage: string
) => action(FETCH_HABITATIONAL_APPLICATION_STEP_ONE_FAILED, { errorMessage });

export const fetchHabitationalApplicationStepOneAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchHabitationalApplicationStepOneProgress());
      const res = await api.get(
        `/policy/get-policy-habitational-application?customer_policy_id=${customerPolicyId}`
      );

      const data: IhabitationalStepOne[] = res.data.data;
      if (data.length > 0) {
        const firstElement: IhabitationalStepOne = data[0];

        dispatch(fetchHabitationalApplicationStepOneSuccess(firstElement));
      } else {
        dispatch(
          fetchHabitationalApplicationStepOneFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchHabitationalApplicationStepOneFailed("Some error occured"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addHabitationalApplicationStepOne =
  (
    dataStepOne: IhabitationalStepOne,
    customerPolicyId: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/create-policy-habitational-application", {
        ...dataStepOne,
        customer_policy_id: customerPolicyId,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Habitational application data saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchHabitationalApplicationStepOneSuccess(dataStepOne));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const CLEAR_HABITATIONAL_APPLICATION_STEP_ONE_STATE =
  "CLEAR_HABITATIONAL_APPLICATION_STEP_ONE_STATE";
export const clearHabitationalApplicationStepOneState = () =>
  action(CLEAR_HABITATIONAL_APPLICATION_STEP_ONE_STATE);
