/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Grid, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LocationAutoComplete } from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import { useFormik } from "formik";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileInterestedParty } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicle.types";
import { fetchCommAutomobileIntrestedPartieListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";
import { CommAutomobileInterested } from "./CommAutoVehicleInterested";
import { renderDropdownValue } from "../../../../../../../helpers";

const INITIAL_STATE: ICommAutomobileInterestedParty = {
  customer_policy_id: 0,
  vehicle_id: null,
  number: null,
  ownership: null,
  owner_operator_or_lessee: null,
  interested_party_code: null,
  interest: null,
  name: null,
  address: null,
  city: null,
  unit_or_suite: null,
  postal_code: null,
  country: null,
  province_or_state: null,
  status: "ACTIVE",
  interested_party_name: null,
};

export const CommAutomobileVehicleInterestedList: React.FC<{
  customerId: number;
  customerPolicyId: number;
  vehicleNumber: number;
}> = (props) => {
  const { customerPolicyId, vehicleNumber } = props;

  const { interestedPartyList, interestedPartyListLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.vehicle.interestedParty
  );
  const [open, setOpen] = React.useState<ICommAutomobileInterestedParty | null>(
    null
  );

  const dispatch = useDispatchWrapper();

  const { interestedParty } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.vehicle
  );
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: interestedParty.data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        // dispatch(
        //   addAutomobileInterestedAsync(
        //     { ...values, customer_policy_id: customerId, number: customerId },
        //     () => {}
        //   )
        // );
      },
    });

  const handleComplete = () => {
    dispatch(fetchCommAutomobileIntrestedPartieListAsync(customerPolicyId));
    setOpen(null);
  };

  const automobileInterestedTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: interestedPartyListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  setOpen({ ...INITIAL_STATE, vehicle_id: vehicleNumber });
                }}
              >
                Add Interested Party
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "number",
        headerName: "#",
        fieldName: "number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row: ICommAutomobileInterestedParty) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(row);
              }}
            >
              {row.number}
            </Link>
          );
        },
      },

      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row: ICommAutomobileInterestedParty) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(row);
              }}
            >
              {row.name}
            </Link>
          );
        },
      },

      {
        key: "interested_party_code",
        headerName: "Interested Party Code",
        fieldName: "interested_party_code",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
      },
      {
        key: "Interest",
        headerName: "Interest",
        fieldName: "interest",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
      },

      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileInterestedParty) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                setOpen(row);
              }}
            />
          );
        },
      },
    ],
    items: interestedPartyList,
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileIntrestedPartieListAsync(customerPolicyId));
  }, [customerPolicyId]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Ownership</CustomFormLabel>
          <CustomTextField
            name="ownership"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.ownership}
            onChange={handleChange}
          />
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <CustomFormLabel>Owner Operator or Lease</CustomFormLabel>
          <LocationAutoComplete
            name="owner_operator_or_lessee"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.owner_operator_or_lessee}
            onLocationChange={(data) =>
              setFieldValue("owner_operator_or_lessee", data.address)
            }
          />
        </Grid> */}
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Owner Operator or Lessee</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="owner_operator_or_lessee"
            value={values.owner_operator_or_lessee}
            onChange={handleChange}
            displayEmpty
            fullWidth
            renderValue={renderDropdownValue(`Select one`)}
          >
            {["Owner Operator", "Lessee"].map((selectValue) => {
              return <MenuItem value={selectValue}>{selectValue}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <DataTable {...automobileInterestedTableProps} />
      </Box>
      {open && (
        <CommAutomobileInterested
          open={true}
          customerPolicyId={customerPolicyId}
          data={open}
          onClose={() => setOpen(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
