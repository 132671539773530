/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { TableSkeleton } from "../../../TableSkeleton";
import clsx from "clsx";
import "../../DataTable.css";
import { TablePaginationActions } from "../../TablePaginationActions";
import { ICustomTableProps } from "./interfaces/ICustomTableProps";
import { CustomCheckbox } from "../../../formsComponents";
import { DeleteOutline } from "@mui/icons-material";
import { SelectAll } from "../SelectAll/SelectAll";
import { TABLE_ROW_TYPE } from "../../interfaces/IDataTableProps";
import { getUniqueId } from "../../../../helpers";

export const CustomTable: React.FC<ICustomTableProps> = (props) => {
  const {
    columns,
    items,
    selectedRows,
    selectType,
    uniqueRowKeyName,
    isDataLoading,
    loaderSkeletonRows,
    selectionMode,
    isPagination,
    rowsPerPageOptions,
    totalRecords,
    onRenderColumnHeader,
    onTableRowCellRender,
    onPageChange,
    onRowsPerPageChange,
    onSelectionAllRows,
    onSelectRow,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions);
  const currentPageItems =
    !totalRecords && rowsPerPage > 0
      ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : items;

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (onPageChange) {
      onPageChange(newPage + 1);
    }
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(
        1,
        rowsPerPage === -1 ? totalRecords || items.length : rowsPerPage
      );
    }
    setRowsPerPage(rowsPerPage);
    setPage(0);
  };

  return (
    <Card
      className="tableScroll"
      sx={{
        borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 3px",
        border: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: 0,
        marginTop: 0,
      }}
    >
      <CardContent sx={{ padding: "0px !important" }}>
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {(selectedRows.length > 0 || selectType === "AllPages") && (
            <Box
              sx={{
                padding: 2,
                borderRadius: 1,
                m: 2,
                mt: 0,
                backgroundColor: "primary.light",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  color="primary.main"
                >
                  {`${
                    selectType === "AllPages"
                      ? totalRecords
                      : selectedRows.length
                  } Row's Selected`}
                </Typography>
                <DeleteOutline
                  sx={{ ml: 1, cursor: "pointer" }}
                  fontSize="small"
                  color="primary"
                  onClick={() => onSelectionAllRows(false)}
                />
              </Box>
            </Box>
          )}
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            <TableHead>
              <TableRow>
                {selectionMode === "multiple" && (
                  <TableCell key="selection">
                    <SelectAll
                      checkedType={selectType}
                      onChange={onSelectionAllRows}
                    />
                  </TableCell>
                )}
                {columns.map((column) => {
                  return (
                    <TableCell key={column.key}>
                      {onRenderColumnHeader(column)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataLoading && (
                <TableSkeleton
                  numberOfCells={
                    selectionMode !== "none"
                      ? columns.length + 1
                      : columns.length
                  }
                  numberOfRows={loaderSkeletonRows}
                />
              )}

              {!isDataLoading &&
                currentPageItems.map((row, index) => {
                  const columnsIndexes = Array.from(
                    Array(columns.length).keys()
                  );

                  return (
                    <CTableRow
                      key={
                        uniqueRowKeyName ? row[uniqueRowKeyName] : getUniqueId()
                      }
                      uniqueRowKeyName={uniqueRowKeyName}
                      onTableRowCellRender={onTableRowCellRender}
                      index={index}
                      row={row}
                      selectionMode={selectionMode}
                      selectType={selectType}
                      selectedRows={selectedRows}
                      onSelectRow={onSelectRow}
                      columnsIndexes={columnsIndexes}
                      columns={columns}
                    />
                  );
                })}
            </TableBody>
          
          </Table>
          {isPagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={props.paginationList}
                    sx={{
                      borderBottom: 0,
                     
                    }}
                    colSpan={columns.length + 1}
                    count={totalRecords || items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    labelDisplayedRows={(data) => {
                      return (
                        <>
                          {data.from}-{data.to}
                        </>
                      );
                    }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
        </Box>
      </CardContent>
    </Card>
  );
};

const CTableRow = React.memo(
  ({
    uniqueRowKeyName,
    onTableRowCellRender,
    index,
    row,
    selectionMode,
    selectType,
    selectedRows,
    onSelectRow,
    columnsIndexes,
    columns,
  }: any) => {
    const data = React.useMemo(() => {
      return (
        <TableRow
          sx={{
            ":hover": {
              background: "rgba(0, 0, 0, 0.03)",
            },
          }}
          className={clsx({ "row-disabled": row.isDisabled })}
        >
          {selectionMode !== "none" && (
            <TableCell>
              <CustomCheckbox
                disabled={selectType === "AllPages"}
                checked={
                  selectedRows.findIndex(
                    (x: any) => x[uniqueRowKeyName] === row[uniqueRowKeyName]
                  ) > -1 || selectType === "AllPages"
                }
                onChange={onSelectRow(row)}
              />
            </TableCell>
          )}
          {columnsIndexes.map((columnIndex: any) => {
            const column = columns[columnIndex];
            const rowConfig = row.rowCellsConfig;
            if (rowConfig && rowConfig[column.fieldName]) {
              const rowCellConfig = rowConfig[column.fieldName];
              if (rowCellConfig.isHidden) {
                return <></>;
              }
              return (
                <TableCell colSpan={rowCellConfig.colSpan}>
                  {onTableRowCellRender(row, columnIndex, index)}
                </TableCell>
              );
            }
            return (
              <TableCell>
                {onTableRowCellRender(row, columnIndex, index)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }, [row, index, selectionMode, selectType, selectedRows, uniqueRowKeyName]);

    return <>{data}</>;
  }
);
