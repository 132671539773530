/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react/jsx-pascal-case

import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import {
  AccountCircle,
  AdminPanelSettings,
  Receipt,
  StickyNote2,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchAutomobileVehicleListAsync } from "../../../../../redux/automobileVehicle/automobileVehicleActions";
import { fetchBindersListAsync } from "../../../../../redux/Binder/binderActions";
import { AutomobileAuthorizationList } from "./AutomobileAuthorizationForm/AuthorizationFormList";
import { AutomobileCancellationList } from "./AutomobileCancellation/AutomobileCancellationList";
import { fetchAutomobileCustomerInfoAsync } from "../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { LiabilitySlipsList } from "./LiabilitySlips/LiabilitySlipsList";
import { AutomobileCSIOCertificateList } from "./CSIOCertificate/CSIOCertificateList";
import { OPCF16List } from "./OPCF_16/OPCF16List";
import { OPCF17List } from "./OPCF_17/OPCF17List";
import { OPCF_28AList } from "./OPCF_28A/OPCF_28AList";

export const AutomobilePolicyFormsRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/automobile-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      //   dispatch(fetchBindersListAsync(customerPolicyId));
      dispatch(fetchAutomobileCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    addBreadcrumb({
      title: "",
      items: [
        {
          to: "/dashboard",
          title: "dashboard",
        },
        {
          to: "/view-customers",
          title: "customers",
        },
        {
          to: "/customer-overview/" + customerId,
          title: "customer-overview",
        },
        {
          to:
            "/habitational-policy/habitational/" +
            customerId +
            "/" +
            customerPolicyId,
          title: "binder",
        },
      ],
    });
  }, []);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            {
              label: "Liability Slips",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Certificate of Liability Insurance",
              icon: <AccountCircle />,
            },

            {
              label: "OPCF 16",
              icon: <Receipt />,
            },
            {
              label: "OPCF 17",
              icon: <AdminPanelSettings />,
            },
            {
              label: "OPCF 28A",
              icon: <StickyNote2 />,
            },
            {
              label: "Payment Authorization",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Cancellation",
              icon: <AccountCircle />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <LiabilitySlipsList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AutomobileCSIOCertificateList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <OPCF16List
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <OPCF17List
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <OPCF_28AList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={5}>
          <AutomobileAuthorizationList />
        </TabPanel>
        <TabPanel value={selectedTab} index={6}>
          <AutomobileCancellationList />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
