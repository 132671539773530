/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobilePolicyAuthorization } from "../../../../../../redux/commAutoPolicy/commAutoPolicyAuthorization/commAutoPolicyAuthorization.types";
import {
  downloadCommAutomobileAuthorizationPDFAsync,
  fetchCommAutomobileAuthorizationListAsync,
  previewCommAutomobileAuthorizationPDFAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoPolicyAuthorization/commAutoPolicyAuthorizationActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { NotificationsActive, ThumbUpAlt } from "@mui/icons-material";
import { CustomChip } from "../../../../../../components/CustomChip";

export const CommAutomobileAuthorizationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.authorization
  );
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [selectedAuth, setselectedAuth] = React.useState<{
    isAllSelected: boolean;
    certificate: ICommAutomobilePolicyAuthorization[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedAuth.certificate.length > 0 || selectedAuth.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const authorizationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "incremental_number",
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerpolicyId}/authorization`
                  );
                }}
              >
                Add Pre-Authorization Form
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobilePolicyAuthorization) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerpolicyId}/authorization/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobilePolicyAuthorization) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "account_number",
        headerName: "Account Number",
        fieldName: "account_number",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },

      {
        key: "insurance_company_name",
        headerName: "Insurance Company Name",
        fieldName: "insurance_company_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },

      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
      },

      {
        key: "broker_code",
        headerName: "Broker Code",
        fieldName: "broker_code",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "installment_fee",
        headerName: "Installment Fee",
        fieldName: "installment_fee",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "tax",
        headerName: "Tax",
        fieldName: "tax",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "mode_of_payment",
        headerName: "Mode of Payment",
        fieldName: "mode_of_payment",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "plan_type",
        headerName: "Plan Type",
        fieldName: "plan_type",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobilePolicyAuthorization) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewCommAutomobileAuthorizationPDFAsync(
                    customerpolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadCommAutomobileAuthorizationPDFAsync(
                      customerpolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/policy-forms-tab/${customerpolicyId}/authorization/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
    onSelection: (
      isAllSelected: boolean,
      rows: ICommAutomobilePolicyAuthorization[]
    ) => {
      setselectedAuth({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileAuthorizationListAsync(customerpolicyId));
  }, [customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...authorizationTableProps} />
      </Box>
    </>
  );
};
