import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IAccountingBankAccount,
  initialAccountingBankAccountState,
} from "./BankAccount.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";

export const FETCH_ACCOUNTING_BANK_ACCOUNT_PROGRESS =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_PROGRESS";
export const FETCH_ACCOUNTING_BANK_ACCOUNT_SUCCESS =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNTING_BANK_ACCOUNT_FAILURE =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_FAILURE";

export const fetchAccountingBankAccountProgress = () =>
  action(FETCH_ACCOUNTING_BANK_ACCOUNT_PROGRESS);

export const fetchAccountingBankAccountSuccess = (
  data: IAccountingBankAccount
) => action(FETCH_ACCOUNTING_BANK_ACCOUNT_SUCCESS, { data });

export const fetchAccountingBankAccountFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BANK_ACCOUNT_FAILURE, { errorMessage });

export const fetchAccountingBankAccountAsync =
  (banAccCode: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBankAccountProgress());

      const res = await api.get(
        `/accounting/get-account-bank-account?bank_account_code=${banAccCode}`
      );
      const data: IAccountingBankAccount[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingBankAccountSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingBankAccountSuccess(
            initialAccountingBankAccountState["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAccountingBankAccountFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingBankAccountAysnc =
  (
    data: IAccountingBankAccount,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      isDuplicate
        ? await api.post("/accounting/edit-account-bank-account", {
            ...data,
            bank_account_code: null,
          })
        : await api.post("/accounting/update-account-bank-account", {
            ...data,
          });
      let message = "Bank Account saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_PROGRESS =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_PROGRESS";
export const FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_SUCCESS =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_SUCCESS";
export const FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_FAILURE =
  "FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_FAILURE";

export const fetchAccountingBankAccountListProgress = () =>
  action(FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_PROGRESS);

export const fetchAccountingBankAccountListSuccess = (
  data: IAccountingBankAccount[]
) => action(FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_SUCCESS, { data });

export const fetchAccountingBankAccountListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BANK_ACCOUNT_LIST_FAILURE, { errorMessage });

export const fetchAccountingBankAccountListAsync =
  (
    searchType: string[],
    search: string,
    status: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBankAccountListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/accounting/get-account-bank-account?${url}`;
      if (searchType.length > 0 && search) {
        finalUrl = finalUrl + `&column=${searchType}&value=${search}`;
      }
      const res = await api.get(finalUrl);
      const data: IAccountingBankAccount[] = res.data.data;
      dispatch(fetchAccountingBankAccountListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingBankAccountListFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_BANK_ACCOUNT = "CLEAR_ACCOUNTING_BANK_ACCOUNT";
export const clearAccountingBankAccount = () =>
  action(CLEAR_ACCOUNTING_BANK_ACCOUNT);
