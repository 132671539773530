import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { fetchBusinessPolicyContractorListAsync } from "../../../../../../redux/businessPolicy/businessPolicyContractor/businessPolicyContractorActions";
import { IBusinessPolicyContractor } from "../../../../../../redux/businessPolicy/businessPolicyContractor/businessPolicyContractor.type";

export const BusinessPolicyContractorList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.contractor
  );
  const dispatch = useDispatchWrapper();
  const contractorTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/questionnare-tab/${customerPolicyId}/contractor`
                  );
                }}
              >
                Add Contractor
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyContractor) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/questionnare-tab/${customerPolicyId}/contractor/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "applicant_operating_under_the_present_name",
        headerName: "Since how long applicant operating under the present name",
        fieldName: "applicant_operating_under_the_present_name",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
      
      },

      {
        key: "previous_operating_name_if_any",
        headerName: "Previous Operating Name, if any?",
        fieldName: "previous_operating_name_if_any",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
     
      },
      {
        key: "years_of_experience_in_the_similar_operations",
        headerName: "Years of Experience in the similar operations",
        fieldName: "years_of_experience_in_the_similar_operations",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
     
      },
      {
        key: "applicant_operating_under_the_present_name",
        headerName: "Operating Under Name",
        fieldName: "applicant_operating_under_the_present_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyContractor) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/questionnare-tab/${customerPolicyId}/contractor/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyContractorListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...contractorTableProps} />
      </Box>
    </>
  );
};
