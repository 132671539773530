import { LoadState } from "../../constants/enums";
import { IPolicyCommissionState } from "./policyCommission.types";

export const initialPolicyCommissioneState: IPolicyCommissionState = {
  policyCommission: {
    data: {
      policy_commission_id: null,
      policy_commission_primary_id: null,
      insurer_code_id: 2,
      policy_code: "",
      description: null,
      insurer_code_with_name: null,
      producer_code: null,
      contract_number: null,
      commission_percentage_new: null,
      house_portion_percentage_new: null,
      producer_portion_percentage_new: null,
      commission_percentage_renewal: null,
      house_portion_percentage_renewal: null,
      producer_portion_percentage_renewal: null,
      status: "ACTIVE",
      insert_ts: "",
    },
    loading: LoadState.NotLoaded,
    saveLoading: false,
    error: null,
  },
};
