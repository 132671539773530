import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../../api/api";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import {
  ControlledCustomSelect,
  CustomSelect,
} from "../../../components/formsComponents";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { capitalizeWords, renderDropdownValue } from "../../../helpers";
import {
  IInsuranceReport,
  INoPolicyInsuranceReports,
} from "./insuranceReport.types";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { IInsuranceReports } from "../../../redux/insuranceReports/insuranceReports.types";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import {
  fetchInsuranceReportsListAsync,
  fetchNoPolicyInsuranceReportsListAsync,
} from "../../../redux/insuranceReports/insuranceReportsActions";
import { Button, MenuItem, Stack } from "@mui/material";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../redux/common/commonActions";
import { LeadOppStatus } from "../../../components/LeadOppStatus/LeadOppStatus";
import { AddOutlined } from "@mui/icons-material";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { InsuranceReportDialog } from "./InsuranceReportsDialog/InsuranceReportsDialog";
// import PolicyTypeCard

const INITIAL_STATE = {
  // customer_policy_id: 0,
  customer_name: "",
  customer_code: "",
  driver_name: "",
  license_number: "",
  business_reason: "Verify Driver Record",
  date_and_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  days_left: "",
  transaction_type: "",
  report_requested: "",
  request_status: "",
  insurer_code: "",
  intact_branch: "",
  insurer_code_written: "",
  number_of_disclosed_convictions: "",
  policy_number: "",
  policy_status_cancelled: "",
  requested_by: "",
  assigned_to: "",
  branch: "",
  producer: "",
  csr: "",
  notes: "",
  file_type: "",
  file_type_two: "",
  file_upload: "",
  file_upload_two: "",
  date_wriitten: "",
  date_destroyed: "",
  date_voided: "",
  date_cancelled: "",
  status: "ACTIVE",
  incremental_number: null,
  years_at_this_address: "",
  current_address_type: "",
  current_address: "",
  current_address_unit_or_suite: "",
  current_address_city: "",
  current_address_state_or_province: "",
  current_address_postal_code: "",
  current_address_country: "",
  new_address_type: "",
  new_address: "",
  new_address_unit_or_suite: "",
  new_address_city: "",
  new_address_state_or_province: "",
  new_address_postal_code: "",
  new_address_country: "",
};

export const NoPolicyInsuranceReportList: React.FC<{
  onChange: (newTab: number) => void;
}> = (props) => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "insurance-reports",
    },
  ];

  const { noPolicyList, noPolicyLoading, noPolicyTotalRecords } = useSelector(
    (storeState: IStoreState) => storeState.insuranceReports
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: IInsuranceReports[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    IInsuranceReports["status"] | "-1"
  >("-1");

  const [openView, setOpenView] =
    React.useState<INoPolicyInsuranceReports | null>(null);

  const [searchType, setSearchType] = React.useState<string[]>(["driver_name"]);
  const [search, setSearch] = React.useState<string>("");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();

  const fetchList = async () => {
    dispatch(
      fetchNoPolicyInsuranceReportsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  };

  const handleComplete = () => {
    fetchList();
    setOpenView(null);
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search, searchType]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const insuranceTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: noPolicyTotalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: noPolicyLoading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
                moduleId={MODULE_IDS.INSURANCE_REPORTS}
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: ["opportunity_id", "customer_id", "lead_id", "status"],
          //   items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Driver Name", value: "driver_name" },
                    { label: "License Number", value: "license_number" },
                    { label: "Transaction Type", value: "transaction_type" },
                    { label: "Report Requested", value: "report_requested" },
                    { label: "Report Status", value: "report_status" },
                    { label: "Requested By", value: "requested_by" },
                    { label: "Policy Number", value: "policy_number" },
                    { label: "Branch", value: "branch" },
                    { label: "Producer", value: "producer_code" },
                    { label: "Date Written", value: "date_wriitten" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onViewClick={() => {}}
              onEditClick={() => {
                navigate(`/insurance-reports/no-policy/${row.license_number}`);
              }}
            />
          );
        },
      },
      {
        key: "customer_policy_id",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: INoPolicyInsuranceReports) => {
          return (
            <CustomLink
              to={`/insurance-reports/no-policy/${row.license_number}`}
              label={value.value}
            />
          );
        },
      },
      {
        key: "license_number",
        headerName: "License Number",
        fieldName: "license_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "business_reason",
        headerName: "Business Reason",
        fieldName: "business_reason",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "branch",
        headerName: "Branch",
        fieldName: "branch",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "producer_code",
        headerName: "Producer",
        fieldName: "producer_code",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "csr_code",
        headerName: "CSR",
        fieldName: "csr_code",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: INoPolicyInsuranceReports) => {
          return <LeadOppStatus status={row.request_status || ""} />;
        },
      },

      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        renderType: RenderType.TEXT,
      },
      {
        key: "days_left",
        headerName: "Days Left",
        fieldName: "days_left",
        renderType: RenderType.TEXT,
      },
      {
        key: "insert_ts",
        headerName: "Date Created",
        fieldName: "insert_ts",
        renderType: RenderType.DATE_TIME,
      },
    ],
    items: noPolicyList,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: IInsuranceReports[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_policy_no_policy_driver_insurance_reports",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer
      title="View Insurance Report"
      description="this is innerpage"
    >
      <IconicMultiColumnCard
        loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      />

      <StandadCard
        heading="No Policy Insurance Reports"
        rightHeading={
          <Stack direction={"row"} spacing={2}>
            <ControlledCustomSelect
              sx={{ minWidth: "300px" }}
              value={"1"}
              options={[
                {
                  label: "Comm-Auto & Automobile Insurance Reports",
                  value: "0",
                },
                { label: "No Policy Insurance Reports", value: "1" },
              ]}
              onChange={(e) => props.onChange(0)}
            />
            <Button
              variant="contained"
              size="small"
              startIcon={<AddOutlined />}
              onClick={() =>
                setOpenView(INITIAL_STATE as INoPolicyInsuranceReports)
              }
            >
              Order Insurance Reports
            </Button>
          </Stack>
        }
      >
        <DataTable {...insuranceTableProps} />
      </StandadCard>
      {openView && (
        <InsuranceReportDialog
          open={true}
          onClose={() => setOpenView(null)}
          data={openView}
          onComplete={handleComplete}
        />
      )}
    </PageContainer>
  );
};
