import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import {
  fetchTaskPolicyReinstmentAysnc,
} from "../../../../redux/taskUrgentTransactions/taskUrgentTransactionsActions";
import {
  ITaskPolicyReinstment,
} from "../../../../redux/taskUrgentTransactions/taskUrgentTransactions.types";

export const ViewReinstatement: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.urgentTransactions.policyReinstment
  );

  const dispatch = useDispatchWrapper();
  const [reinstatementDetails, setReinstatementDetails] =
    React.useState<ITaskPolicyReinstment>();

  React.useEffect(() => {
    dispatch(fetchTaskPolicyReinstmentAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setReinstatementDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {reinstatementDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: reinstatementDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: reinstatementDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: reinstatementDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: {
                    type: "text",
                    value: reinstatementDetails.line_of_business,
                  },
                },

                {
                  heading: "Policy Type",
                  data: {
                    type: "text",
                    value: reinstatementDetails.policy_type,
                  },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: reinstatementDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: reinstatementDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: reinstatementDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: reinstatementDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: reinstatementDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Reinstatement Request"
              colSize={3}
              list={[
                {
                  heading: "Reinstatement Date",
                  data: {
                    type: "text",
                    value: formatDate(
                      reinstatementDetails.reinstatement_date || ""
                    ),
                  },
                },
                {
                  heading: "Reinstatement Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      reinstatementDetails.reinstatement_time || ""
                    ),
                  },
                },
                {
                  heading: "Payment Recieved",
                  data: {
                    type: "text",
                    value:
                      `${reinstatementDetails.full ? "Full" : "--"}` ||
                      `${reinstatementDetails.partial ? "Partial" : "--"}` ||
                      `${
                        reinstatementDetails.certified_cheque
                          ? "Certified Cheque"
                          : "--"
                      }`,
                  },
                },
                {
                  heading: "Balance Owing",
                  data: {
                    type: "text",
                    value: reinstatementDetails.balance_owing,
                  },
                },
                {
                  heading: "Reason for Reinstatement",
                  data: {
                    type: "text",
                    value: reinstatementDetails.reason_for_reinstatement,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
