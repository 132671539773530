/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Link } from "@mui/material";
import React from "react";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
// import { IMarketingBasicDetailsProps } from "./MarketingBasicdetailProps";
// import { MarketingDialog } from "./MarketingDialog";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IBusinessBinder,
} from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketing.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { LoadState } from "../../../../../../constants/enums";
import {
  fetchBusinessPolicyBinderistListAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyMarketing/businessPolicyMarketingActions";
import { BinderDialog } from "./BinderDialog";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { IBinderBasicDetailsProps } from "./BinderDialog.types";
import { BinderPreview } from "./BinderPreview";
import { DownLoadFile } from "../../../../../../helpers";
import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { downLoadFile } from "../../../../../../components/FileUpload/utils";

export const BinderList = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerpolicyId = data[1];
  const navigate = useNavigate();
  const [openBinder, setOpenBinder] = React.useState(false);

  const { list, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.business.marketing.binderHistory
  );
  const dispatch = useDispatchWrapper();
  const [openPreview, setOpenPreview] = React.useState<number | null>(null);
  const [downloadingLoading, setDownloading] = React.useState(false);

  const handlePreviewOpen = (customerPolicyId: number) => () => {
    setOpenPreview(customerPolicyId);
  };

  const handleDownload = (row: IBusinessBinder) => async () => {
    try {
      setDownloading(true);
      for (let item of row.attachment) {
        await downLoadFile(item);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        })
      );
    } finally {
      setDownloading(false);
    }
  };

  const marketingActivityTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  onClick={() => setOpenBinder(true)}
                >
                  Generate Binder
                </Button>
              </Box>
            ),
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "customer_policy_id",
        headerName: "Customer Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "created_by_id",
        headerName: "Created By",
        fieldName: "created_by_id",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "status",
        headerName: "Staus",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: IBusinessBinder) => {
          return (
            <StandardTableActions
            downloadLoading={downloadingLoading}
              onDownLoadClick={handleDownload(row)}
              onDownloadPreview={handlePreviewOpen(row.customer_policy_id)}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: list,
  };

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyBinderistListAsync(customerpolicyId));
  }, []);

  return (
    <>
      <PageLoader loading={false}>
        <Box sx={{ mt: 2 }}>
          <DataTable {...marketingActivityTableProps} />
        </Box>
      </PageLoader>
      {openBinder && (
        <BinderDialog
          open={openBinder}
          customerPolicyId={customerpolicyId}
          onClose={() => setOpenBinder(false)}
          onComplete={() => {}}
        />
      )}
      {openPreview && (
        <BinderPreview
          customerPolicyId={openPreview}
          open={true}
          onClose={() => setOpenPreview(null)}
          onComplete={() => {}}
        />
      )}
    </>
  );
};
