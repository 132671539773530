import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { IAddRiskLocation } from "../../../../redux/TaskReducer/RiskLocation/taskriskLocation.types";
import { fetchAddRiskLocationAysnc } from "../../../../redux/TaskReducer/RiskLocation/taskRiskLocationActions";
import { ITaskDeleteRiskLocation } from "../../../../redux/generalChange/generalChange.types";
import { fetchTaskDeleteRiskAysnc } from "../../../../redux/generalChange/generalChangeActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";

export const ViewDeleteRiskLocation: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { deleteData, deleteLoading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.riskLoaction
  );

  const dispatch = useDispatchWrapper();
  const [riskLocationDetails, setRiskLocationDetails] =
    React.useState<ITaskDeleteRiskLocation>();

  React.useEffect(() => {
    dispatch(fetchTaskDeleteRiskAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setRiskLocationDetails(deleteData);
  }, [deleteData]);

  return (
    <PageLoader loading={deleteLoading === LoadState.InProgress}>
      {riskLocationDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: riskLocationDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: riskLocationDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: riskLocationDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: {
                    type: "text",
                    value: riskLocationDetails.line_of_business,
                  },
                },

                {
                  heading: "Policy Type",
                  data: {
                    type: "text",
                    value: riskLocationDetails.policy_type,
                  },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: riskLocationDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: riskLocationDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: riskLocationDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: riskLocationDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: riskLocationDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Additional Information"
              colSize={3}
              list={[
                {
                  heading: "Risk Location",
                  data: {
                    type: "text",
                    value: riskLocationDetails.risk_location,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: riskLocationDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      riskLocationDetails.effective_time_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Details",
                  data: {
                    type: "text",
                    value: riskLocationDetails.change_details,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
