import React from "react";
import { CustomChip } from "../CustomChip";

export const LeadOppStatus: React.FC<{ status: string }> = (props) => {
  const { status } = props;

  switch (status) {
    case "ACTIVE": {
      return <CustomChip color="warning" content={"Active"} size="small" />;
    }
    case "NEW": {
      return <CustomChip color="info" content={"New"} size="small" />;
    }

    case "INPROGRESS": {
      return (
        <CustomChip color="success" content={"In-Process"} size="small" />
      );
    }

    case "CONVERTED": {
      return <CustomChip color="success" content={"Converted"} size="small" />;
    }

    case "SPAM": {
      return <CustomChip color="error" content={"Spam"} size="small" />;
    }

    case "DUPLICATE": {
      return (
        <CustomChip color="secondary" content={"Duplicate"} size="small" />
      );
    }

    case "DEAD": {
      return <CustomChip color="error" content={"Dead"} size="small" />;
    }

    case "RECYCLE": {
      return <CustomChip color="info" content={"Recycle"} size="small" />;
    }
    case "OPPORTUNITY": 
    return (
      <CustomChip color="success" content={"Opportunity"} size="small" />
    );
    case "Accepted": 
    return (
      <CustomChip color="success" content={"Accepted"} size="small" />
    )
    case "Declined": 
    return (
      <CustomChip color="error" content={"Declined"} size="small" />
    )
    case "Submitted": 
    return (
      <CustomChip color="secondary" content={"Submitted"} size="small" />
    )
    case "Waiting on Producer": 
    return (
      <CustomChip color="warning" content={"Waiting on Producer"} size="small" />
    )
    case null: 
    return (
      <></>
    )

    default: {
      return (
        <CustomChip color="info" content={status} size="small" />
      );
    }
  }
};
