/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { fetchCustomerInfoAsync } from "../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { StandadCard } from "../../../../../components/Cards";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { AuthorizationList } from "./AuthorizationForm/AuthorizationFormList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GarageCertificateList } from "./GarageCertificate/GarageCertificateList";
import { PropertyInsuranceCertificateList } from "./PropertyInsuranceCertificate/PropertyInsuranceCertificateList";
import { CSIOCertificateList } from "./CSIOCertificate/CSIOCertificateList";
import { BusinessCancellationList } from "./BusinessCancellation/BusinessCancellationList";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";

export const PolicyFormsRoot = () => {
  const data = useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const { getParm } = useQueryParms();
  const tabValue = getParm("tab");
  const selectedTab = tabValue ? Number(tabValue) : 0;
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: selectedTab === 0 ? 'liability-insurance' :  selectedTab === 1 ? 'commercial-property-insurance' :  selectedTab === 2 ? 'Garage Insurance':  selectedTab === 3 ? 'payment-authorization' :  'cancellation',
    },
  ];

  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails
  );

  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}?tab=${newValue}`
    );
  };
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, [selectedTab]);
  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  return (
    <PageLoader
      loading={customerInfo.loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            {
              label: "Certificate of Liability Insurance",
              icon: <AccountCircle />,
            },
            {
              label: "Commercial Property Insurance Certificate",
              icon: <AdminPanelSettings />,
            },
            {
              label: "Garage Insurance Certificate",
              icon: <AdminPanelSettings />,
            },
            { label: "Payment Authorization", icon: <AdminPanelSettings /> },
            { label: "Cancellation", icon: <AccountCircle /> },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <CSIOCertificateList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <PropertyInsuranceCertificateList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <GarageCertificateList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <AuthorizationList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
          <BusinessCancellationList
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
