import { Autocomplete, CircularProgress } from "@mui/material";
import React from "react";
import { CustomTextField } from "../formsComponents";
import { ISelectOption } from "../../constants/types";
import { debounce } from "lodash";
import { api } from "../../api/api";
import { IAutoCompleteSearchesProps } from "./AutoCompleteSearches.types";
import { IBranchOffice } from "../../redux/branchOffice/branchOffice.types";

export const BranchCodeAutoSearch: React.FC<IAutoCompleteSearchesProps> = (
  props
) => {
  const { value, onSelect,disabled } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<ISelectOption[]>([]);
  const [searchText, setSearchText] = React.useState("");

  const fetchSuggestion = debounce(async (value) => {
    setLoading(true);
    try {
      const res = await api.get(`/insurance/get-branch-office?status=ACTIVE&column=branch_code&column=name&value=${value}`);
      const data: IBranchOffice[] = res.data.data;
      const finalData: ISelectOption[] = [];
      for (const branchOffice of data) {
        if (branchOffice.branch_code) {
          finalData.push({
            label: `${branchOffice.name}`,
            value: branchOffice.branch_code,
          });
        }
      }
      setSearchResults(finalData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    fetchSuggestion(value);
    setSearchText(value);
  };
  const getOptionLabel = (option: string | ISelectOption) => {
    if (typeof option === "string") {
      return option;
    }
    return option.value.toString();
  };

  React.useEffect(() => {
    if (value) {
      setSearchText(value);
      fetchSuggestion(value);
    } else {
      setSearchText("");
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        options={searchResults.map((result) => ({
          label: result.label,
          value: result.value,
        }))}
        disabled={disabled}
        sx={{
            ".MuiOutlinedInput-root": {
                paddingTop: '2px',
                paddingBottom: '2px',
                fontSize: "0.8rem",
                color: "rgb(38, 38, 38)",
              },
          }}
        value={searchResults.find((option) => option.value === value) || null}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue && typeof newValue !== "string") {
            onSelect(newValue);
          }
          else{
            onSelect({label: '', value: ''})
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            value={searchText}
            onChange={handleChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
