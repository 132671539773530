import produce from "immer";
import { ApplicationsActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  initialAllApplicationState,
  initialApplication,
  initialUserOffboarding,
} from "./allApplications.types";
import {
  CLEAR_APPLICATION,
  FETCH_APPLICATIONS_LIST_FAILED,
  FETCH_APPLICATIONS_LIST_PROGRESS,
  FETCH_APPLICATIONS_LIST_SUCCESS,
  FETCH_APPLICATION_FAILED,
  FETCH_APPLICATION_PROGRESS,
  FETCH_APPLICATION_SUCCESS,
  FETCH_REQUEST_FORM_LIST_FAILED,
  FETCH_REQUEST_FORM_LIST_PROGRESS,
  FETCH_REQUEST_FORM_LIST_SUCCESS,
  FETCH_USER_OFF_BOARDING_FAILED,
  FETCH_USER_OFF_BOARDING_PROGRESS,
  FETCH_USER_OFF_BOARDING_SUCCESS,
  FETCH_USER_ON_BOARDING_FAILED,
  FETCH_USER_ON_BOARDING_PROGRESS,
  FETCH_USER_ON_BOARDING_SUCCESS,
} from "./allApplicationsActions";

export const applicationReducer = (
  state: IStoreState["allApplications"] = initialAllApplicationState,
  action: ApplicationsActions
) => {
  switch (action.type) {
    case FETCH_APPLICATIONS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPLICATIONS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });

      return newState;
    }
    case FETCH_APPLICATIONS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.applicationLoading = LoadState.InProgress;
        draftState.application = initialApplication;
      });
      return newState;
    }
    case FETCH_APPLICATION_SUCCESS: {
      const { application } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.applicationLoading = LoadState.Loaded;
        draftState.application = application;
      });
      return newState;
    }
    case FETCH_APPLICATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.applicationLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case FETCH_USER_OFF_BOARDING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.userOffBoardLoading = LoadState.InProgress;
        draftState.userOffBoard = initialUserOffboarding;
      });
      return newState;
    }
    case FETCH_USER_OFF_BOARDING_SUCCESS: {
      const { userOffBoarding } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userOffBoardLoading = LoadState.Loaded;
        draftState.userOffBoard = userOffBoarding;
      });
      return newState;
    }
    case FETCH_USER_OFF_BOARDING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userOffBoardLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case FETCH_USER_ON_BOARDING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.userOnBoardLoading = LoadState.InProgress;
        draftState.userOnBoard = initialAllApplicationState.userOnBoard;
      });
      return newState;
    }
    case FETCH_USER_ON_BOARDING_SUCCESS: {
      const { userOnBoarding } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userOnBoardLoading = LoadState.Loaded;
        draftState.userOnBoard = userOnBoarding;
      });
      return newState;
    }
    case FETCH_USER_ON_BOARDING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.userOnBoardLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    case FETCH_REQUEST_FORM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rquestFormLoading = LoadState.InProgress;
        draftState.requestFormList = [];
      });
      return newState;
    }
    
    case FETCH_REQUEST_FORM_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rquestFormLoading = LoadState.Loaded;
        draftState.requestFormList = list;
      });
      return newState;
    }

    case FETCH_REQUEST_FORM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.rquestFormLoading = LoadState.Failed;
        draftState.requestFormList = [];
      });
      return newState;
    }
    case CLEAR_APPLICATION: {
      return initialAllApplicationState;
    }

    default: {
      return state;
    }
  }
};
