import React from "react";
import { StandadCard } from "../../../../components/Cards";
import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import { a11yProps } from "../../../../components/Tabs/utils";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import { ViewAddDriver } from "./ViewAddDriver";
import { ViewDeleteDriver } from "./ViewDeleteDriver";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";

export const ViewDriver: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Tabs
          value={tab}
          variant="fullWidth"
          scrollButtons="auto"
          onChange={handleChange}
        >
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="Add Driver"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ minHeight: "50px" }}
            icon={<AdminPanelSettings />}
            iconPosition="start"
            label="Delete Driver"
            {...a11yProps(1)}
          />
        </Tabs>
        <Divider />
        <TabPanel value={tab} index={0}>
          <ViewAddDriver taskCode={taskCode} attachment={attachment} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ViewDeleteDriver taskCode={taskCode} attachment={attachment} />
        </TabPanel>
      </Box>

      <StandadCard sx={{ mt: 2, mb: 3 }}>
        <Typography
          variant="h2"
          fontWeight={600}
          color="primary"
          marginBottom={2}
        >
          {"Attachments"}
        </Typography>
        {attachment.length > 0 ? (
          attachment.map((item) => {
            return (
              <Stack
                key={item.key}
                direction={"row"}
                justifyContent={"center"}
                gap={4}
                alignItems={"center"}
              >
                <Typography variant="h4" fontWeight={"bold"}>
                  {" "}
                  {item.fileName}{" "}
                </Typography>
                <FileUpload value={item.url} />
              </Stack>
            );
          })
        ) : (
          <Typography variant="body1">No attachments</Typography>
        )}
      </StandadCard>
    </>
  );
};
