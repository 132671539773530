import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { initialCommAutomobileCertificatesState } from "./commAutoCertificates.types";
import { CommAutomobileCertificatesActions } from ".";
import {
  CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE,
  CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP,
  CLEAR_COMM_AUTOMOBILE_OCEP16,
  CLEAR_COMM_AUTOMOBILE_OCEP17,
  CLEAR_COMM_AUTOMOBILE_OCEP28A,
  FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS,
  FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS,
  FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS,
} from "./commAutoCertificatesActions";

export const CommAutomobileCertificatesReducer = (
  state: IStoreState["commAuto"]["certificates"] = initialCommAutomobileCertificatesState,
  action: CommAutomobileCertificatesActions
) => {
  switch (action.type) {
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.InProgress;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.Loaded;
        draftState.liabilitySlips.liabilitySlip = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.Failed;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.InProgress;
        draftState.liabilitySlips.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Loaded;
        draftState.liabilitySlips.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.loading = LoadState.Failed;
        draftState.liabilitySlips.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP: {
      const newState = produce(state, (draftState) => {
        draftState.liabilitySlips.liabilitySlipLoading = LoadState.NotLoaded;
        draftState.liabilitySlips.liabilitySlip =
          initialCommAutomobileCertificatesState["liabilitySlips"][
            "liabilitySlip"
          ];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.InProgress;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.Loaded;
        draftState.opcf16.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.Failed;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.InProgress;
        draftState.opcf16.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Loaded;
        draftState.opcf16.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.loading = LoadState.Failed;
        draftState.opcf16.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP16: {
      const newState = produce(state, (draftState) => {
        draftState.opcf16.opcfLoading = LoadState.NotLoaded;
        draftState.opcf16.opcf =
          initialCommAutomobileCertificatesState["opcf16"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.InProgress;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.Loaded;
        draftState.opcf17.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.Failed;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.InProgress;
        draftState.opcf17.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Loaded;
        draftState.opcf17.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.loading = LoadState.Failed;
        draftState.opcf17.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP17: {
      const newState = produce(state, (draftState) => {
        draftState.opcf17.opcfLoading = LoadState.NotLoaded;
        draftState.opcf17.opcf =
          initialCommAutomobileCertificatesState["opcf17"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.InProgress;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.Loaded;
        draftState.opcf28A.opcf = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.Failed;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
        draftState.liabilitySlips.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.InProgress;
        draftState.opcf28A.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Loaded;
        draftState.opcf28A.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.loading = LoadState.Failed;
        draftState.opcf28A.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_OCEP28A: {
      const newState = produce(state, (draftState) => {
        draftState.opcf28A.opcfLoading = LoadState.NotLoaded;
        draftState.opcf28A.opcf =
          initialCommAutomobileCertificatesState["opcf28A"]["opcf"];
        draftState.liabilitySlips.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.InProgress;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.Loaded;
        draftState.liabilityInsurance.liabilityInsurance = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.Failed;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
        draftState.liabilityInsurance.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.InProgress;
        draftState.liabilityInsurance.list = [];
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Loaded;
        draftState.liabilityInsurance.list = data;
      });
      return newState;
    }
    case FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.loading = LoadState.Failed;
        draftState.liabilityInsurance.list = [];
      });
      return newState;
    }

    case CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE: {
      const newState = produce(state, (draftState) => {
        draftState.liabilityInsurance.liabilityInsuranceLoading =
          LoadState.NotLoaded;
        draftState.liabilityInsurance.liabilityInsurance =
          initialCommAutomobileCertificatesState["liabilityInsurance"][
            "liabilityInsurance"
          ];
        draftState.liabilityInsurance.error = null;
      });
      return newState;
    }

    case FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE: {
      return initialCommAutomobileCertificatesState;
    }

    default: {
      return state;
    }
  }
};
