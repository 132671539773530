import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import { defaultHabitationalApplication } from "./habitationalApplication.types";
import {
  FETCH_HABITATIONAL_APPLICATION_LIST_FAILED,
  FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS,
  FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS,
  FETCH_HABITATIONAL_APPLICATION_STEP_ONE_FAILED,
  FETCH_HABITATIONAL_APPLICATION_STEP_ONE_PROGRESS,
  FETCH_HABITATIONAL_APPLICATION_STEP_ONE_SUCCESSS,

} from "./habitationalApplication.Actions";
import { HabitationalApplicationAction } from ".";

export const habitationalApplicationReducer = (
  state: IStoreState["habitational"]["applications"] = defaultHabitationalApplication,
  action: HabitationalApplicationAction
) => {
  switch (action.type) {

    case FETCH_HABITATIONAL_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }


    case FETCH_HABITATIONAL_APPLICATION_STEP_ONE_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.step1.loading1 = LoadState.NotLoaded;
        draftState.step1.data1 = defaultHabitationalApplication["step1"]["data1"];
        draftState.step1.error1 = null;
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_STEP_ONE_SUCCESSS: {
      const { dataStepOne } = action.payload
      const newState = produce(state, (draftState: any) => {
        draftState.step1.loading1 = LoadState.Loaded;
        draftState.step1.data1 = {
          ...dataStepOne,
          ...defaultHabitationalApplication["step1"]["data1"]
        }
      });
      return newState;
    }
    case FETCH_HABITATIONAL_APPLICATION_STEP_ONE_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.step1.loading1 = LoadState.Failed;
        draftState.step1.data1 =
          defaultHabitationalApplication["step1"]["data1"];
        draftState.step1.error1 = "Error Occured";
      });
      return newState;
    }
    // case CLEAR_HABITATIONAL_APPLICATION_STEP_ONE_STATE: {
    //   return defaultHabitationalApplication["step1"];
    // }
    // case CLEAR_HABITATIONAL_APPLICATION_STEP_TWO_STATE: {
    //   return defaultHabitationalApplication["step2"];
    // }
    // case CLEAR_HABITATIONAL_APPLICATION_STEP_THREE_STATE: {
    //   return defaultHabitationalApplication["step3"];
    // }
    default: {
      return state;
    }
  }
};
