import { LoadState } from "../../../constants/enums";

export interface IAccountingCompanyInformationState {
  data: ICompanyInformation;
  loading: LoadState;
  error: string;
}

export interface ICompanyInformation {
  company_id: number | null;
  company_name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  phone: string | null;
  telephone: string | null;
  fax: string | null;
  default_language: string | null;
  // email: string | null;
  accounts_email: string | null;
  cl_email: string | null;
  pl_email: string | null;
  default_tax_region: string | null;
  pst_or_gst_or_vat_number: string | null;
  bahamas_premium_tax: string | null;
  status: string;
}

export const initialAccountingCompanyInformation: IAccountingCompanyInformationState = {
    data: {
        company_id: null,
        company_name: null,
        address: null,
        unit_or_suite: null,
        city: null,
        province_or_state: null,
        postal_code: null,
        country: null,
        phone: null,
        telephone: null,
        fax: null,
        default_language: null,
        // email: null,
        accounts_email: null,
        cl_email: null,
        pl_email: null,
        default_tax_region: null,
        pst_or_gst_or_vat_number: null,
        bahamas_premium_tax: null,
        status: "",
    },
    loading: LoadState.NotLoaded,
    error: "",
}
