import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { IDeleteDriver } from "../../../../redux/TaskReducer/Driver/driver.types";
import { fetchDeleteDriverAysnc } from "../../../../redux/TaskReducer/Driver/driverActions";
import { StandardCellList } from "../../../../components/Standards";
import { Grid, Stack, Typography } from "@mui/material";
import { formatTime } from "../../../../helpers";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { StandadCard } from "../../../../components/Cards";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";

export const ViewDeleteDriver: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];

}> = (props) => {
  const { taskCode, attachment } = props;

  const { data, deleteloading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.driver.deleteDriver
  );

  const dispatch = useDispatchWrapper();
  const [driverDetails, setDriverDetails] = React.useState<IDeleteDriver>();

  React.useEffect(() => {
    dispatch(fetchDeleteDriverAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setDriverDetails(data);
  }, [data]);

  return (
    <PageLoader loading={deleteloading === LoadState.InProgress}>
      {driverDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: driverDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: driverDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: driverDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: { type: "text", value: driverDetails.line_of_business },
                },

                {
                  heading: "Policy Type",
                  data: { type: "text", value: driverDetails.policy_type },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: driverDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: driverDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: driverDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: driverDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: driverDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Driver Information"
              colSize={3}
              list={[
                {
                  heading: "Driver",
                  data: {
                    type: "text",
                    value: driverDetails.driver,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: driverDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(driverDetails.effective_time_of_change.toString()),
                  },
                },
                {
                  heading: "Details",
                  data: {
                    type: "text",
                    value: driverDetails.details,
                  },
                },
              ]}
            />
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <StandadCard>
              <Typography
                variant="h2"
                fontWeight={600}
                color="primary"
                marginBottom={2}
              >
                {"Attachments"}
              </Typography>
              {attachment.length > 0 ? (
                attachment.map((item) => {
                  return (
                    <Stack
                      key={item.key}
                      direction={"row"}
                      justifyContent={"center"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Typography variant="h4" fontWeight={"bold"}>
                        {" "}
                        {item.fileName}{" "}
                      </Typography>
                      <FileUpload value={item.url} />
                    </Stack>
                  );
                })
              ) : (
                <Typography variant="body1">No attachments</Typography>
              )}
            </StandadCard>
          </Grid> */}
        </Grid>
      )}
    </PageLoader>
  );
};
