import moment from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileCertificatesState {
  liabilitySlips: {
    list: IAutomobileLiabilitySlips[];
    loading: LoadState;
    liabilitySlip: IAutomobileLiabilitySlips;
    liabilitySlipLoading: LoadState;
    error: string | null;
  };
  liabilityInsurance: {
    list: IAutomobileLiabilityInsurance[];
    loading: LoadState;
    liabilityInsurance: IAutomobileLiabilityInsurance;
    liabilityInsuranceLoading: LoadState;
    error: string | null;
  };
  opcf16: {
    list: IAutomobileOCEP16[];
    loading: LoadState;
    opcf: IAutomobileOCEP16;
    opcfLoading: LoadState;
    error: string | null;
  };
  opcf17: {
    list: IAutomobileOCEP17[];
    loading: LoadState;
    opcf: IAutomobileOCEP17;
    opcfLoading: LoadState;
    error: string | null;
  };
  opcf28A: {
    list: IAutomobileOCEP28A[];
    loading: LoadState;
    opcf: IAutomobileOCEP28A;
    opcfLoading: LoadState;
    error: string | null;
  };
}

export interface IAutomobileLiabilitySlips {
  customer_policy_id: number;
  incremental_number: number | null;
  insured: string | null;
  address: string | null;
  policy_number: string | null;
  policy_status: string | null;
  insurer_name: string | null;
  broker_name: string | null;
  effective_date: string | null;
  expiry_date: string | null;
  effective_time_of_change: string | null;
  time_of_binding: string | null;
  list_of_vehicle_option_one: string | null;
  list_of_vehicle_option_two: string | null;
  list_of_vehicles: any[];
  status: string;
}

export interface IAutomobileLiabilityInsurance {
  customer_policy_id: number;
  incremental_number: number | null;
  cgl_policy_number: string | null;
  automobile_policy: string | null;
  umbrella_or_excess_liability_policy: string | null;
  other_policy: string | null;
  non_owned_automobiles_policy: string | null;
  hired_automobiles: string | null;
  vehicle_unit_number_or_vin: string | null;
  certificate_holder_name: string | null;
  certificate_holder_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  description_of_operations: {
    key: string;
    label: string;
    value: string;
  }[];
  commercial_general_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  commercial_general_liability_section_two: {
    claim_mode: string;
    claim_mode_deductible: string | number;
    claim_mode_amount: string | number;
    occurance: string;
    occurance_deductible: string | number;
    occurance_amount: string | number;
    products_completed_oprations: string;
    products_completed_oprations_deductible: string | number;
    products_completed_oprations_amount: string | number;
    empolyer_liability: string;
    empolyer_liability_peronal_injury: string;
    empolyer_liability_deductible: string | number;
    empolyer_liability_amount: string | number;
    waiver_of_subrogation: string;
    waiver_of_subrogation_peronal_injury: string;
    waiver_of_subrogation_deductible: string | number;
    waiver_of_subrogation_amount: string | number;
    cross_liability: string;
    cross_liability_deductible: string | number;
    cross_liability_amount: string | number;
    tenants_Legal_Liability: string;
    tenants_Legal_Liability_deductible: string | number;
    tenants_Legal_Liability_amount: string | number;
    pollution_Liability_Extension: string;
    pollution_Liability_Extension_deductible: string | number;
    pollution_Liability_Extension_amount: string | number;
  };
  commercial_general_liability_section_three: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  non_owned_automobiles_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  non_owned_automobiles_section_two: {
    non_owned: string;
    non_owned_deductible: string;
    non_ownder_amount: string;
  };
  hired_automobiles_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  hired_automobiles_section_two: {
    hired_type_of_insurance: string;
    hired_amount: string;
    hired_deductible: string
  };
  automobile_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  automobile_liability_section_two: {
    described_automobiles: string;
    described_automobiles_deductible: string;
    described_automobiles_amount: string;
    all_owned_automobiles: string;
    all_owned_automobiles_deductible: string;
    all_owned_automobiles_amount: string;
    leased_automobiles: string;
    leased_automobiles_deductible: string;
    leased_automobiles_amount: string;
    property_damage_deductible: string;
    property_damage_amount: string;
  };
  excess_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  excess_liability_section_two: {
    umbrella_form: string;
    umbrella_form_deductible: string;
    umbrella_form_amount: string;
    aggreate_deductible: string;
    aggreate_amount: string;
    extra: string;
    extra_type_of_insurance: string;
    extra_coverage: string;
    extra_deductible: string;
    extra_amount: string;
  };
  other_liability_section_one: {
    insurance_company: string;
    policy_number: string;
    effective_date: string;
    expiry_date: string;
  };
  other_liability_section_two: {
    key: string;
    type_of_insurance_check: string;
    type_of_insurance: string;
    coverage: string;
    deductible: string | number;
    amount: string | number;
  }[];
  cancelation_number_of_days: string | null;
  brokerage_full_name: string | null;
  brokerage_mailing_address: string | null;
  brokerage_customer_code: string | null;
  additional_name_insured_yes_or_no: string | null;
  additional_name_insured_name: string | null;
  additional_name_insured_mailing_address: string | null;
  certificate_authorization_branch_name: string | null;
  certificate_authorization_assigned_to: string | null;
  certificate_authorization_phone_number: string | null;
  certificate_authorization_fax_number: string | null;
  certificate_authorization_email: string | null;
  certificate_authorization_date: string | null;
  status: string;
}

export interface IAutomobileOCEP16 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: string | null;
  checkbox_this_change_applies: string | null,
  this_change_applies_only_to_automobile: string | null,
  indicated_on_your_certificate: string | null,
  checkbox_see_your_certificate_of_automobile: string | null,
  see_your_certificate_of_automobile: string | null,
  automobile_data: any;
  status: string;
}

export interface IAutomobileOCEP17 {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  policy_number: string | null;
  effective_date_of_change: string | null;
  policy_status: null;
  automobile_data: any;
  status: string;
  checkbox_this_change_applies_only_to_automobile: string;
  this_change_applies_only_to_automobile: string | null;
  indicated_on_your_certificate_of_automobile: string | null;
  checkbox_see_your_certificate_of_automobile: string;
  see_your_certificate_of_automobile: string | null;
}

export interface IAutomobileOCEP28A {
  customer_policy_id: number;
  incremental_number: number | null;
  issued_to: string | null;
  effective_date_of_change: string | null;
  policy_number: string | null;
  automobile_number: string | null;
  model: string | null;
  year: string | null;
  trade_name: string | null;
  serial_number_or_vin: string | null;
  name_of_excluded_driver: string | null;
  driver_license_number: string | null;
  status: string;
}

export const initialAutomobileCertificatesState: IAutomobileCertificatesState =
  {
    liabilitySlips: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilitySlip: {
        customer_policy_id: 0,
        incremental_number: null,
        insured: null,
        address: null,
        policy_number: null,
        policy_status: null,
        insurer_name: null,
        broker_name: null,
        effective_date: null,
        expiry_date: null,
        effective_time_of_change: moment()
          .startOf("day")
          .add(1, "minute")
          .toString(),
        time_of_binding: moment().startOf("day").add(1, "minute").toString(),
        list_of_vehicle_option_one: null,
        list_of_vehicle_option_two: null,
        list_of_vehicles: [],
        status: "ACTIVE",
      },
      liabilitySlipLoading: LoadState.NotLoaded,
      error: null,
    },
    liabilityInsurance: {
      list: [],
      loading: LoadState.NotLoaded,
      liabilityInsurance: {
        incremental_number: null,
        customer_policy_id: 0,
        cgl_policy_number: null,
        automobile_policy: null,
        umbrella_or_excess_liability_policy: null,
        other_policy: null,
        non_owned_automobiles_policy: null,
        hired_automobiles: null,
        vehicle_unit_number_or_vin: null,
        certificate_holder_name: null,
        certificate_holder_address: null,
        certificate_holder_email: null,
        certificate_holder_phone: null,
        description_of_operations: [
          { key: getUniqueId(), label: "Line 1", value: "" },
          { key: getUniqueId(), label: "Line 2", value: "" },
          { key: getUniqueId(), label: "Line 3", value: "" },
          { key: getUniqueId(), label: "Line 4", value: "" },
        ],
        commercial_general_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        commercial_general_liability_section_two: {
          claim_mode: "N",
          claim_mode_deductible: "",
          claim_mode_amount: "",
          occurance: "N",
          occurance_deductible: "",
          occurance_amount: "",
          products_completed_oprations: "N",
          products_completed_oprations_deductible: "",
          products_completed_oprations_amount: "",
          empolyer_liability: "N",
          empolyer_liability_peronal_injury: "N",
          empolyer_liability_deductible: "",
          empolyer_liability_amount: "",
          waiver_of_subrogation: "N",
          waiver_of_subrogation_peronal_injury: "N",
          waiver_of_subrogation_deductible: "",
          waiver_of_subrogation_amount: "",
          cross_liability: "N",
          cross_liability_deductible: "",
          cross_liability_amount: "",
          tenants_Legal_Liability: "N",
          tenants_Legal_Liability_deductible: "",
          tenants_Legal_Liability_amount: "",
          pollution_Liability_Extension: "N",
          pollution_Liability_Extension_deductible: "",
          pollution_Liability_Extension_amount: "",
        },
        commercial_general_liability_section_three: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
     
        ],
        non_owned_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        non_owned_automobiles_section_two: {
          non_owned: "N",
          non_ownder_amount: "",
          non_owned_deductible: "",
        },
        hired_automobiles_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        hired_automobiles_section_two: {
          hired_type_of_insurance: "N",
          hired_amount: "",
          hired_deductible: "",
        },
        automobile_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        automobile_liability_section_two: {
          described_automobiles: "N",
          described_automobiles_deductible: "",
          described_automobiles_amount: "",
          all_owned_automobiles: "N",
          all_owned_automobiles_deductible: "",
          all_owned_automobiles_amount: "",
          leased_automobiles: "N",
          leased_automobiles_deductible: "",
          leased_automobiles_amount: "",
          property_damage_deductible: "",
          property_damage_amount: "",
        },
        excess_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        excess_liability_section_two: {
          umbrella_form: "N",
          umbrella_form_amount: "",
          aggreate_deductible: "",
          umbrella_form_deductible: "",
          extra: "N",
          extra_coverage: "",
          extra_type_of_insurance: "",
          extra_amount: "",
          extra_deductible: "",
          aggreate_amount: "",
        },
        other_liability_section_one: {
          insurance_company: "",
          policy_number: "",
          effective_date: "",
          expiry_date: "",
        },
        other_liability_section_two: [
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
          {
            key: getUniqueId(),
            type_of_insurance_check: "",
            type_of_insurance: "",
            coverage: "",
            deductible: "",
            amount: "",
          },
        ],
        cancelation_number_of_days: null,
        brokerage_full_name: null,
        brokerage_mailing_address: null,
        brokerage_customer_code: null,
        additional_name_insured_yes_or_no: null,
        additional_name_insured_name: null,
        additional_name_insured_mailing_address: null,
        certificate_authorization_branch_name: null,
        certificate_authorization_assigned_to: null,
        certificate_authorization_phone_number: null,
        certificate_authorization_fax_number: null,
        certificate_authorization_email: null,
        certificate_authorization_date: null,
        status: "ACTIVE",
      },
      liabilityInsuranceLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf16: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: null,
        policy_status: null,
        automobile_data: [],
        checkbox_this_change_applies: null,
        this_change_applies_only_to_automobile: null,
        indicated_on_your_certificate: null,
        checkbox_see_your_certificate_of_automobile: null,
        see_your_certificate_of_automobile: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf17: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        policy_number: null,
        effective_date_of_change: null,
        policy_status: null,
        automobile_data: [{}],
        checkbox_this_change_applies_only_to_automobile: "N",
        this_change_applies_only_to_automobile: null,
        indicated_on_your_certificate_of_automobile: null,
        checkbox_see_your_certificate_of_automobile: "N",
        see_your_certificate_of_automobile: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
    opcf28A: {
      list: [],
      loading: LoadState.NotLoaded,
      opcf: {
        customer_policy_id: 0,
        incremental_number: null,
        issued_to: null,
        effective_date_of_change: moment().toString(),
        policy_number: null,
        automobile_number: null,
        model: null,
        year: null,
        trade_name: null,
        serial_number_or_vin: null,
        name_of_excluded_driver: null,
        driver_license_number: null,
        status: "ACTIVE",
      },
      opcfLoading: LoadState.NotLoaded,
      error: null,
    },
  };
