import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate } from "../../../../helpers";
import {
  fetchTaskVehcileChangeAysnc,
} from "../../../../redux/generalChange/generalChangeActions";
import {
  ITaskAddVehicle,
} from "../../../../redux/generalChange/generalChange.types";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";

export const ViewAddVehicle: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.vehcile
  );

  const dispatch = useDispatchWrapper();
  const [vehicleDetails, setVehicleDetails] = React.useState<ITaskAddVehicle>();

  React.useEffect(() => {
    dispatch(fetchTaskVehcileChangeAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setVehicleDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {vehicleDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: vehicleDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: vehicleDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: vehicleDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: {
                    type: "text",
                    value: vehicleDetails.line_of_business,
                  },
                },

                {
                  heading: "Policy Type",
                  data: {
                    type: "text",
                    value: vehicleDetails.policy_type,
                  },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: vehicleDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: vehicleDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: vehicleDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: vehicleDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: vehicleDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Vehicle Information"
              colSize={3}
              list={[
                {
                  heading: "Year",
                  data: {
                    type: "text",
                    value: vehicleDetails.year,
                  },
                },
                {
                  heading: "Make",
                  data: {
                    type: "text",
                    value: vehicleDetails.make,
                  },
                },
                {
                  heading: "Model",
                  data: {
                    type: "text",
                    value: vehicleDetails.model,
                  },
                },
                {
                  heading: "VIN",
                  data: {
                    type: "text",
                    value: vehicleDetails.vin,
                  },
                },
                {
                  heading: "Date of Purchase",
                  data: {
                    type: "text",
                    value: formatDate(vehicleDetails.date_of_purchase || ""),
                  },
                },
                {
                  heading: "Purchase Condition",
                  data: {
                    type: "text",
                    value: vehicleDetails.purchase_condition,
                  },
                },
                {
                  heading: "KMs at Purchase",
                  data: {
                    type: "text",
                    value: vehicleDetails.kms_at_purchase,
                  },
                },
                {
                  heading: "List Price New",
                  data: {
                    type: "text",
                    value: vehicleDetails.list_price_new,
                  },
                },
                {
                  heading: "Horse Power",
                  data: {
                    type: "text",
                    value: vehicleDetails.horse_power,
                  },
                },
                {
                  heading: "Use of Vehicle",
                  data: {
                    type: "text",
                    value: vehicleDetails.use_of_vehicle,
                  },
                },
                {
                  heading: "Annual KMs",
                  data: {
                    type: "text",
                    value: vehicleDetails.annual_kms,
                  },
                },
                {
                  heading: "One Way Commute",
                  data: {
                    type: "text",
                    value: vehicleDetails.one_way_commute,
                  },
                },
                {
                  heading: "Business KMs",
                  data: {
                    type: "text",
                    value: vehicleDetails.business_kms,
                  },
                },
                {
                  heading: "Details",
                  data: {
                    type: "text",
                    value: vehicleDetails.details,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
