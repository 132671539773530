import { LoadState } from "../../constants/enums";

export interface ILegalsState {
    list: ILegal[];
    totalRecords: number;
    loading: LoadState;
    legal: ILegal;
    legalLoading: LoadState;
    errro: string | null;
}

export interface ILegal {
    incremental_number: number | null;
    legal_plus_code: number | null; 
    type: string | null;
    defendant: string;
    plaintiff: string;
    customer_name: string;
    policy_number: number | null;
    effective_date: string | null;
    expiry_date: string | null;
    details: string;
    attachment: string | null;
    comments: string | null;
    status: string; 
}

export const initialLegal: ILegal = {
    incremental_number: null,
    legal_plus_code:  null, 
    type: null,
    defendant: "",
    plaintiff: "",
    customer_name: "",
    policy_number: null,
    effective_date: null,
    expiry_date: null,
    details: "",
    attachment: null, 
    comments: "",
    status: "ACTIVE",
}

export const initialLegalState: ILegalsState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    legal: initialLegal,
    legalLoading: LoadState.NotLoaded,
    errro: null,
}