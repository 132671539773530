import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../components/Table/DataTable";
import { IBusinessPolicyRiskLocation } from "../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { useDispatchWrapper } from "../../../../../hooks";
import { fetchBusinessPolicyRiskListAsync } from "../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../constants/enums";
import { StandardTableActions } from "../../../../../components/StandardTableActions/StandardTableActions";
import { IhabitationalStepOne } from "../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplication.types";
import { fetchHabitationalApplicationListAsync } from "../../../../../redux/habitationalPolicy/habitationalApplication/habitationalApplication.Actions";

export const HabitationalApplicationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.applications
  );
  const dispatch = useDispatchWrapper();



  const habitationalApplicationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application`
                  );
                }}
              >
                Add Application
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "location_number",
        headerName: "Loc #",
        fieldName: "location_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IhabitationalStepOne) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application/${row.policy_number}`
                )
              }
            >
              {row.policy_number}
            </Link>
          );
        },
      },
     
      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellValueRender:(value, row: IhabitationalStepOne) =>{
           return row.applicant_address+", "+row.applicant_unit_or_suite+", "+row.applicant_city+", "+row.applicant_province_or_state+", "+row.applicant_postal_or_zip_code
        }
      },

      {
        key: "applicant_name",
        headerName: "Applicant Name",
        fieldName: "applicant_name",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "policy_period_effective_date",
        headerName: "Effective Date",
        fieldName: "policy_period_effective_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IhabitationalStepOne) => {
       

          return    (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/application-tab/${customerpolicyId}/application/${row.policy_number}`
                );
              }}
            //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchHabitationalApplicationListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...habitationalApplicationTableProps} />
      </Box>
    </>
  );
};
