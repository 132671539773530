import { action } from "typesafe-actions";
import {
  ICustomerDetail,
  IDriverByPolicyNumber,
  IGeneralHistory,
  IMail,
  IPolicies,
  IRecordCount,
  IRiskLocationByPolicyNumber,
  IVehcileByPolicyNumber,
} from "./common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { showMessage } from "../messages/messagesActions";
import { api } from "../../api/api";
import { LoadState } from "../../constants/enums";
import { clearBasicPolicyState } from "../businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";

export const FETCH_POLICIES_PROGRESS = "FETCH_POLICIES_PROGRESS";
export const FETCH_POLICIES_SUCCESS = "FETCH_POLICIES_SUCCESS";
export const FETCH_POLCIES_FAILED = "FETCH_POLCIES_FAILED";

export const fetchPoliciesProgress = () => action(FETCH_POLICIES_PROGRESS);
export const fetchPoliciesSuccess = (policies: IPolicies[]) =>
  action(FETCH_POLICIES_SUCCESS, { policies });
export const fetchPoliciesFailed = () => action(FETCH_POLCIES_FAILED);
export const fetchPolicies =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const policies = getState().common.policies;
    if (policies.loadState !== LoadState.Loaded) {
      try {
        dispatch(fetchPoliciesProgress());
        const res = await api.get(`/policy/get-policies`);
        const data: IPolicies[] = res.data.data;

        dispatch(fetchPoliciesSuccess(data));
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        dispatch(fetchPoliciesFailed());
      }
    }
  };

export const fetchInitialRefData =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(fetchPolicies());
  };

export const clearBusinessPolicyState =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(clearBasicPolicyState());
  };

export const sendMessageToUserPhone =
  (
    data: {
      mobileNumber: string;
      message: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-sms`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
            name: data.message,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Message send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendMessageToWhatsapp =
  (
    data: {
      mobileNumber: string;
      message: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-whatsapp`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
            name: data.message,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Message send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendVoiceCalling =
  (
    data: {
      mobileNumber: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/voice-calling`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Voice calling is send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendEmail =
  (
    data: IMail,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-email`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Email is send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_GENERAL_HISTORY_LIST_PROGRESS =
  "FETCH_GENERAL_HISTORY_LIST_PROGRESS";
export const FETCH_GENERAL_HISTORY_LIST_SUCCESS =
  "FETCH_GENERAL_HISTORY_LIST_SUCCESS";
export const FETCH_GENERAL_HISTORY_LIST_FAILED =
  "FETCH_GENERAL_HISTORY_LIST_FAILED";

export const fetchGeneraHistoryListProgress = () =>
  action(FETCH_GENERAL_HISTORY_LIST_PROGRESS);
export const fetchGeneraHistoryListSuccess = (data: IGeneralHistory[]) =>
  action(FETCH_GENERAL_HISTORY_LIST_SUCCESS, { data });
export const fetchGeneraHistoryListFailed = () =>
  action(FETCH_GENERAL_HISTORY_LIST_FAILED);

export const fetchGeneraHistoryListAsync =
  (
    customerPolicyId: number,
    tableName: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGeneraHistoryListProgress());
      const res = await api.get(
        `/general/get-log-data?table_name=${tableName}&customer_policy_id=${customerPolicyId}`
      );

      const data: IGeneralHistory[] = res.data.data;
      if (data) {
        dispatch(fetchGeneraHistoryListSuccess(data));
      } else {
        dispatch(fetchGeneraHistoryListSuccess([]));
      }
    } catch (err: any) {
      dispatch(fetchGeneraHistoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const fetchHistoryByTableColumnAsync =
  (
    tableName: string,
    columnName: string,
    columnValue: string | number | null
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGeneraHistoryListProgress());
      const res = await api.get(
        `/general/get-log-data?table_name=${tableName}&column_name=${columnName}&column_value=${columnValue}`
      );

      const data: IGeneralHistory[] = res.data.data;
      if (data) {
        dispatch(fetchGeneraHistoryListSuccess(data));
      } else {
        dispatch(fetchGeneraHistoryListSuccess([]));
      }
    } catch (err: any) {
      dispatch(fetchGeneraHistoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_GENERAL_HISTORY_STATE = "CLEAR_GENERAL_HISTORY_STATE";

export const clearGeneraHistoryState = () =>
  action(CLEAR_GENERAL_HISTORY_STATE);

export const FETCH_RECORD_COUNTS_PROGRESS = "FETCH_RECORD_COUNTS_PROGRESS";
export const FETCH_RECORD_COUNTS_SUCCESS = "FETCH_RECORD_COUNTS_SUCCESS";
export const FETCH_RECORD_COUNTS_FAILED = "FETCH_RECORD_COUNTS_FAILED";

export const fetchRecordsCountsProgress = () =>
  action(FETCH_RECORD_COUNTS_PROGRESS);
export const fetchRecordsCountsSuccess = (data: IRecordCount[]) =>
  action(FETCH_RECORD_COUNTS_SUCCESS, { data });
export const fetchRecordsCountsFailed = () =>
  action(FETCH_RECORD_COUNTS_FAILED);

export const fetchRecordCountsAsync =
  (
    tableName: string,
    column: string,
    fromDate: string,
    toDate: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(fetchRecordsCountsProgress());
      // const res = await api.get(
      //   `/general/get-record-counts?table_name=${tableName}&column=${column}&from_date=${fromDate}&to_date=${toDate}`
      // );
      // const data: IRecordCount[] = res.data.data;
      // dispatch(fetchRecordsCountsSuccess(data || []));
    } catch (err: any) {
      dispatch(fetchRecordsCountsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_RECORD_COUNTS = "CLEAR_RECORD_COUNTS";
export const clearRecordCounts = () => action(CLEAR_RECORD_COUNTS);

export const FETCH_VEHICLES_BY_POLICY_NUM_PROGRESS =
  "FETCH_VEHICLES_BY_POLICY_NUM_PROGRESS";
export const FETCH_VEHICLES_BY_POLICY_NUM_SUCCESS =
  "FETCH_VEHICLES_BY_POLICY_NUM_SUCCESS";
export const FETCH_VEHICLES_BY_POLICY_NUM_FAILED =
  "FETCH_VEHICLES_BY_POLICY_NUM_FAILED";

export const fetchVehcilesByPolicyNumProgress = () =>
  action(FETCH_VEHICLES_BY_POLICY_NUM_PROGRESS);
export const fetchVehcilesByPolicyNumSuccess = (
  data: IVehcileByPolicyNumber[],
  policyNumber: number
) => action(FETCH_VEHICLES_BY_POLICY_NUM_SUCCESS, { data, policyNumber });
export const fetchVehcilesByPolicyNumFailed = () =>
  action(FETCH_VEHICLES_BY_POLICY_NUM_FAILED);
export const fetchVehcilesByPolicyNum =
  (policyNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const policies = getState().common.policies;
    if (policies.loadState !== LoadState.Loaded) {
      try {
        dispatch(fetchVehcilesByPolicyNumProgress());
        const res = await api.get(
          `/policy/get-merged-vehicle-information?customer_policy_id=${policyNumber}`
        );
        const data = res.data.data;
        const finalData: IVehcileByPolicyNumber[] = [];
        for (let item of data) {
          finalData.push({
            customer_name: item.customer_name,
            incremental_number: item.incremental_number,
            make: item.make,
            model: item.model,
            policy_number: item.policy_number,
            unit_number: item.unit_number,
            vin: item.vin,
            year: item.year,
            annual_kms: item.annual_kms,
            plate_number: item.plate_number,
            puchase_condition: item.puchase_condition,
            purchase_date: item.purchase_date,
            purchase_price: item.purchase_price,
            list_price_new: item.list_price_new,
          });
        }

        dispatch(fetchVehcilesByPolicyNumSuccess(finalData, policyNumber));
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        dispatch(fetchVehcilesByPolicyNumFailed());
      }
    }
  };

export const CLEAR_VEHICLES_BY_POLICY_NUM = "CLEAR_VEHICLES_BY_POLICY_NUM";
export const clearVehcilesByPolicyNum = () =>
  action(CLEAR_VEHICLES_BY_POLICY_NUM);

export const FETCH_DRIVERS_BY_POLICY_NUM_PROGRESS =
  "FETCH_DRIVERS_BY_POLICY_NUM_PROGRESS";
export const FETCH_DRIVERS_BY_POLICY_NUM_SUCCESS =
  "FETCH_DRIVERS_BY_POLICY_NUM_SUCCESS";
export const FETCH_DRIVERS_BY_POLICY_NUM_FAILED =
  "FETCH_DRIVERS_BY_POLICY_NUM_FAILED";

export const fetchDriversByPolicyNumProgress = () =>
  action(FETCH_DRIVERS_BY_POLICY_NUM_PROGRESS);
export const fetchDriversByPolicyNumSuccess = (
  data: IDriverByPolicyNumber[],
  policyNumber: number
) => action(FETCH_DRIVERS_BY_POLICY_NUM_SUCCESS, { data, policyNumber });
export const fetchDriversByPolicyNumFailed = () =>
  action(FETCH_DRIVERS_BY_POLICY_NUM_FAILED);
export const fetchDriversByPolicyNum =
  (policyNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const policies = getState().common.policies;
    if (policies.loadState !== LoadState.Loaded) {
      try {
        dispatch(fetchDriversByPolicyNumProgress());
        const res = await api.get(
          `/policy/get-merged-driver-information?customer_policy_id=${policyNumber}`
        );
        const data = res.data.data as IDriverByPolicyNumber[];
        const finalData: IDriverByPolicyNumber[] = [];
        for (let item of data) {
          finalData.push({
            age: item.age,
            customer_policy_id: item.customer_policy_id,
            date_of_birth: item.date_of_birth,
            driver_name: item.driver_name,
            driver_number: item.driver_number,
            driver_phone_number: item.driver_phone_number,
            expiry_date_of_license: item.expiry_date_of_license,
            gender: item.gender,
            last_mvr_date: item.last_mvr_date,
            license_number: item.license_number,
            license_status: item.license_status,
            marital_status: item.marital_status,
            mvr_status: item.mvr_status,
            province: item.province,
          });
        }

        dispatch(fetchDriversByPolicyNumSuccess(finalData, policyNumber));
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        dispatch(fetchDriversByPolicyNumFailed());
      }
    }
  };

export const CLEAR_DRIVERS_BY_POLICY_NUM = "CLEAR_DRIVERS_BY_POLICY_NUM";
export const clearDriversByPolicyNum = () =>
  action(CLEAR_DRIVERS_BY_POLICY_NUM);

export const FETCH_RISK_LOCATIONS_BY_POLICY_NUM_PROGRESS =
  "FETCH_RISK_LOCATIONS_BY_POLICY_NUM_PROGRESS";
export const FETCH_RISK_LOCATIONS_BY_POLICY_NUM_SUCCESS =
  "FETCH_RISK_LOCATIONS_BY_POLICY_NUM_SUCCESS";
export const FETCH_RISK_LOCATIONS_BY_POLICY_NUM_FAILED =
  "FETCH_RISK_LOCATIONS_BY_POLICY_NUM_FAILED";

export const fetchRiskLocationByPolicyNumProgress = () =>
  action(FETCH_RISK_LOCATIONS_BY_POLICY_NUM_PROGRESS);
export const fetchRiskLocationByPolicyNumSuccess = (
  data: IRiskLocationByPolicyNumber[],
  policyNumber: number
) => action(FETCH_RISK_LOCATIONS_BY_POLICY_NUM_SUCCESS, { data, policyNumber });
export const fetchRiskLocationByPolicyNumFailed = () =>
  action(FETCH_RISK_LOCATIONS_BY_POLICY_NUM_FAILED);
export const fetchRiskLocationByPolicyNum =
  (policyNumber: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const policies = getState().common.policies;
    if (policies.loadState !== LoadState.Loaded) {
      try {
        dispatch(fetchRiskLocationByPolicyNumProgress());
        const res = await api.get(
          `/policy/get-merged-policy-number-risk-location?customer_policy_id=${policyNumber}`
        );
        const data = res.data.data as IRiskLocationByPolicyNumber[];
        const finalData: IRiskLocationByPolicyNumber[] = [];
        for (let item of data) {
          finalData.push({
            address: item.address,
            area_occupied: item.area_occupied,
            location_number: item.location_number,
            basement: item.basement,
            city: item.city,
            country: item.country,
            customer_id: item.customer_id,
            customer_policy_id: item.customer_policy_id,
            date_inspected: item.date_inspected,
            electrical: item.electrical,
            floors: item.floors,
            heating: item.heating,
            number_of_stories: item.number_of_stories,
            plumbing: item.plumbing,
            policy_business_risk_location_id:
              item.policy_business_risk_location_id,
            postal_code: item.postal_code,
            province_or_state: item.province_or_state,
            risk_inspected: item.risk_inspected,
            roof: item.roof,
            roof_coverings: item.roof_coverings,
            total_building_area: item.total_building_area,
            unit_or_suite: item.unit_or_suite,
            walls: item.walls,
            year_built: item.year_built,
          });
        }

        dispatch(fetchRiskLocationByPolicyNumSuccess(finalData, policyNumber));
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        dispatch(fetchRiskLocationByPolicyNumFailed());
      }
    }
  };

export const CLEAR_RISK_LOCATIONS_BY_POLICY_NUM =
  "CLEAR_RISK_LOCATIONS_BY_POLICY_NUM";
export const clearRiskLocationsByPolicyNum = () =>
  action(CLEAR_RISK_LOCATIONS_BY_POLICY_NUM);

export const FETCH_CUSTOMER_ALL_DETAILS_PROGRESS =
  "FETCH_CUSTOMER_ALL_DETAILS_PROGRESS";
export const FETCH_CUSTOMER_ALL_DETAILS_SUCCESS =
  "FETCH_CUSTOMER_ALL_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_ALL_DETAILS_FAILED =
  "FETCH_CUSTOMER_ALL_DETAILS_FAILED";

export const fetchCustomerAllPolicyDetailsProgress = () =>
  action(FETCH_CUSTOMER_ALL_DETAILS_PROGRESS);
export const fetchCustomerAllPolicyDetailsSuccess = (data: ICustomerDetail) =>
  action(FETCH_CUSTOMER_ALL_DETAILS_SUCCESS, { data });
export const fetchCustomerAllPolicyDetailsFailed = () =>
  action(FETCH_CUSTOMER_ALL_DETAILS_FAILED);

export const fetchCustomerAllPolicyDetailsAsync =
  (
    customerPolicyId: number,
    typeOfPolicy: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerAllPolicyDetailsProgress());
      const res = await api.get(
        `/policy/get-customer-all-policy-details?customer_policy_id=${customerPolicyId}&type_of_policy=${typeOfPolicy}`
      );
      const data: ICustomerDetail = res.data.data;
      // if (data.length > 0) {
        dispatch(fetchCustomerAllPolicyDetailsSuccess(data));
      // }
    } catch (err: any) {
      dispatch(fetchCustomerAllPolicyDetailsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
