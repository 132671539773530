/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CustomerBaiscDetailsLayout } from "../../CustomerBasicDetailsLayout/CustomerBasicDetailsLayout";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { StandadCard } from "../../../../../components/Cards";
import { Box } from "@mui/material";
import { PolicyTabCard } from "../../../../../components/PolicyTabCard/PolicyTabCard";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../hooks";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { BreadcrumbProvider } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchLifeInsuranceCustomerInfoAsync } from "../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { batch } from "react-redux";
import { clearNotesState } from "../../../../../redux/notes/notesActions";

export const LifeInsurancePolicyLayout: React.FC = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const {
    customerPoliycId: savedCustomerPolicyId,
    customerInfo: { Loading: loading, error },
  } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  const location = useLocation();
  const currentPathname = location.pathname;
  const isMainTab = currentPathname.includes("basic-details");

  const handleStep = (step: string) => () => {
    const isAlreadyInSamePage = currentPathname.includes(step);
    if (!isAlreadyInSamePage) {
      navigate(
        `/life-insurance-policy/${customerId}/${step}/${savedCustomerPolicyId}?tab=0`
      );
    }
  };

  const renderComponent = () => {
    if (isMainTab) {
      return <Outlet />;
    } else if (!isMainTab && !customerPolicyId) {
      return <Navigate to={`/customer-overview/${customerId}`} />;
    } else if (!isMainTab && savedCustomerPolicyId) {
      return <Outlet context={[customerId, savedCustomerPolicyId]} />;
    } else {
      console.log("__FALIED____");
      return null;
    }
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchLifeInsuranceCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearNotesState());
      });
    };
  }, []);

  return (
    <>
      <BreadcrumbProvider>
        <CustomerBaiscDetailsLayout customerId={customerId} fromPolicy policyId={savedCustomerPolicyId as number}>
          <StandadCard sx={{ my: 2 }}>
            <Box display={"flex"} flexWrap={"wrap"}>
              <PolicyTabCard
                label="Basic Details"
                onClick={handleStep("basic-details")}
                isSelected={currentPathname.includes("basic-details")}
              />

              <PolicyTabCard
                label="Coverage Needs"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("coverage-needs-tab")}
                isSelected={currentPathname.includes("coverage-needs-tab")}
              />

              {/* <PolicyTabCard
                label="Policies"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policies-tab")}
                isSelected={currentPathname.includes("policies-tab")}
              /> */}

              <PolicyTabCard
                label="Compliance Checklist"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("compliance-checklist-tab")}
                isSelected={currentPathname.includes(
                  "compliance-checklist-tab"
                )}
              />

              <PolicyTabCard
                label="Information"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("additional-information-tab")}
                isSelected={currentPathname.includes(
                  "additional-information-tab"
                )}
              />

              <PolicyTabCard
                label="Policy Forms"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("policy-forms-tab")}
                isSelected={currentPathname.includes("policy-forms-tab")}
              />

              <PolicyTabCard
                label="Claims"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("claims-tab")}
                isSelected={currentPathname.includes("claims-tab")}
              />

              {/* <PolicyTabCard
                label="Activity/Task"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("activity-task-tab")}
                isSelected={currentPathname.includes("activity-task-tab")}
              /> */}

              <PolicyTabCard
                label="History"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("history-tab")}
                isSelected={currentPathname.includes("history-tab")}
              />

              <PolicyTabCard
                label="Attachment"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("attachment-tab")}
                isSelected={currentPathname.includes("attachment-tab")}
              />
              <PolicyTabCard
                label="Notes"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("notes-tab")}
                isSelected={currentPathname.includes("notes-tab")}
              />
              <PolicyTabCard
                label="All Task/Activitiy"
                disabled={!savedCustomerPolicyId}
                onClick={handleStep("task-activity-tab")}
                isSelected={currentPathname.includes("task-activity-tab")}
              />
            </Box>
          </StandadCard>
          <PageLoader
            loading={loading === LoadState.InProgress}
            error={error ? { message: error } : null}
          >
            {renderComponent()}
          </PageLoader>
        </CustomerBaiscDetailsLayout>
      </BreadcrumbProvider>
    </>
  );
};
