import { LoadState } from "../../constants/enums";

export interface IPolicyNotesState {
  list: IPolicyNote[];
  loading: LoadState;
  note: IPolicyNote;
  noteLoading: LoadState;
  error: string | null;
}

export interface IPolicyNote {
  customer_policy_id: number;
  incremental_number: number | null;
  notes_title: string | null;
  customer: string | null;
  policy_number: string | null;
  status_open_or_completed: string | null;
  assigned_to: string | null;
  date_created: string | null;
  due_date: string | null;
  due_time: string | null;
  date_completed: string | null;
  time_completed: string | null;
  file_upload: string | null;
  description: string | null;
  notes: string | null;
  status: string;
}

export const initialPolicyNotesState: IPolicyNotesState = {
  list: [],
  loading: LoadState.NotLoaded,
  note: {
    customer_policy_id: 0,
    incremental_number: null,
    notes_title: null,
    customer: null,
    policy_number: null,
    status_open_or_completed: null,
    assigned_to: null,
    date_created: null,
    due_date: null,
    due_time: null,
    date_completed: null,
    time_completed: null,
    file_upload: null,
    description: null,
    notes: null,
    status: "ACTIVE",
  },
  noteLoading: LoadState.NotLoaded,
  error: null,
};
