import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { initialInsuranceReportsState } from "./insuranceReports.types";
import { InsuranceReportsActions } from ".";
import {
  FETCH_INSURANCE_REPORTS_LIST_FAILED,
  FETCH_INSURANCE_REPORTS_LIST_PROGRESS,
  FETCH_INSURANCE_REPORTS_LIST_SUCCESS,
  FETCH_NO_POLICY_INSURANCE_REPORTS_FAILED,
  FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_FAILED,
  FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_PROGRESS,
  FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_SUCCESS,
  FETCH_NO_POLICY_INSURANCE_REPORTS_PROGRESS,
  FETCH_NO_POLICY_INSURANCE_REPORTS_SUCCESS,
} from "./insuranceReportsActions";

export const insuranceReducer = (
  state: IStoreState["insuranceReports"] = initialInsuranceReportsState,
  action: InsuranceReportsActions
) => {
  switch (action.type) {
    case FETCH_INSURANCE_REPORTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_INSURANCE_REPORTS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INSURANCE_REPORTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.noPolicyLoading = LoadState.InProgress;
        draftState.noPolicyList = [];
        draftState.noPolicyTotalRecords = 0;
      });
      return newState;
    }
    case FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_SUCCESS: {
      const { noPolicyList, noPolicyTotalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.noPolicyLoading = LoadState.Loaded;
        draftState.noPolicyList = noPolicyList;
        draftState.noPolicyTotalRecords = noPolicyTotalRecords;
      });
      return newState;
    }
    case FETCH_NO_POLICY_INSURANCE_REPORTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.noPolicyLoading = LoadState.Failed;
        draftState.noPolicyList = [];
        draftState.noPolicyTotalRecords = 0;
      });
      return newState;
    }

    case FETCH_NO_POLICY_INSURANCE_REPORTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.InProgress;
        draftState.data = initialInsuranceReportsState["data"];
      });
      return newState;
    }
    case FETCH_NO_POLICY_INSURANCE_REPORTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_NO_POLICY_INSURANCE_REPORTS_FAILED: {
      const {errorMessage} = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.dataLoading = LoadState.Failed;
        draftState.data = initialInsuranceReportsState["data"];
        draftState.error = errorMessage;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
