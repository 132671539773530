import moment, { Moment } from "moment";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileApplicationState {
  data: IAutomobileApplication[];
  listLoading: LoadState;
  application: IAutomobileApplication;
  loading: LoadState;

}

export interface IAutoDescribedSectionOne {
  key: string;
  autoNo: number;
  modelYear: number | null;
  makeTradeName: string | null;
  model: string | null;
  bodyType: string | null;
  noCylinders: number | null;
  grossVehicleWeight: string | null;
}

export interface IAutoDescribedSectionTwo {
  key: string;
  autoNo: number;
  vinNumber: string | null;
  ownedLeased: string | null;
  purchaseLeasedDate: string | null;
  purchaseLeased: string | null;
  purchasePrice: number | null;
  automobileUsedPleasure: string | null;
  automobileUsedOneWay: string | null;
  automobileUsedPrecentage: number | null;
  automobileUsedFarm: string | null;
  automobileUsedComm: string | null;
}

export interface IAutoDescribedSectionThree {
  key: string;
  autoNo: number;
  annualDrivingDistance: number | null;
  automobileUsedForPooling: string | null;
  automobileUsedForPoolingIfYes: string | null;
  typefFuelUsed: string | null;
  typefFuelUsedIfYes: string | null;
  unrepairedDamage: string | null;
  modifiedCustomized: string | null;
}
export interface IAutomobileApplication {
  customer_policy_id: number | null;
  incremental_number: number | null;
  new_policy: boolean;
  replacing_policy_no: string | null;
  company_bill: boolean;
  broker_or_agent_bill: boolean;
  other_specify: string | null;
  language_preferred: string | null;
  policy_no_assigned: string | null;
  insurance_company: string | null;
  broke_or_agent: string | null;
  insurance_name: string | null;
  insurance_address: string | null;
  insurance_unit_or_suite: string | null;
  insurance_city: string | null;
  insurance_state: string | null;
  insurance_country: string | null;
  insurance_zip: string | null;
  broker_agent: string | null;
  broker_agent_address: string | null;
  broker_agent_unit_or_suite: string | null;
  broker_agent_city: string | null;
  broker_agent_state: string | null;
  broker_agent_country: string | null;
  broker_agent_zip: string | null;
  broker_code: string | null;
  certificate_holder_name: string | null;
  certificate_holder_full_address: string | null;
  certificate_holder_email: string | null;
  certificate_holder_phone: string | null;
  lessor_name: string | null;
  lessor_full_address: string | null;
  lessor_email: string | null;
  lessor_phone: string | null;
  policy_period_effective_date: string | null;
  policy_period_effective_time: Moment;
  policy_period_expiry_date: string | null;
  policy_period_expiry_time: Moment;
  described_automobiles_part_one: IAutoDescribedSectionOne[];
  described_automobiles_part_two: IAutoDescribedSectionTwo[];
  described_automobiles_part_three: IAutoDescribedSectionThree[];
  described_automobiles_part_four: {
    key: string;
    autoNo: number;
    lienholderName: string;
    postalAddress: string;
  }[];
  described_automobiles_registered_owner_actual_owner: string | null;
  describe_automobiles_total_automobiles_in_business: string | null;
  describe_automobiles_use_cases: string | null;
  driver_information_part_one: {
    key: string;
    driverNo: number | null;
    nameofDriver: string | null;
    driverLicenseNumber: string | null;
    dateOfBirth: string;
    sex: string;
    martialStatus: string;
  }[];
  driver_information_part_two: {
    key: string;
    driverNo: number | null;
    driverTrainingCertificateAttached: string | null;
    dateFirstLicencedInCanadaorUSClass: string | null;
    dateFirstLicencedInCanadaorUSYear: string | null;
    dateFirstLicencedInCanadaorUSMonth: string | null;
    otherClassofLicenseIfAnyClass: string | null;
    otherClassofLicenseIfAnyYear: string | null;
    otherClassofLicenseIfAnyMonth: string | null;
    percentageUseByEachDriverAuto1: string | null;
    percentageUseByEachDriverAuto2: string | null;
    percentageUseByEachDriverAuto3: string | null;
    areAnyOtherPersonsInTheHousehold: string | null;
    doAnyDriversQualifyForRetireeDiscount: string | null;
  }[];
  has_any_driver_license: boolean | null;
  has_any_insurance_company: boolean | null;
  during_the_last_three_years: boolean | null;
  has_the_applicant_or_any_listed_driver: boolean | null;
  previous_accidents_and_insurance_claims: {
    key: string;
    driverNo: string | null;
    autoNo: string | null;
    date: string | null;
    coverageClaimPaidUnder: string | null;
    amountPaidOrEstimate: string | null;
    details: string | null;
  }[];
  history_of_convictions: {
    key: string;
    driverNo: string | null;
    dateConvicted: string | null;
    details: string;
  }[];
  rating_info_part_one: {
    key: string;
    autoNo: number | string;
    class: string | null;
    drivingRecord: string | null;
    bi: string | null;
    pd: string | null;
    ab: string | null;
    dcpd: string | null;
    coll_ap: string | null;
    driverNoPrimary: string | null;
    driverNoSceondary: string | null;
    atFaultClaimSurcharges: string | null;
    atFaultClaimPrecentage: string | null;
    convictionsSurcharges: string | null;
    convictionsPrecentage: string | null;
  }[];
  rating_info_part_two: {
    key: string;
    autoNo: number;
    listPriceView: string | null;
    vehicleCode: string | null;
    ratingGroupAb: string | null;
    ratingGroupDcpd: string | null;
    ratingGroupCollAp: string | null;
    ratingGroupcompSp: string | null;
    location: string | null;
    territory: string | null;
    discountDescription: string | null;
    discountPrecentage: number | null;
  }[];
  insurance_coverages_applied_for_part_one: {
    bodilyInjury: {
      automobile1: {
        limit: string | null;
        premimum: string | null;
      };
      automobile2: {
        limit: string | null;
        premimum: string | null;
      };
      automobile3: {
        limit: string | null;
        premimum: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    propertyDamage: {
      automobile1: {
        limit: string | null;
        premimum: string | null;
      };
      automobile2: {
        limit: string | null;
        premimum: string | null;
      };
      automobile3: {
        limit: string | null;
        premimum: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    standardBenefits: {
      automobile1: {
        limit: string | null;
        premimum: string | null;
      };
      automobile2: {
        limit: string | null;
        premimum: string | null;
      };
      automobile3: {
        limit: string | null;
        premimum: string | null;
      };
      occasionalDriverPremium: string | null;
    };
  };
  insurance_coverages_applied_for_part_two: {
    incomeReplacement: {
      checkBox: string;
      automobile1: {
        limit: string | null;
        premimum: string | null;
      };
      automobile2: {
        limit: string | null;
        premimum: string | null;
      };
      automobile3: {
        limit: string | null;
        premimum: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    medicalRehabilitation: {
      checkBox: string;
      automobile1: {
        limit: string | null;
        premimum: string | null;
      };
      automobile2: {
        limit: string | null;
        premimum: string | null;
      };
      automobile3: {
        limit: string | null;
        premimum: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    optionalCatastrophicImpairement: {
      checkBox: string;
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    caregiver: {
      checkBox: string;
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    deathFuneral: {
      checkBox: string;
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    dependentCare: {
      checkBox: string;
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    indexationBenefit: {
      checkBox: string;
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    uninsuredAutomobile: {
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
    directCompensationPropertyDamage: {
      automobile1: string | null;
      automobile2: string | null;
      automobile3: string | null;
      occasionalDriverPremium: string | null;
    };
  };
  insurance_coverages_applied_for_part_three: {
    specifiedPerils: {
      automobile1: {
        deductible: string | null;
        premium: string | null;
      };
      automobile2: {
        deductible: string | null;
        premium: string | null;
      };
      automobile3: {
        deductible: string | null;
        premium: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    comprehensive: {
      automobile1: {
        deductible: string | null;
        premium: string | null;
      };
      automobile2: {
        deductible: string | null;
        premium: string | null;
      };
      automobile3: {
        deductible: string | null;
        premium: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    collision: {
      automobile1: {
        deductible: string | null;
        premium: string | null;
      };
      automobile2: {
        deductible: string | null;
        premium: string | null;
      };
      automobile3: {
        deductible: string | null;
        premium: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    allPerils: {
      automobile1: {
        deductible: string | null;
        premium: string | null;
      };
      automobile2: {
        deductible: string | null;
        premium: string | null;
      };
      automobile3: {
        deductible: string | null;
        premium: string | null;
      };
      occasionalDriverPremium: string | null;
    };
  };
  insurance_coverages_applied_for_part_four: {
    familyProtectionCoverageOPCF44R: {
      yes: boolean;
      no: boolean;
      automobile1: {
        deductible: string | null;
        premium: string | null;
      };
      automobile2: {
        deductible: string | null;
        premium: string | null;
      };
      automobile3: {
        deductible: string | null;
        premium: string | null;
      };
      occasionalDriverPremium: string | null;
    };
    others: {
      key: string;
      label: string | null;
      automobile1Deductible: string | null;
      automobile1Premium: string | null;
      automobile2Deductible: string | null;

      automobile2Premium: string | null;
      automobile3Deductible: string | null;
      automobile3Premium: string | null;

      occasionalDriverPremium: string | null;
    }[];
  };
  type_of_payment_plan: string | null;
  type_of_month: string | null;
  method_of_payment: string | null;
  estimated_policy_premium: string | null;
  provincial_sales_tax: string | null;
  interest: string | null;
  interest_amount: string | null;
  total_estimated_cost: string | null;
  amount_paid_with_application: string | null;
  amount_due: string | null;
  no_of_remaining_installments: string | null;
  amount_of_each_installment: string | null;
  installment_due_date: string | null;
  have_you_bound_the_risk: string | null;
  is_this_business_new_to_you: string | null;
  type_of_motor_vehicle_liability_insurance_card: string | null;
  how_long_you_have_known_the_applicant: string | null;
  has_an_inspection_been_done: string | null;
  status: null;
}

export const initialAutomobileApplicationState: IAutomobileApplicationState = {
  application: {
    customer_policy_id: 0,
    incremental_number: null,
    new_policy: false,
    replacing_policy_no: null,
    company_bill: false,
    broker_or_agent_bill: false,
    other_specify: null,
    language_preferred: null,
    policy_no_assigned: null,
    insurance_company: null,
    broke_or_agent: null,
    insurance_name: null,
    insurance_address: null,
    insurance_unit_or_suite: null,
    insurance_city: null,
    insurance_state: null,
    insurance_country: null,
    insurance_zip: null,
    broker_agent: null,
    broker_agent_address: null,
    broker_agent_unit_or_suite: null,
    broker_agent_city: null,
    broker_agent_state: null,
    broker_agent_country: null,
    broker_agent_zip: null,
    broker_code: null,
    certificate_holder_name: null,
    certificate_holder_full_address: null,
    certificate_holder_email: null,
    certificate_holder_phone: null,
    lessor_name: null,
    lessor_full_address: null,
    lessor_email: null,
    lessor_phone: null,
    policy_period_effective_date: null,
    policy_period_effective_time: moment(),
    policy_period_expiry_date: null,
    policy_period_expiry_time: moment().startOf("day").add(1, "minutes"),
    described_automobiles_part_one: [
      {
        key: getUniqueId(),
        autoNo: 1,
        bodyType: null,
        grossVehicleWeight: null,
        makeTradeName: null,
        model: null,
        modelYear: null,
        noCylinders: null,
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        bodyType: null,
        grossVehicleWeight: null,
        makeTradeName: null,
        model: null,
        modelYear: null,
        noCylinders: null,
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        bodyType: null,
        grossVehicleWeight: null,
        makeTradeName: null,
        model: null,
        modelYear: null,
        noCylinders: null,
      },
    ],
    described_automobiles_part_two: [
      {
        key: getUniqueId(),
        autoNo: 1,
        automobileUsedComm: null,
        automobileUsedFarm: null,
        automobileUsedOneWay: null,
        automobileUsedPleasure: null,
        automobileUsedPrecentage: null,
        purchaseLeasedDate: null,
        ownedLeased: null,
        purchaseLeased: null,
        purchasePrice: null,
        vinNumber: null,
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        automobileUsedComm: null,
        automobileUsedFarm: null,
        automobileUsedOneWay: null,
        automobileUsedPleasure: null,
        automobileUsedPrecentage: null,
        purchaseLeasedDate: null,
        ownedLeased: null,
        purchaseLeased: null,
        purchasePrice: null,
        vinNumber: null,
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        automobileUsedComm: null,
        automobileUsedFarm: null,
        automobileUsedOneWay: null,
        automobileUsedPleasure: null,
        automobileUsedPrecentage: null,
        purchaseLeasedDate: null,
        ownedLeased: null,
        purchaseLeased: null,
        purchasePrice: null,
        vinNumber: null,
      },
    ],
    has_any_driver_license: null,
    has_any_insurance_company: null,
    during_the_last_three_years: null,
    has_the_applicant_or_any_listed_driver: null,
    described_automobiles_part_three: [
      {
        key: getUniqueId(),
        autoNo: 1,
        annualDrivingDistance: null,
        automobileUsedForPooling: null,
        automobileUsedForPoolingIfYes: null,
        modifiedCustomized: null,
        typefFuelUsed: null,
        typefFuelUsedIfYes: null,
        unrepairedDamage: null,
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        annualDrivingDistance: null,
        automobileUsedForPooling: null,
        automobileUsedForPoolingIfYes: null,
        modifiedCustomized: null,
        typefFuelUsed: null,
        typefFuelUsedIfYes: null,
        unrepairedDamage: null,
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        annualDrivingDistance: null,
        automobileUsedForPooling: null,
        automobileUsedForPoolingIfYes: null,
        modifiedCustomized: null,
        typefFuelUsed: null,
        typefFuelUsedIfYes: null,
        unrepairedDamage: null,
      },
    ],
    described_automobiles_part_four: [
      {
        key: getUniqueId(),
        autoNo: 1,
        lienholderName: "",
        postalAddress: "",
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        lienholderName: "",
        postalAddress: "",
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        lienholderName: "",
        postalAddress: "",
      },
    ],
    described_automobiles_registered_owner_actual_owner: null,
    describe_automobiles_total_automobiles_in_business: null,
    describe_automobiles_use_cases: null,
    driver_information_part_one: [
      {
        key: getUniqueId(),
        nameofDriver: null,
        dateOfBirth: "",
        driverLicenseNumber: null,
        driverNo: 1,
        martialStatus: "",
        sex: "",
      },
      {
        key: getUniqueId(),
        nameofDriver: null,
        dateOfBirth: "",
        driverLicenseNumber: null,
        driverNo: 2,
        martialStatus: "",
        sex: "",
      },
      {
        key: getUniqueId(),
        nameofDriver: null,
        dateOfBirth: "",
        driverLicenseNumber: null,
        driverNo: 3,
        martialStatus: "",
        sex: "",
      },
      {
        key: getUniqueId(),
        nameofDriver: null,
        dateOfBirth: "",
        driverLicenseNumber: null,
        driverNo: 4,
        martialStatus: "",
        sex: "",
      },
    ],
    driver_information_part_two: [
      {
        key: getUniqueId(),
        driverNo: 1,
        areAnyOtherPersonsInTheHousehold: null,
        dateFirstLicencedInCanadaorUSClass: null,
        dateFirstLicencedInCanadaorUSMonth: null,
        dateFirstLicencedInCanadaorUSYear: null,
        doAnyDriversQualifyForRetireeDiscount: null,
        driverTrainingCertificateAttached: null,
        otherClassofLicenseIfAnyClass: null,
        otherClassofLicenseIfAnyMonth: null,
        otherClassofLicenseIfAnyYear: null,
        percentageUseByEachDriverAuto1: null,
        percentageUseByEachDriverAuto2: null,
        percentageUseByEachDriverAuto3: null,
      },
      {
        key: getUniqueId(),
        driverNo: 2,
        areAnyOtherPersonsInTheHousehold: null,
        dateFirstLicencedInCanadaorUSClass: null,
        dateFirstLicencedInCanadaorUSMonth: null,
        dateFirstLicencedInCanadaorUSYear: null,
        doAnyDriversQualifyForRetireeDiscount: null,
        driverTrainingCertificateAttached: null,
        otherClassofLicenseIfAnyClass: null,
        otherClassofLicenseIfAnyMonth: null,
        otherClassofLicenseIfAnyYear: null,
        percentageUseByEachDriverAuto1: null,
        percentageUseByEachDriverAuto2: null,
        percentageUseByEachDriverAuto3: null,
      },
      {
        key: getUniqueId(),
        driverNo: 3,
        areAnyOtherPersonsInTheHousehold: null,
        dateFirstLicencedInCanadaorUSClass: null,
        dateFirstLicencedInCanadaorUSMonth: null,
        dateFirstLicencedInCanadaorUSYear: null,
        doAnyDriversQualifyForRetireeDiscount: null,
        driverTrainingCertificateAttached: null,
        otherClassofLicenseIfAnyClass: null,
        otherClassofLicenseIfAnyMonth: null,
        otherClassofLicenseIfAnyYear: null,
        percentageUseByEachDriverAuto1: null,
        percentageUseByEachDriverAuto2: null,
        percentageUseByEachDriverAuto3: null,
      },
      {
        key: getUniqueId(),
        driverNo: 4,
        areAnyOtherPersonsInTheHousehold: null,
        dateFirstLicencedInCanadaorUSClass: null,
        dateFirstLicencedInCanadaorUSMonth: null,
        dateFirstLicencedInCanadaorUSYear: null,
        doAnyDriversQualifyForRetireeDiscount: null,
        driverTrainingCertificateAttached: null,
        otherClassofLicenseIfAnyClass: null,
        otherClassofLicenseIfAnyMonth: null,
        otherClassofLicenseIfAnyYear: null,
        percentageUseByEachDriverAuto1: null,
        percentageUseByEachDriverAuto2: null,
        percentageUseByEachDriverAuto3: null,
      },
    ],
    previous_accidents_and_insurance_claims: [
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
      {
        key: getUniqueId(),
        autoNo: null,
        amountPaidOrEstimate: null,
        coverageClaimPaidUnder: null,
        date: null,
        details: null,
        driverNo: null,
      },
    ],
    history_of_convictions: [
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
      {
        key: getUniqueId(),
        dateConvicted: null,
        details: "",
        driverNo: null,
      },
    ],
    rating_info_part_one: [
      {
        key: getUniqueId(),
        autoNo: 1,
        class: null,
        atFaultClaimSurcharges: null,
        convictionsSurcharges: null,
        driverNoPrimary: null,
        driverNoSceondary: null,
        drivingRecord: null,
        bi: null,
        pd: null,
        ab: null,
        dcpd: null,
        coll_ap: null,
        atFaultClaimPrecentage: null,
        convictionsPrecentage: null,
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        class: null,
        atFaultClaimSurcharges: null,
        convictionsSurcharges: null,
        driverNoPrimary: null,
        driverNoSceondary: null,
        drivingRecord: null,
        bi: null,
        pd: null,
        ab: null,
        dcpd: null,
        coll_ap: null,
        atFaultClaimPrecentage: null,
        convictionsPrecentage: null,
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        class: null,
        atFaultClaimSurcharges: null,
        convictionsSurcharges: null,
        driverNoPrimary: null,
        driverNoSceondary: null,
        drivingRecord: null,
        bi: null,
        pd: null,
        ab: null,
        dcpd: null,
        coll_ap: null,
        atFaultClaimPrecentage: null,
        convictionsPrecentage: null,
      },
      {
        key: getUniqueId(),
        autoNo: "O.D.",
        class: null,
        atFaultClaimSurcharges: null,
        convictionsSurcharges: null,
        driverNoPrimary: null,
        driverNoSceondary: null,
        drivingRecord: null,
        bi: null,
        pd: null,
        ab: null,
        dcpd: null,
        coll_ap: null,
        atFaultClaimPrecentage: null,
        convictionsPrecentage: null,
      },
    ],
    rating_info_part_two: [
      {
        key: getUniqueId(),
        autoNo: 1,
        discountDescription: null,
        discountPrecentage: null,
        listPriceView: null,
        location: null,
        ratingGroupAb: null,
        ratingGroupCollAp: null,
        ratingGroupcompSp: null,
        ratingGroupDcpd: null,
        territory: null,
        vehicleCode: null,
      },
      {
        key: getUniqueId(),
        autoNo: 2,
        discountDescription: null,
        discountPrecentage: null,
        listPriceView: null,
        location: null,
        ratingGroupAb: null,
        ratingGroupCollAp: null,
        ratingGroupcompSp: null,
        ratingGroupDcpd: null,
        territory: null,
        vehicleCode: null,
      },
      {
        key: getUniqueId(),
        autoNo: 3,
        discountDescription: null,
        discountPrecentage: null,
        listPriceView: null,
        location: null,
        ratingGroupAb: null,
        ratingGroupCollAp: null,
        ratingGroupcompSp: null,
        ratingGroupDcpd: null,
        territory: null,
        vehicleCode: null,
      },
    ],
    insurance_coverages_applied_for_part_one: {
      bodilyInjury: {
        automobile1: { limit: null, premimum: null },
        automobile2: { limit: null, premimum: null },
        automobile3: { limit: null, premimum: null },
        occasionalDriverPremium: null,
      },
      propertyDamage: {
        automobile1: { limit: null, premimum: null },
        automobile2: { limit: null, premimum: null },
        automobile3: { limit: null, premimum: null },
        occasionalDriverPremium: null,
      },
      standardBenefits: {
        automobile1: { limit: null, premimum: null },
        automobile2: { limit: null, premimum: null },
        automobile3: { limit: null, premimum: null },
        occasionalDriverPremium: null,
      },
    },
    insurance_coverages_applied_for_part_two: {
      caregiver: {
        checkBox: "N",
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      deathFuneral: {
        checkBox: "N",
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      dependentCare: {
        checkBox: "N",
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      directCompensationPropertyDamage: {
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      incomeReplacement: {
        checkBox: "N",
        automobile1: { limit: null, premimum: null },
        automobile2: { limit: null, premimum: null },
        automobile3: { limit: null, premimum: null },
        occasionalDriverPremium: null,
      },
      indexationBenefit: {
        checkBox: "N",
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      medicalRehabilitation: {
        checkBox: "N",
        automobile1: { limit: null, premimum: null },
        automobile2: { limit: null, premimum: null },
        automobile3: { limit: null, premimum: null },
        occasionalDriverPremium: null,
      },
      optionalCatastrophicImpairement: {
        checkBox: "N",
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
      uninsuredAutomobile: {
        automobile1: null,
        automobile2: null,
        automobile3: null,
        occasionalDriverPremium: null,
      },
    },
    insurance_coverages_applied_for_part_three: {
      allPerils: {
        automobile1: { deductible: null, premium: null },
        automobile2: { deductible: null, premium: null },
        automobile3: { deductible: null, premium: null },
        occasionalDriverPremium: null,
      },
      collision: {
        automobile1: { deductible: null, premium: null },
        automobile2: { deductible: null, premium: null },
        automobile3: { deductible: null, premium: null },
        occasionalDriverPremium: null,
      },
      comprehensive: {
        automobile1: { deductible: null, premium: null },
        automobile2: { deductible: null, premium: null },
        automobile3: { deductible: null, premium: null },
        occasionalDriverPremium: null,
      },
      specifiedPerils: {
        automobile1: { deductible: null, premium: null },
        automobile2: { deductible: null, premium: null },
        automobile3: { deductible: null, premium: null },
        occasionalDriverPremium: null,
      },
    },
    insurance_coverages_applied_for_part_four: {
      familyProtectionCoverageOPCF44R: {
        automobile1: { deductible: null, premium: null },
        automobile2: { deductible: null, premium: null },
        automobile3: { deductible: null, premium: null },
        yes: false,
        no: false,
        occasionalDriverPremium: null,
      },
      others: [
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
        {
          key: getUniqueId(),
          label: null,
          automobile1Deductible: null,
          automobile1Premium: null,
          automobile2Deductible: null,
          automobile2Premium: null,
          automobile3Deductible: null,
          automobile3Premium: null,
          occasionalDriverPremium: null,
        },
      ],
    },
    type_of_payment_plan: null,
    type_of_month: null,
    method_of_payment: null,
    estimated_policy_premium: null,
    provincial_sales_tax: null,
    interest: null,
    interest_amount: null,
    total_estimated_cost: null,
    amount_paid_with_application: null,
    amount_due: null,
    no_of_remaining_installments: null,
    amount_of_each_installment: null,
    installment_due_date: null,
    have_you_bound_the_risk: null,
    is_this_business_new_to_you: null,
    type_of_motor_vehicle_liability_insurance_card: null,
    how_long_you_have_known_the_applicant: null,
    has_an_inspection_been_done: null,
    status: null,
  },
  loading: LoadState.NotLoaded,
  data: [],
  listLoading: LoadState.NotLoaded,
};
