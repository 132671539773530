import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IBusinessPropertyInsurance } from "../../../../../../redux/businessPolicy/businessPropertyInsurance/businessPropertyInsurance.types";
import {
  downloadBusinessGarageCertificateListPDFAsync,
  fetchBusinessGarageCertificateListAsync,
  previewBusinessGarageCertificateListPDFAsync,
} from "../../../../../../redux/businessPolicy/businessGarageCertificate/businessGarageCertificateActions";
import { IBusinessGarageCertificate } from "../../../../../../redux/businessPolicy/businessGarageCertificate/businessGarageCertificate.types";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { CustomChip } from "../../../../../../components/CustomChip";
import { NotificationsActive, ThumbUpAlt } from "@mui/icons-material";

export const GarageCertificateList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const {
    businessGarageCertificateList: data,
    businessGarageCertificateListLoading: loading,
  } = useSelector((storeState: IStoreState) => storeState.business.certificate);
  const [downloading, setDownloading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const [businessGarage, setBusinessGarage] = React.useState<{
    isAllSelected: boolean;
    customers: IBusinessGarageCertificate[];
  }>({
    isAllSelected: false,
    customers: [],
  });

  const menuItems =
    businessGarage.customers.length > 0 || businessGarage.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const GarageTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "customer_policy_id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [...menuItems],
      },
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}/garage-insurance`
                  );
                }}
              >
                Add Garage Insurance
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "customer_policy_id",
        headerName: "ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessGarageCertificate) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}/garage-insurance/${row.incremental_number}`
                )
              }
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessGarageCertificate) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "cgl",
        headerName: "CGL Policy",
        fieldName: "cgl",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
      },

      {
        key: "vehicle_unit_number_or_vin",
        headerName: "Vehicle Unit Number or Vin",
        fieldName: "vehicle_unit_number_or_vin",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "certificate_holder_name",
        headerName: "Certificate Holder Name",
        fieldName: "certificate_holder_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "certificate_holder_email",
        headerName: "Certificate Holder Email",
        fieldName: "certificate_holder_email",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPropertyInsurance) => {
          return (
            <StandardTableActions
              downloadLoading={downloading}
              onDownloadPreview={() => {
                dispatch(
                  previewBusinessGarageCertificateListPDFAsync(
                    customerPolicyId,
                    row.incremental_number as number,
                    () => {}
                  )
                );
              }}
              onDownLoadClick={() => {
                if (row.incremental_number) {
                  setDownloading(true);
                  dispatch(
                    downloadBusinessGarageCertificateListPDFAsync(
                      customerPolicyId,
                      row.incremental_number,
                      () => {
                        setDownloading(false);
                      }
                    )
                  );
                }
              }}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}/garage-insurance/${row.incremental_number}`
                );
              }}
              onDuplicateClick={() => {
                navigate(
                  `/business-policy/${customerId}/policy-forms-tab/${customerPolicyId}/garage-insurance-duplicate/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: data,
    onSelection: (
      isAllSelected: boolean,
      rows: IBusinessGarageCertificate[]
    ) => {
      setBusinessGarage({ isAllSelected: isAllSelected, customers: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchBusinessGarageCertificateListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...GarageTableProps} />
      </Box>
    </>
  );
};
