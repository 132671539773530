import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IBillPaymentPayable } from "./BillPaymentPayable.types";

export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_PROGRESS =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_PROGRESS";
export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_SUCCESS =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_SUCCESS";
export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_FAILURE =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_FAILURE";

export const fetchAccountingBillPaymentPayableProgress = () =>
  action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_PROGRESS);

export const fetchAccountingBillPaymentPayableSuccess = (
  data: IBillPaymentPayable
) => action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_SUCCESS, { data });

export const fetchAccountingBillPaymentPayableFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_FAILURE, { errorMessage });

export const fetchAccountingBillPaymentPayableAsync =
  (paymentId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillPaymentPayableProgress());

      const res = await api.get(
        `/accounting/get-account-create-payment?column=payment_id&value=${paymentId}`
      );
      const data: IBillPaymentPayable[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingBillPaymentPayableSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingBillPaymentPayableFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingBillPaymentPayableAysnc =
  (
    data: IBillPaymentPayable,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-create-payment", {
        ...data,
      });
      let message = "Payment Payable saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_PROGRESS =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_PROGRESS";
export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_SUCCESS =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_SUCCESS";
export const FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_FAILURE =
  "FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_FAILURE";

export const fetchAccountingBillPaymentPayableListProgress = () =>
  action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_PROGRESS);

export const fetchAccountingBillPaymentPayableListSuccess = (
  data: IBillPaymentPayable[]
) => action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_SUCCESS, { data });

export const fetchAccountingBillPaymentPayableListFailed = (
  errorMessage: string
) =>
  action(FETCH_ACCOUNTING_BILL_PAYMENT_PAYABLE_LIST_FAILURE, { errorMessage });

export const fetchAccountingBillPaymentPayableListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillPaymentPayableListProgress());

      const res = await api.get(`/accounting/get-account-create-payment`);
      const data: IBillPaymentPayable[] = res.data.data;
      dispatch(fetchAccountingBillPaymentPayableListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchAccountingBillPaymentPayableListFailed(
          "Something went to be wrong!"
        )
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_BILL_PAYMENT_PAYABLE =
  "CLEAR_ACCOUNTING_BILL_PAYMENT_PAYABLE";
export const clearAccountingBillPaymentPayable = () =>
  action(CLEAR_ACCOUNTING_BILL_PAYMENT_PAYABLE);
