import {
  IRow,
  TABLE_ROW_TYPE,
} from "../../../components/Table/interfaces/IDataTableProps";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ICommAutomobileScheduleState {
  scheduleA: {
    loading: LoadState;
    data: ICommAutomobileScheduleA;
    listData: ICommAutomobileScheduleA[];
    listLoading: LoadState;
  };
  scheduleB: {
    loading: LoadState;
    data: ICommAutomobileScheduleB;
    listData: ICommAutomobileScheduleB[];
    listLoading: LoadState;
  };
  scheduleC: {
    loading: LoadState;
    data: ICommAutomobileScheduleC;
    listData: ICommAutomobileScheduleC[];
    listLoading: LoadState;
  };
}

export interface ICommAutomobileScheduleA {
  incremental_number: number | null; 
  customer_policy_id: number;
  vehicle_number: number | null; 
  coverage_number: number | null; 
  section_one_third_party_liability: ICommScheduleAColumns[];
  section_two_accident_benefits: ICommScheduleAColumns[];
  optional_increased_accident_benefits: ICommScheduleAColumns[];
  section_three: ICommScheduleAColumns[];
  section_four: ICommScheduleAColumns[];
  section_five_loss_or_damage: ICommScheduleAColumns[];
  section_six_policy_change_forms: ICommScheduleAColumns[];
  // endorsements: ICommScheduleAColumns[];
  set_as_default: string | null;
  status: string | null;
  minimum_retained_premium: number | null;
  total_premium: number | null;
}

export interface ICommScheduleAColumns {
  key?: string;
  label: string | null;
  declined: string | null;
  deductibleOrLimit: string | null;
  premium: string;
  action: string | null;
}
export interface ICommAutomobileScheduleB {
  customer_policy_id: number;
  incremental_number: number | null,
  vehicle_number: number | null,
  section_one_third_party_liability: ICommScheduleBColumns[];
  section_two_accident_benefits: ICommScheduleBColumns[];
  optional_increased_accident_benefits: ICommScheduleBColumns[];
  section_three: ICommScheduleBColumns[];
  section_four: ICommScheduleBColumns[];
  section_five_loss_or_damage: ICommScheduleBColumns[];
  section_six_policy_change_forms: ICommScheduleBColumns[];
  endorsements: ICommScheduleBColumns[];
  status: string | null;
}
export interface ICommScheduleBColumns {
  key?: string;
  label: string | null;
  declined: string | null;
  deductibleOrLimit: string | null;
  premium: string;
  action: string | null;
}

export interface ICommAutomobileScheduleCColumns {
  key?: string;
  label: string;
  deductible: string | null;
  limit: string | null;
  premium: string;
  action: null;
}

export interface ICommAutomobileScheduleC {
  customer_policy_id: number;
  incremental_number: string | null,
  vehicle_number: string,
  section_one_third_party_liability: ICommAutomobileScheduleCColumns[];
  section_two_accident_benefits: ICommAutomobileScheduleCColumns[];
  optional_increased_accident_benefits: ICommAutomobileScheduleCColumns[];
  section_three: ICommAutomobileScheduleCColumns[];
  section_four: ICommAutomobileScheduleCColumns[];
  section_five_lossof_or_damage_to_owned_automobiles: {
    label: string;
    locationPerItem1: string;
    subsectionInsured: string | null;
    deductibleOfEachOccurrence: string | null;
    limitofLiability: string | null;
    advacnePremimum: string;
  }[];
  section_six_liability_for_damage_to_a_customer_automobile: {
    label: string;
    locationPerItem1: string;
    maximumNumberOfCustomers: string | null;
    deductibleOfEachOccurrence: string | null;
    limitofLiability: string | null;
    advacnePremimum: string;
  }[];
  section_six_b: {
    label: string;
    deductibleOfEachOccurrence: string | null;
    limitofLiability: string | null;
    advacnePremimum: string;
  }[];
  // section_six_c_other_endorsements: {
  //   label: string;
  //   deductibleOfEachOccurrence: string | null;
  //   limitofLiability: string | null;
  //   advacnePremimum: string;
  // };
  collision_or_upset: {
    deductibleOfEachOccurrence: string | null;
    limitofLiability: string | null;
    advacnePremimum: string;
  };
  status: string | null;
  minimum_retained_premium: string | null;
  total_premium: string | null;
  each_separate_automobile_deductible: string | null;
  each_separate_automobile_premium: string | null;
  MCO: string | null;
}
export interface ICommScheduleCCoverage extends IRow {
  type: string;
  rowType: TABLE_ROW_TYPE;
  rowLabel?: string;
  DECLINED?: string | null;
  DEDUCTIBLE?: string | null;
  PREMIUM?: string | null;
  ACTION?: string | null;
  LIMIT?: string | null;
}

export interface ICommScheduleCLoss {
  label: string;
  locationPerItem1: string;
  subsectionInsured: string;
  deductibleOfEachOccurrence: string;
  limitofLiability: string;
  advacnePremimum: string;
}

export interface ICommScheduleCCollision {
  label: string;
  locationPerItem1: string;
  maximumNumberOfCustomers: string;
  deductibleOfEachOccurrence: string;
  limitofLiability: string;
  advacnePremimum: string;
  action?: string;
  rowCellsConfig?: IRow["rowCellsConfig"];
}

export interface ICommScheduleCCollision {}

export const initialCommAutomoblieSchedule: ICommAutomobileScheduleState = {
  scheduleA: {
    loading: LoadState.NotLoaded,
    data: {
      incremental_number: null,
      customer_policy_id: 0,
      vehicle_number:null, 
      coverage_number: null, 
      set_as_default: "N",
      minimum_retained_premium: null,
      total_premium: null,
      section_one_third_party_liability: [
        {
          key: getUniqueId(),
          label: "Body Injury",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: "Property Damage",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_two_accident_benefits: [
        {
          label: "Accidents Benefits ( Standard Benefits )",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      optional_increased_accident_benefits: [
        {
          label: "Income Replacement ($600/$800/$1000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Medical, Rehabilitation and Attendant Care($1,30,000 or $1,000,000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Optional Catastrophic Impairment Benefit (Additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care)",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Caregiver, Housekeeping and Home Maintenance",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Death and Funeral",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Dependent Care",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Indexation Benefits ( Consumer Price Index )",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
      ],
      section_three: [
        {
          label: "Uninsured Automobile",
          declined: null,
          deductibleOrLimit: "As Stated in Section 5 of the Policy",
          premium: "",
          action: null,
        },
      ],
      section_four: [
        {
          label: "Direct Compensation Property Damage (DCPD)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_five_loss_or_damage: [
        {
          label: "Specified Perils (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Comprehensive (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Collision or Upset",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "All Perils",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_six_policy_change_forms: [
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      status: "ACTIVE",
    },
    listData: [],
    listLoading: LoadState.NotLoaded,
  },

  scheduleB: {
    loading: LoadState.NotLoaded,
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      vehicle_number: null,
      section_one_third_party_liability: [
        {
          label: "Body Injury",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Property Damage",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_two_accident_benefits: [
        {
          label: "Accidents Benefits ( Standard Benefits )",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      optional_increased_accident_benefits: [
        {
          label: "Income Replacement ($600/$800/$1000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Medical, Rehabilitation and Attendant Care($1,30,000 or $1,000,000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Optional Catastrophic Impairment Benefit (Additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Caregiver, Housekeeping and Home Maintenance",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Death and Funeral",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Dependent Care",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Indexation Benefits ( Consumer Price Index )",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_three: [
        {
          label: "Uninsured Automobile",
          declined: null,
          deductibleOrLimit: "As Stated in Section 5 of the Policy",
          premium: "",
          action: null,
        },
      ],
      section_four: [
        {
          label: "Direct Compensation Property Damage (DCPD)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_five_loss_or_damage: [
        {
          label: "1. Specified Perils (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "2. Comprehensive (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "3. Collision or Upset",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "4. All Perils",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_six_policy_change_forms: [
        {
          label: "Family Protection Coverage – OPCF 44R",
          declined: null,
          deductibleOrLimit: "5000",
          premium: "",
          action: null,
        },
      ],
      endorsements: [
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      status: "ACTIVE",
    },
    listData: [],
    listLoading: LoadState.NotLoaded,
  },

  scheduleC: {
    data: {
      customer_policy_id: 0,
      incremental_number: null,
      vehicle_number: "",
      section_one_third_party_liability: [
        {
          key: getUniqueId(),
          label: "Third Party Inclusive Limit",
          deductible: null,
          limit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: "Body Injury",
          deductible: null,
          limit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: "Property Damage",
          deductible: null,
          limit: null,
          premium: "",
          action: null,
        },
      ],
      section_two_accident_benefits: [
        {
          label: "Standard Benefits",
          deductible: null,
          limit: null,
          premium: "",
          action: null,
        },
      ],
      optional_increased_accident_benefits: [
        {
          label: "Income Replacement ($600/$800/$1000)",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Medical, Rehabilitation & Attendant care ($1,30,000 or $1,000,000)",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Optional Catastrophic Impairment (Additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care Benefit)",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label: "Caregiver, Housekeeping & Home Maintenance",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label: "Death & Funeral",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label: "Dependent Care",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
        {
          label: "Indexation Benefit (Consumer Price Index)",
          deductible: "N",
          limit: null,
          premium: "",
          action: null,
        },
      ],
      section_three: [
        {
          label: "Uninsured Automobile",
          deductible: "As Stated in Section 3 of the Policy",
          limit: null,
          premium: "",
          action: null,
        },
      ],
      section_four: [
        {
          label: "Direct Compensation - Property Damage (DCPD)",
          deductible: null,
          limit: null,
          premium: "",
          action: null,
        },
      ],
      section_five_lossof_or_damage_to_owned_automobiles: [
        {
          label:
            "5.1.2 Comprehensive (Excluding Collision or Upset and Open Lot Theft)",
          locationPerItem1: "A",
          subsectionInsured: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "5.1.3 Specified Perils (Excluding Open Lot Theft)",
          locationPerItem1: "B",
          subsectionInsured: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "5.1.4 Specified Perils (Excluding Theft)",
          locationPerItem1: "C",
          subsectionInsured: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          locationPerItem1: "D",
          subsectionInsured: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
      ],
      section_six_liability_for_damage_to_a_customer_automobile: [
        // {
        //   label:
        //     "Comprehensive (Excluding Collision or Upset and Open Lot Theft)",
        //   locationPerItem1: "A",
        //   maximumNumberOfCustomers: "",
        //   deductibleOfEachOccurrence: null,
        //   limitofLiability: null,
        //   advacnePremimum: null,
        // },
        {
          label: "6.4 Specified Perils (Excluding Open Lot Theft)",
          locationPerItem1: "A",
          maximumNumberOfCustomers: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          locationPerItem1: "B",
          maximumNumberOfCustomers: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          locationPerItem1: "C",
          maximumNumberOfCustomers: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          locationPerItem1: "D",
          maximumNumberOfCustomers: null,
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
      ],
      section_six_b: [
        {
          label: "O.E.F. 81 Garage Family Protection Endorsements",
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
        {
          label: "",
          deductibleOfEachOccurrence: null,
          limitofLiability: null,
          advacnePremimum: "",
        },
      ],
      collision_or_upset: {
        deductibleOfEachOccurrence: null,
        limitofLiability: null,
        advacnePremimum: "",
      },
      // section_six_c_other_endorsements: {
      //   label: "DD Garage Endorsements",
      //   deductibleOfEachOccurrence: null,
      //   limitofLiability: null,
      //   advacnePremimum: "",
      // },
      status: "ACTIVE",
      minimum_retained_premium: null,
      total_premium: null,
      each_separate_automobile_deductible: null,
      each_separate_automobile_premium: null,
      MCO: null,
    },
    loading: LoadState.NotLoaded,
    listData: [],
    listLoading: LoadState.NotLoaded,
  },
};
