import produce from "immer";
import { CompanyInformationActions } from ".";
import { IStoreState } from "../../initialStoreState";
import { initialAccountingCompanyInformation } from "./companyInformation.types";
import {
  FETCH_ACCOUNTING_COMPANY_INFORMATION_FAILURE,
  FETCH_ACCOUNTING_COMPANY_INFORMATION_PROGRESS,
  FETCH_ACCOUNTING_COMPANY_INFORMATION_SUCCESS,
} from "./companyInformationActions";
import { LoadState } from "../../../constants/enums";

export const AccountingCompanyInformationReducer = (
  state: IStoreState["accounting"]["accountingCompanyInformation"] = initialAccountingCompanyInformation,
  action: CompanyInformationActions
) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_COMPANY_INFORMATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_COMPANY_INFORMATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_ACCOUNTING_COMPANY_INFORMATION_FAILURE: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    default:
      return state;
  }
};
