import { AddOutlined, AppRegistration, AspectRatio } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { api } from "../../api/api";
import { StandadCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";
import { DataTable } from "../../components/Table/DataTable";
import {
  IData,
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../components/Table/plugins";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { IInsurerUnderWriter } from "../../redux/insurer/insurer.types";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/messages/messagesActions";
import { Button, MenuItem, Typography } from "@mui/material";
import { CustomSelect } from "../../components/formsComponents";
import { formatDateWithTime, renderDropdownValue } from "../../helpers";
import { ViewUnderWritersDetails } from "./components/UnderWriters/ViewUnderWriters/ViewUnderWritersDetails";
import { IUnderWriter } from "../../redux/underWriter/underWriter.types";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { MessagesDialogWrapper } from "../../components/MessagesDialogs/MessagesDialogWrapper/MessagesDialogWrapper";
import { CallDialog } from "../../components/MessagesDialogs/CallDialog/CallDialog";
import { WhatsAppDialog } from "../../components/MessagesDialogs/WhatsAppDialog/WhatsAppDialog";
import { SmsDialog } from "../../components/MessagesDialogs/SmsDialog/SmsDialog";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { EmailComposeRightPanel } from "../../components/MessagesDialogs/EmailCompose/EmailCompose";
import { StatusRenderer } from "../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import moment from "moment";
import { RoleBasedEmailRightPanel } from "../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { MODULE_IDS } from "../../constants/enums";

interface IUnderWriterListBaseProps {
  hideColumnsName?: string[];
  hideTableFilters?: boolean;
  onSelectionUnderwritter?: (
    isAllSelected: boolean,
    rows: IInsurerUnderWriter[]
  ) => void;
}

export const UnderWritersList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "underwriters",
    },
  ];
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/underwriter-composer");
  };

  return (
    <PageContainer title="Underwriter" description="this is innerpage">
      <Breadcrumb title="Underwriters" items={BCrumb} />

      <StandadCard
        heading="Underwriters"
        rightHeading={
          <Button variant="contained" onClick={handleCreate} startIcon={<AddOutlined />}>
            Create
          </Button>
        }
      >
        <UnderWritersListBase />
      </StandadCard>
    </PageContainer>
  );
};

export const UnderWritersListBase: React.FC<IUnderWriterListBaseProps> = (
  props
) => {
  const { hideColumnsName = [], hideTableFilters } = props;
  const [status, setStatus] = React.useState<"ACTIVE" | "INACTIVE" | "-1">(
    "ACTIVE"
  );
  const [list, setList] = React.useState<IUnderWriter[]>([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [selectedUnderWriters, setSelectedUnderWriters] = React.useState<{
    isAllSelected: boolean;
    underwriter: IInsurerUnderWriter[];
  }>({
    isAllSelected: false,
    underwriter: [],
  });
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const [openView, setOpenView] = React.useState<IInsurerUnderWriter | null>(
    null
  );

  const [searchType, setSearchType] = React.useState<string[]>(["name"]);
  const [search, setSearch] = React.useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchList = async () => {
    try {
      setLoading(true);
      // const finalStatus = status === "-1" ? "" : status;
      // const res = await api.get(
      //   `/insurance/get-underwriters?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}&status=${finalStatus}`
      // );
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/insurance/get-underwriters?pageNo=${tablePagination.pageNumber}&itemPerPage=${tablePagination.rowsInPerPage}${url}`;
      if (searchType.length > 0 && search) {
        finalUrl = `/insurance/get-underwriters?pageNo=${
          tablePagination.pageNumber
        }&itemPerPage=${tablePagination.rowsInPerPage}${url}&column=${[
          searchType,
        ]}&value=${search}`;
      }
      const res = await api.get(finalUrl);
      setList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, status, search, searchType]);

  const menuItems =
    selectedUnderWriters.underwriter.length > 0 ||
    selectedUnderWriters.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const underwritersTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading,
    selectionMode: "multiple",
    uniqueRowKeyName: "underwriters_id",
    tableCommandBarProps: {
          leftItems: {
            customPlugins: [
              ...menuItems,
              {
                key: "status",
                onRender: () => (
                  <CustomSelect
                    sx={{ minWidth: "120px" }}
                    value={status}
                    onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                  >
                    <MenuItem value="-1">All Status</MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </CustomSelect>
                ),
              },
              {
                key: "refresh",
                onRender: () => (
                  <RefreshPlugin
                    onClick={() => {
                      fetchList();
                    }}
                  />
                ),
              },
              // {
              //   key: "csvExport",
              //   onRender: (columns, items) => (
              //     <CSVExportPlugin
              //       columns={columns}
              //       items={items}
              //       filePrefixName="Report"
              //     />
              //   ),
              // },
            ],
          },
          rightItems: {
            plugins: {
              // searchField: {
                // searchKeys: [
                //   "contact_no",
                //   "membership_code",
                //   "user_name",
                //   "user_prospect_dim_id",
                //   "status",
                //   "user_id",
                //   "invoice_number",
                //   "user_name",
                //   "user_prospect_id",
                //   "vehicle_no",
                //   "cheque_no",
                //   "cheque_status"
                // ],
                // items: list,
              // },
            },
            customPlugins: [
              {
                key: "search",
                onRender: () => {
                  return (
                    <SearchPlugin
                      selectedDropdownValue={searchType}
                      dropdownOptions={[
                        { label: "Insurer Code", value: "insurer_code" },
                        { label: "Name", value: "name" },
                        { label: "Phone Number", value: "phone_number" },
                        { label: "Mobile Number", value: "mobile_number" },
                        { label: "Email", value: "email" },
                        { label: "Department Email", value: "department_email" },
                        { label: "Line of Business", value: "line_of_business" },
                      ]}
                      onDropdownChange={(value) => setSearchType(value)}
                      onChange={(newValue) => {
                        setSearch(newValue);
                      }}
                    />
                  );
                },
              },
            ],
          },
        },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value: any, row: IInsurerUnderWriter) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "insurer_code",
        headerName: "Insurer Code",
        fieldName: "insurer_code",
        renderType: RenderType.TEXT_DARK,
        enableSorting: true,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        renderType: RenderType.CUSTOM_RENDER,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <CustomLink
              to={
                "/underwriter-composer/" +
                (row as IInsurerUnderWriter).underwriters_id
              }
              label={value.value}
            />
          );
        },
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedEmailRightPanel
              displayLabel={row.email}
              toEmail={row.email}
              moduleId={MODULE_IDS.UNDERWRITERS}
            />
          );
        },
      },
      {
        key: "department_email",
        headerName: "Department Email",
        fieldName: "department_email",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        enableSorting: true,
      },
      {
        key: "phone",
        headerName: "Phone",
        fieldName: "phone_number",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        enableSorting: true,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          return (
            <RoleBasedMessages phone={row.phone_number} 
            moduleId={MODULE_IDS.UNDERWRITERS}
            />
          );
        },
      },
      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        renderType: RenderType.DATE_DARK_COLOR,
        enableSorting: true,
        exportCellWidth: 25,
        onExportRender: (values: any) => {
          return formatDateWithTime(values.value);
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value: IData, row: IInsurerUnderWriter) => {
          const menuItems = [
            {
              icon: <AspectRatio />,
              label: "See Details",
              onClick: () => setOpenView(row),
            },
            {
              icon: <AppRegistration />,
              label: "View & Edit",
              onClick: () => {
                navigate(
                  "/underwriter-composer/" +
                    (row as IInsurerUnderWriter).underwriters_id
                );
              },
            },
          ];

          return (
            <StandardTableActions
              onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  "/underwriter-composer/" +
                    (row as IInsurerUnderWriter).underwriters_id
                );
              }}
              more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ].filter((x) => !hideColumnsName.includes(x.headerName)),
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: IInsurerUnderWriter[]) => {
      setSelectedUnderWriters({
        isAllSelected: isAllSelected,
        underwriter: rows,
      });
      if (props.onSelectionUnderwritter) {
        props.onSelectionUnderwritter(isAllSelected, rows);
      }
    },
  };

  return (
    <>
      <DataTable {...underwritersTableProps} />
      {openView && (
        <ViewUnderWritersDetails
          open={true}
          underwriter={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </>
  );
};
