import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialAutomobileApplicationState } from "./automobileApplication.types";
import { AutomobileApplicationActions } from ".";
import {
  CLEAR_AUTOMOBILE_APPLICATION_STATE,
  FETCH_AUTOMOBILE_APPLICATION_FAILED,
  FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED,
  FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS,
  FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS,
  FETCH_AUTOMOBILE_APPLICATION_PROGRESS,
  FETCH_AUTOMOBILE_APPLICATION_SUCCESSS,
} from "./automobileApplicationActions";

export const AutomobileApplicationReducer = (
  state: IStoreState["automobile"]["appication"] = initialAutomobileApplicationState,
  action: AutomobileApplicationActions
) => {
  switch (action.type) {
    case FETCH_AUTOMOBILE_APPLICATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.application =
          initialAutomobileApplicationState["application"];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_APPLICATION_SUCCESSS: {
      const {data} =action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.application =data
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_APPLICATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.application =
          initialAutomobileApplicationState["application"];
      });
      return newState;
    }

    case FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.data = [];
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Failed;
        draftState.data = [];
      });
      return newState;
    }

    case CLEAR_AUTOMOBILE_APPLICATION_STATE: {
      return initialAutomobileApplicationState;
    }

    default: {
      return state;
    }
  }
};
