import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchBusinessPolicyRiskListAsync } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";

export const RiskLocationList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.riskLocation
  );
  const dispatch = useDispatchWrapper();


  const riskLocationTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/risklocation`
                  );
                }}
              >
                Add Risk Location
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "location_number",
        headerName: "Loc #",
        fieldName: "location_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyRiskLocation) => {

          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/risklocation/${row.location_number}`
                )
              }
            >
              {row.location_number}
            </Link>
          );
        },
      },

      {
        key: "address",
        headerName: "Address",
        fieldName: "address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellValueRender: (value, row: IBusinessPolicyRiskLocation) => {
          return row.address + ", " + row.unit_or_suite + ", " + row.city + ", " + row.province_or_state + ", " + row.postal_code
        }
      },
      {
        key: "created_Date",
        headerName: "Date Created ",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyRiskLocation) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
              
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/risklocation/${row.location_number}`
                );
              }}
            //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyRiskListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...riskLocationTableProps} />
      </Box>
    </>
  );
};
