import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { IBusinessAttachment } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetails.types";
import { fetchBusinessAttachmentListAsync } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { LeadOppStatus } from "../../../../../../../components/LeadOppStatus/LeadOppStatus";
import { AttachmentBusiness } from "./Attachment";
import { useBreadcrumbContext } from "../../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { IBreadcrumbProps } from "../../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBusinessPolicyRestrutant } from "../../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";

const INITIAL_STATE = {
  customer_policy_id: 0,
  serial_number: null,
  document_name: "",
  file_upload: "",
  description: "",
  status: "",
  send_attachment: 0,
};

export const BusinessAttachmentList = () => {

  const params= useOutletContext() as any;
  const customerId = params[0];
  const customerPolicyId = params[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "attachments",
    },
  ];
  const { list: data, listLoading: loading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.attachment
  );
  const dispatch = useDispatchWrapper();

  const [openAttachment, setOpenAttachment] =
    React.useState<IBusinessAttachment | null>(null);

  const handleComplete = () => {
    dispatch(fetchBusinessAttachmentListAsync(customerPolicyId));
    setOpenAttachment(null);
  };

  const attachmentTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() =>
                  setOpenAttachment({
                    ...INITIAL_STATE,
                    customer_policy_id: customerPolicyId,
                  })
                }
              >
                Add Attachment
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenAttachment(row)}
            >
              {row.serial_number}
            </Link>
          );
        },
      },

      {
        key: "customer_policy_id",
        headerName: "Customer Policy ID",
        fieldName: "customer_policy_id",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },

      {
        key: "document_name",
        headerName: "Document Name",
        fieldName: "document_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return <LeadOppStatus status={row.status} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessAttachment) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenAttachment(row)}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);
  React.useEffect(() => {
    dispatch(fetchBusinessAttachmentListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...attachmentTableProps} />
      </Box>
      {openAttachment && (
        <AttachmentBusiness
          open={true}
          attachment={openAttachment}
          onClose={() => setOpenAttachment(null)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};
