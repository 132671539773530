import {
  AppRegistration,
  NotificationsActive,
  ThumbUpAlt,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate } from "react-router-dom";
import { useDispatchWrapper } from "../../../hooks";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { Box, Button, Chip, MenuItem, Typography } from "@mui/material";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { renderDropdownValue } from "../../../helpers";
import CallIcon from "@mui/icons-material/Call";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../constants/enums";

import { CustomChip } from "../../../components/CustomChip";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { MessagesDialogWrapper } from "../../../components/MessagesDialogs/MessagesDialogWrapper/MessagesDialogWrapper";
import { WhatsAppDialog } from "../../../components/MessagesDialogs/WhatsAppDialog/WhatsAppDialog";
import { SmsDialog } from "../../../components/MessagesDialogs/SmsDialog/SmsDialog";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { EmailComposeRightPanel } from "../../../components/MessagesDialogs/EmailCompose/EmailCompose";
import {
  fetchApplicationsListAsync,
  fetchRequestFormListAsync,
} from "../../../redux/allApplications/allApplicationsActions";
import {
  IAllApplication,
  IHrRequestFormDialog,
} from "../../../redux/allApplications/allApplications.types";
import { RoleBasedCSVExportPlugin } from "../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { RoleBasedEmailRightPanel } from "../../../security/RoleBasedComponents/RoleBasedEmailRightPanel/RoleBasedEmailRightPanel";
import { RoleBasedMessages } from "../../../security/RoleBasedComponents/RoleBaseMessages/RoleBaseMessages";
import { IAllApplicationHr } from "../AllApplication/AllApplication.types";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_STATE_REQUEST_FORM } from "./AddRequestFormDialog/AddRequestFormDialog.types";
import { RequestFormDialog } from "./AddRequestFormDialog/AddRequestFormDialog";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";

export const RequestFormsList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "request-forms",
    },
  ];
  const [openView, setOpenView] = React.useState<IAllApplication | null>(null);

  const { requestFormList, rquestFormLoading } = useSelector(
    (storeState: IStoreState) => storeState.allApplications
  );
  const [selectedApplications, setSelectedApplications] = React.useState<{
    isAllSelected: boolean;
    applicaion: IHrRequestFormDialog[];
  }>({
    isAllSelected: false,
    applicaion: [],
  });
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });

  const [status, setStatus] = React.useState<
    IHrRequestFormDialog["status"] | "-1"
  >("-1");

  const [searchType, setSearchType] = React.useState<string[]>(["name"]);
  const [search, setSearch] = React.useState<string>("");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const fetchList = async () => {
    dispatch(fetchRequestFormListAsync(status));
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const menuItems =
    selectedApplications.applicaion.length > 0 ||
    selectedApplications.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose Bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: 0,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: rquestFormLoading !== LoadState.Loaded,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
                moduleId={MODULE_IDS.HR_APPLICATION}
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[{ label: "Name", value: "name" }]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "branch_name",
        headerName: "Branch Name",
        fieldName: "branch_name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row) => {
          return (
            <Chip
              color="warning"
              sx={{ color: "#000" }}
              label={row.branch_name}
            ></Chip>
          );
        },
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "name",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IAllApplication) => {
          return (
            <CustomLink
              // to={"/applications/manage/" + row.application_number}
              to={"/on-boarding/" + row.application_number}
              label={row.first_name + " " + row.last_name}
            />
          );
        },
      },
      {
        key: "mobile",
        headerName: "Mobile",
        fieldName: "mobile",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          return (
            // <Stack direction="column" spacing={1}>
            // <MessagesDialogWrapper>
            //   <CallIcon color="primary" fontSize="small" />

            //   <WhatsAppDialog mobileNumber={row.mobile} />
            //   <SmsDialog mobileNumber={row.phone} />
            //   <Typography variant="body1">{row.mobile}</Typography>
            // </MessagesDialogWrapper>
            <RoleBasedMessages
              phone={row.phone || ""}
              moduleId={MODULE_IDS.HR_REQUEST_FORMS}
            />
          );
        },
      },
      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row) => {
          if (!row.email || row.email === "null") {
            return <Typography>--</Typography>;
          }
          return (
            <RoleBasedEmailRightPanel
              moduleId={MODULE_IDS.HR_REQUEST_FORMS}
              displayLabel={row.email}
              toEmail={row.email}
            />
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHrRequestFormDialog) => {
          return (
            <StandardTableActions
              onViewClick={() => setOpenView(null)}
              onEditClick={() => {
                setOpenViewForm(row);
              }}
            />
          );
        },
      },
    ],
    items: requestFormList,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: IHrRequestFormDialog[]) => {
      setSelectedApplications({
        isAllSelected: isAllSelected,
        applicaion: rows,
      });
    },
  };

  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [openViewForm, setOpenViewForm] =
    React.useState<IHrRequestFormDialog | null>(null);

  const handleCreate = () => {
    setOpenForm(true);
    setOpenViewForm(INITIAL_STATE_REQUEST_FORM);
  };

  return (
    <PageContainer
      title="Request Forms"
      description="this is all request form list"
    >
      <Breadcrumb title="" items={BCrumb} />
      <StandadCard>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            mx: 5,
            my: 2,
          }}
        >
          <Typography variant="h2" fontWeight={"700"}>
            Request Forms
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleCreate}
          >
            Create
          </Button>
        </Box>

        {openViewForm && (
          <RequestFormDialog
            open={true}
            onClose={() => setOpenViewForm(null)}
            data={openViewForm}
          />
        )}

        <DataTable {...opportunitesTableProps} />
      </StandadCard>
    </PageContainer>
  );
};
