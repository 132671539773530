import React from "react";
import { CustomChip } from "../CustomChip";
import { NotificationsActive } from "@mui/icons-material";
import { capitalizeWords } from "../../helpers";

interface IActiveInActiveStatusProps {
  status: string;
  size?: "small" | "medium";
}

export const StatusRenderer: React.FC<IActiveInActiveStatusProps> = (props) => {
  const status = (props.status || "").toLowerCase();
  const size = props.size || "small";
  if (status === "active") {
    return <CustomChip color="success" content={"Active"} size={size} />;
  } else if (status === "open") {
    return <CustomChip color="warning" content={"Open"} size={size} />;
  } else if (status === "closed") {
    return <CustomChip color="error" content={"Closed"} size={size} />;
  } else if (status === "re-opened") {
    return <CustomChip color="info" content={"Re-Opened"} size={size} />;
  } else if (status === "inactive") {
    return <CustomChip color="error" content={"Inactive"} size={size} />;
  } else if (status === "new submission") {
    return (
      <CustomChip color="success" content={"New Submission"} size={size} />
    );
  } else if (status === "quote received") {
    return (
      <CustomChip
        color="warning"
        content={"Quote Received"}
        sx={{ color: "#000" }}
        size={size}
      />
    );
  } else if (status === "remote work") {
    return <CustomChip color="secondary" content={"Remote Work"} size={size} />;
  } else if (status === "on-leave") {
    return <CustomChip color="info" content={"On-Leave"} size={size} />;
  } else {
    return (
      <CustomChip
        color="info"
        content={capitalizeWords(status) || "N/A"}
        size={size}
      />
    );
  }
};
