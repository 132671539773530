/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import produce from "immer";
import {
  addCommAutomobileScheduleAAsync,
  clearCommAutomobileSchedule,
  fetchCommAutomobileScheduleAAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoScheduleActions";
import { ICommScheduleCCoverage } from "../../../../../../../redux/commAutoPolicy/commAutoSchedule/commAutoSchedule.types";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../../../../components/Cards";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { renderDropdownValue } from "../../../../../../../helpers";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { RiskLocation } from "../../../BusinessPolicy/RiskLocation/RiskLocation";
import { fetchCommAutomobileVehicleListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoVehicle/commAutoVehicleActions";

export const CommAutomobileScheduleA = () => {
  const { customerId, customerPolicyId, incrementalNumber, vehicleNumber } =
    useParams() as {
      customerId?: number;
      customerPolicyId?: number;
      incrementalNumber?: number;
      vehicleNumber?: number;
    };

  const { loading, data } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.schedule.scheduleA
  );
  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.vehicle.VehicleInformation.vehiclesList
  );

  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const { values, handleSubmit, setFieldValue, setValues, handleChange } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: (values) => {
        if (customerPolicyId) {
          setSaveLoading(true);
          dispatch(
            addCommAutomobileScheduleAAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                }
                navigate(
                  `/comm-automobile-policy/${customerId}/vehicle-coverage-schedule-tab/${customerPolicyId}?tab=0`
                );
                setSaveLoading(false);
              }
            )
          );
        }
      },
    });

  const handleThirdPartyLiabilityCurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(
        values.section_one_third_party_liability,
        (draftState) => {
          draftState[index][key as "label"] = value;
        }
      );
      setFieldValue("section_one_third_party_liability", newValues);
    };

  const handleThirdPartyLiabilityDropDownChange =
    (key: string, index: number) => (e: SelectChangeEvent<any>) => {
      const newValues = produce(
        values.section_one_third_party_liability,
        (draftState) => {
          draftState.forEach((row) => {
            row[key as "label"] = e.target.value;
          });
        }
      );
      setFieldValue("section_one_third_party_liability", newValues);
    };

  const handleAccidentBenefitsCurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(
        values.section_two_accident_benefits,
        (draftState) => {
          draftState[index][key as "label"] = value;
        }
      );
      setFieldValue("section_two_accident_benefits", newValues);
    };

  const handleIncreasedAccidentBenefits =
    (key: string, index: number) => (e: any) => {
      const newValues = produce(
        values.optional_increased_accident_benefits,
        (draftState) => {
          draftState[index][key as "label"] = e.target.value;
        }
      );
      setFieldValue("optional_increased_accident_benefits", newValues);
    };
  const handleIncreasedAccidentBenefitsCurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(
        values.optional_increased_accident_benefits,
        (draftState) => {
          draftState[index][key as "label"] = value;
        }
      );
      setFieldValue("optional_increased_accident_benefits", newValues);
    };

  const handleSection3CurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(values.section_three, (draftState) => {
        draftState[index][key as "label"] = value;
      });
      setFieldValue("section_three", newValues);
    };

  const handleSection4CurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(values.section_four, (draftState) => {
        draftState[index][key as "label"] = value;
      });
      setFieldValue("section_four", newValues);
    };

  const handleSection5 = (key: string, index: number) => (e: any) => {
    const newValues = produce(
      values.section_five_loss_or_damage,
      (draftState) => {
        draftState[index][key as "label"] = e.target.value;
      }
    );
    setFieldValue("section_five_loss_or_damage", newValues);
  };
  const handleSection5CurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(
        values.section_five_loss_or_damage,
        (draftState) => {
          draftState[index][key as "label"] = value;
        }
      );
      setFieldValue("section_five_loss_or_damage", newValues);
    };

  const finalList = React.useMemo(() => {
    return vehiclesList?.map((item) => {
      return {
        label: `${item.incremental_number || "--"} (
          Year: ${item.year || "N/A"} 
          Make: ${item.make || "N/A"} 
          Model: ${item.model || "N/A"}
          VIN: ${item.vin || "N/A"} 
          )`,
        value: item.incremental_number || "",
      };
    });
  }, [vehiclesList]);

  const accidentBenefits: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,
    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "schedule",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      // {
      //   key: "deductible_limit",
      //   headerName: "Deductible/Limit",
      //   fieldName: "deductibleOrLimit",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   exportCellWidth: 15,
      //   onRowCellRender: (
      //     value: { column: { rowIndex: number } },
      //     row: ICommScheduleCCoverage
      //   ) => {
      //     return (
      //       <CurrencyTextField
      //         fullWidth
      //         value={row.deductibleOrLimit}
      //         onChange={handleAccidentBenefitsCurrencyChange(
      //           "deductibleOrLimit",
      //           value.column.rowIndex
      //         )}
      //       />
      //     );
      //   },
      // },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleAccidentBenefitsCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_two_accident_benefits,
  };

  const optionalIncreasedAccidentBenefits: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,
    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "schedule",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          const splitText = row.label?.split("(");
          const part1: string = splitText[0]?.trim();
          const part2: string = "(" + splitText[1]?.trim();
          return row.label?.length <= 76 ? (
            <Typography>{row.label}</Typography>
          ) : (
            <>
              <Typography>{part1}</Typography>
              <Typography>{part2}</Typography>
            </>
          );
        },
      },
      {
        key: "declined",
        headerName: "Accepted",
        fieldName: "declined",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <ControlledCustomSelect
              fullWidth
              value={row.declined}
              options={[
                { label: "Y", value: "Y" },
                { label: "N", value: "N" },
              ]}
              placeholder="Select one"
              onChange={handleIncreasedAccidentBenefits(
                "declined",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "deductible_limit",
        headerName: "Limit",
        fieldName: "deductibleOrLimit",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <>
              {row.deductibleOrLimit ===
              "As Stated in Section 4 of the Policy" ? (
                <CustomTextField
                  fullWidth
                  disabled
                  value={row.deductibleOrLimit}
                />
              ) : (
                <CurrencyTextField
                  fullWidth
                  value={row.deductibleOrLimit}
                  onChange={handleIncreasedAccidentBenefitsCurrencyChange(
                    "deductibleOrLimit",
                    value.column.rowIndex
                  )}
                />
              )}
            </>
          );
        },
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleIncreasedAccidentBenefitsCurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.optional_increased_accident_benefits,
  };

  const section3: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,
    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "schedule",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "deductible_limit",
        headerName: "Limit",
        fieldName: "deductibleOrLimit",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOrLimit}
              onChange={handleSection3CurrencyChange(
                "deductibleOrLimit",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleSection3CurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_three,
  };

  const section4: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,
    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "schedule",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },

      {
        key: "deductible_limit",
        headerName: "Deductible",
        fieldName: "deductibleOrLimit",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOrLimit}
              onChange={handleSection4CurrencyChange(
                "deductibleOrLimit",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleSection4CurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_four,
  };

  const lossorDamage: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: 1000,
    uniqueRowKeyName: "schedule-table",
    columns: [
      {
        key: "schedule",
        headerName: "",
        fieldName: "label",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "declined",
        headerName: "Declined",
        fieldName: "declined",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommScheduleCCoverage) => {
          return (
            <ControlledCustomSelect
              fullWidth
              value={row.declined}
              options={[
                { label: "Y", value: "Y" },
                { label: "N", value: "N" },
              ]}
              placeholder="Select one"
              onChange={handleSection5("declined", value.column.rowIndex)}
            />
          );
        },
      },
      {
        key: "deductible_limit",
        headerName: "Deductible/Limit",
        fieldName: "deductible_limit",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.deductibleOrLimit}
              onChange={handleSection5CurrencyChange(
                "deductibleOrLimit",
                value.column.rowIndex
              )}
            />
          );
        },
      },
      {
        key: "premium",
        headerName: "Premium",
        fieldName: "premium",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,

        onRowCellRender: (
          value: { column: { rowIndex: number } },
          row: ICommScheduleCCoverage
        ) => {
          return (
            <CurrencyTextField
              fullWidth
              value={row.premium}
              onChange={handleSection5CurrencyChange(
                "premium",
                value.column.rowIndex
              )}
            />
          );
        },
      },
    ],
    items: values.section_five_loss_or_damage,
  };

  const handleEndorsementsForms = (key: string, index: number) => (e: any) => {
    const newValues = produce(
      values.section_six_policy_change_forms,
      (draftState) => {
        draftState[index][key as "label"] = e.target.value;
      }
    );
    setFieldValue("section_six_policy_change_forms", newValues);
  };

  const handleEndorsementsFormsCurrencyChange =
    (key: string, index: number) => (value: string) => {
      const newValues = produce(
        values.section_six_policy_change_forms,
        (draftState) => {
          draftState[index][key as "label"] = value;
        }
      );
      setFieldValue("section_six_policy_change_forms", newValues);
    };

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && vehicleNumber) {
      dispatch(
        fetchCommAutomobileScheduleAAsync(
          customerPolicyId,
          incrementalNumber,
          vehicleNumber
        )
      );
    }
  }, [customerPolicyId, incrementalNumber, vehicleNumber]);
  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchCommAutomobileVehicleListAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCommAutomobileSchedule());
    };
  }, []);

  React.useEffect(() => {
    setValues({ ...data, incremental_number: data.incremental_number });
  }, [data]);

  React.useEffect(() => {
    const Section_One = values.section_one_third_party_liability.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replace(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Section_two = values.section_two_accident_benefits.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replace(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Section_three = values.section_three.reduce((acc, item) => {
      let inputString = item.premium;
      let outputString = inputString?.replace(",", "");
      return acc + Number(outputString);
    }, 0);

    const Section_four = values.section_four.reduce((acc, item) => {
      let inputString = item.premium;
      let outputString = inputString?.replace(",", "");
      return acc + Number(outputString);
    }, 0);

    const Section_five = values.section_five_loss_or_damage.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replace(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Section_six = values.section_six_policy_change_forms.reduce(
      (acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replace(",", "");
        return acc + Number(outputString);
      },
      0
    );

    const Optional_increased =
      values.optional_increased_accident_benefits.reduce((acc, item) => {
        let inputString = item.premium;
        let outputString = inputString?.replace(",", "");
        return acc + Number(outputString);
      }, 0);

    const endorsements = values.section_six_policy_change_forms || [];

    const optional = endorsements.reduce((acc, item) => {
      if (item && item.premium) {
        let inputString = item.premium;
        let outputString = inputString.replace(",", "");
        return acc + Number(outputString);
      }
      return acc;
    }, 0);

    let total =
      Section_One +
      Section_two +
      Section_three +
      Section_four +
      Section_five +
      Section_six +
      optional +
      Optional_increased;

    setFieldValue('total_premium', total)
  }, [
    values.section_one_third_party_liability,
    values.section_two_accident_benefits,
    values.section_three,
    values.section_four,
    values.section_five_loss_or_damage,
    values.section_six_policy_change_forms,
    values.optional_increased_accident_benefits,
  ]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <StandadCard heading="Vehicle Coverage - IRCA/Scheduled Fleet">
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Vehicle Number</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                fullWidth
                type="string"
                name="vehicle_number"
                value={values.vehicle_number}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
                placeholder="Select one"
                options={finalList}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 1. Third Party Liability" />
              {/* <DataTable {...thirdPartyLiability} /> */}

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography fontWeight={600} variant="h4">
                        Limit
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight={600} variant="h4">
                        Premium
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.section_one_third_party_liability
                    ? values.section_one_third_party_liability?.map(
                        (item, index) => {
                          return (
                            <TableRow
                              key={item.key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{item.label}</TableCell>
                              <TableCell>
                                <ControlledCustomSelect
                                  fullWidth
                                  displayEmpty
                                  value={item.deductibleOrLimit}
                                  options={[
                                    { label: "1,000,000", value: "1,000,000" },
                                    { label: "2,000,000", value: "2,000,000" },
                                    { label: "3,000,000", value: "3,000,000" },
                                    { label: "4,000,000", value: "4,000,000" },
                                    { label: "5,000,000", value: "5,000,000" },
                                    {
                                      label: "10,000,000",
                                      value: "10,000,000",
                                    },
                                  ]}
                                  placeholder="Select one"
                                  onChange={handleThirdPartyLiabilityDropDownChange(
                                    "deductibleOrLimit",
                                    index
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <CurrencyTextField
                                  fullWidth
                                  value={item.premium}
                                  onChange={handleThirdPartyLiabilityCurrencyChange(
                                    "premium",
                                    index
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : []}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 2. Accident Benefits" />
              <DataTable {...accidentBenefits} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Optional Increased Accident Benefits" />
              <DataTable {...optionalIncreasedAccidentBenefits} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 3." />
              <DataTable {...section3} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 4." />
              <DataTable {...section4} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 5. Loss or Damage" />
              <DataTable {...lossorDamage} />
            </Grid>
            <Grid item xs={12} md={12}>
              <LabelCard heading="Section 6. Policy Change Forms" />
              {/* <DataTable {...policyChangeForms} />  */}
              <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography fontWeight={600} variant="h4">
                        Declined
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight={600} variant="h4">
                        Deductible/Limit
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight={600} variant="h4">
                        Premium
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.section_six_policy_change_forms?.map(
                    (item, index) => {
                      return (
                        <TableRow
                          key={item.key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "30%",
                            }}
                          >
                            <ControlledCustomSelect
                              fullWidth
                              value={item.label}
                              options={[
                                {
                                  label: "20 Loss of Use",
                                  value: "20 Loss of Use",
                                },
                                {
                                  label: "21b Blanket Basis Fleet (Pro Rata)",
                                  value: "21b Blanket Basis Fleet (Pro Rata)",
                                },
                                {
                                  label:
                                    "27 Legal Liability for Damage to Non-Owned Automobiles",
                                  value:
                                    "27 Legal Liability for Damage to Non-Owned Automobiles",
                                },
                                {
                                  label:
                                    "27b Business Operations - Liability for Damage to Non Owned Autos",
                                  value:
                                    "27b Business Operations - Liability for Damage to Non Owned Autos",
                                },
                                {
                                  label:
                                    "21b Blanket Basis Fleet (50/50 basis)",
                                  value:
                                    "21b Blanket Basis Fleet (50/50 basis)",
                                },
                                {
                                  label: "43 Removing Depreciation Deduction",
                                  value: "43 Removing Depreciation Deduction",
                                },
                                {
                                  label: "44 Family Protection",
                                  value: "44 Family Protection",
                                },
                                {
                                  label: "39 Accident Rating Waiver",
                                  value: "39 Accident Rating Waiver",
                                },
                                {
                                  label: "5 Permission to Rent or Lease",
                                  value: "5 Permission to Rent or Lease",
                                },
                                {
                                  label: "23a Lienholder Protection",
                                  value: "23a Lienholder Protection",
                                },
                                {
                                  label: "40 Fire and Theft Deductible",
                                  value: "40 Fire and Theft Deductible",
                                },
                                {
                                  label:
                                    "43a Removing Depreciation Deduction for Specified Lessee(s)",
                                  value:
                                    "43a Removing Depreciation Deduction for Specified Lessee(s)",
                                },
                                {
                                  label: "5a Permission to Rent or Lease",
                                  value: "5a Permission to Rent or Lease",
                                },
                                {
                                  label: "44R Family Protection (Ontario)",
                                  value: "44R Family Protection (Ontario)",
                                },
                                {
                                  label:
                                    "16 Agreement for suspension of Coverage OEF 71-EXCLUDING OWNED AUTO",
                                  value:
                                    "16 Agreement for suspension of Coverage OEF 71-EXCLUDING OWNED AUTO",
                                },
                                {
                                  label: "OEF 77",
                                  value: "OEF 77",
                                },
                              ]}
                              placeholder="Select one"
                              onChange={handleEndorsementsForms("label", index)}
                            />
                          </TableCell>
                          <TableCell>
                            <ControlledCustomSelect
                              fullWidth
                              value={item.declined}
                              options={[
                                { label: "Y", value: "Y" },
                                { label: "N", value: "N" },
                              ]}
                              placeholder="Select one"
                              onChange={handleEndorsementsForms(
                                "declined",
                                index
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <CurrencyTextField
                              fullWidth
                              value={item.deductibleOrLimit}
                              onChange={handleEndorsementsFormsCurrencyChange(
                                "deductibleOrLimit",
                                index
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <CurrencyTextField
                              fullWidth
                              value={item.premium}
                              onChange={handleEndorsementsFormsCurrencyChange(
                                "premium",
                                index
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Minimum Retained Premium</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <CustomTextField
                    id="minimum_retained_premium"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.minimum_retained_premium}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography fontWeight={600} variant="h4">
                    Total Premium
                  </Typography>
                </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  {" "}
                  <CustomTextField
                    id="total_premium"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.total_premium}
                    disabled
                    // onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} lg={3}>
              <Stack direction="row" alignItems={"center"}>
                <Checkbox  checked={values.set_as_default === "Y"}
                  onChange={(e,checked)=>{
                    setFieldValue("set_as_default", checked ? "Y": "N")
                  }}
                />
                <Typography fontWeight={600} variant="h5">
                  Set as Default Coverage
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <RoleBasedCustomButton
            moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Box>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
