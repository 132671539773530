/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Grid, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../../../../components/formsComponents/CustomDatePicker";
import { IBusinesPolicyBasicDetailsProps } from "../BusinessPolicyBasicDetails.types";
import { useDispatchWrapper } from "../../../../../../../hooks";
import {
  addPriorPolicyInfoAsync,
  fetchPriorPolicyAsync,
} from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import moment from "moment";
import {
  current_status,
  payment_method,
} from "../../../../../../../constants/constants";
import { renderDropdownValue } from "../../../../../../../helpers";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const PriorPolicy: React.FC<IBusinesPolicyBasicDetailsProps> = (
  props
) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.basicDetails.priorPolicy
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          addPriorPolicyInfoAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            () => {
              setSaveLoading(false);
            }
          )
        );
      },
    });

  React.useEffect(() => {
    dispatch(fetchPriorPolicyAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Line of Business</CustomFormLabel>
            <CustomTextField
              name="line_of_business"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.line_of_business}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Expiry Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.policy_expiry_date || ""}
              onChange={(newValue) => {;
                setFieldValue("policy_expiry_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Current Insurer</CustomFormLabel>
            <CustomTextField
              name="current_insurer"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.current_insurer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Expiring Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.expiring_premium}
              onChange={(value) => {
                setFieldValue("expiring_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Current Status</CustomFormLabel>

            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="current_status"
              fullWidth
              value={values.current_status}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {(current_status || []).map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
        </Grid>

        <Divider sx={{mt: 4, mb: 2.5}} />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Non Payment Code</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="non_payment_code"
              fullWidth
              value={values.non_payment_code}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {(payment_method || []).map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Non Payment Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.non_payment_date || ""}
              onChange={(newValue) => {
                setFieldValue("non_payment_date", newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <CustomTextField
              name="insurer"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.insurer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Amount Owing</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.amount_owing}
              onChange={(value) => {
                setFieldValue("amount_owing", value);
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.BUSINESS_POLICY}
          type="submit" disabled={saveLoading} variant="contained">
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
