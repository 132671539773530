import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";

export interface ICommAutomobileBasicDetailsState {
  customerInfo: {
    data: ICommAutomobileCustomerInfo;
    loading: LoadState;
    error: string | null;
  };
  priorPolicy: {
    data: ICommAutomobilePriorPolicy;
    loading: LoadState;
    error: string | null;
  };
  policyAdmin: {
    data: ICommAutomobilePolicyAdmin;
    loading: LoadState;
    error: string | null;
  };
  policyAdminManager: {
    data: ICommAutomobilePolicyAdminManager;
    loading: LoadState;
    error: string | null;
  };
  premium: {
    data: ICommAutomobilePremium;
    loading: LoadState;
    error: string | null;
  };
  attachment: {
    list: ICommAutomobileAttachment[];
    listLoading: LoadState;
    data: ICommAutomobileAttachment;
    loading: LoadState;
    error: string | null;
  }
  customerPoliycId?: number | null;
  isNewPolicy: boolean;
}

export interface ICommAutomobileCustomerInfo {
  policy_id: number;
  customer_id: number;
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  residential_or_business: string | null,
  form_of_business: string | number | null;
  language: string | null;
  line_of_business: string | null;
  sub_type: string | null;
  effective_date: string;
  expiry_date: string;
  segment: string[];
  transaction_type: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  policy_status: string | null;
  date_cancelled: string | null;
  cancellation_reason: string | null;
  cancellation_type: string | null;
  program: string | null;
  type_of_policy: string | null;
  quote_number: string | null;
  policy_number: string | null;
  binder_number: string | null;
  effective_time: Moment;
  send_invoices_renewal_and_receivacble_letter_via_emails: boolean | null;
  send_statements_via_email: boolean | null;
  send_eDocs_via_email: boolean | null;
  status: string;
}

export interface ICommAutomobilePriorPolicy {
  customer_policy_id: number;
  line_of_business: string | null;
  policy_expiry_date: string | null;
  current_insurer: string | null;
  expiring_premium: string | null;
  current_status: string | null;
  non_payment_code: string | null;
  non_payment_date: string | null;
  insurer: string | null;
  amount_owing: string | null;
}

export interface ICommAutomobilePolicyAdmin {
  customer_policy_id: number;
  insurer: string | null;
  broker_code: string | null;
  comission_code: string | null;
  policy_fee: string | null;
  branch_code: string | null;
  producer_one: string | null;
  producer_one_code: string | null;
  producer_two: string | null;
  producer_two_code: string | null;
  producer_three: string | null;
  producer_three_code: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  billing_account_number: string | null;
}

export interface ICommAutomobilePolicyAdminManager {
  customer_policy_id: number;
  insurer: string | null;
  broker_code: string | null;
  comission_code: string | null;
  policy_fee: string | null;
  house_percentage: string | null;
  branch_code: string | null;
  producer_one: string | null;
  producer_one_code: string | null;
  producer_one_percentage: string | null;
  producer_two: string | null;
  producer_two_code: string | null;
  producer_two_percentage: string | null;
  producer_three: string | null;
  producer_three_code: string | null;
  producer_three_percentage: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  billing_account_number: string | null;
}
export interface ICommAutomobilePremium {
  customer_policy_id: number;
  policy_premium: string | null;
  agency_bill_or_direct_bill: string | null;
  payment_plan: string | null;
  premium_finance_contract_number: string | null;
  financed_by: string | null;
  default_tax_region: string | null;
  tax_1: string | null;
  tax_2: string | null;
  tax_exempt: string | null;
  reason: string | null;
}

export interface ICommAutomobileAttachment {
  customer_policy_id: number | null,
  serial_number: number | null;
  document_name: string | null;
  file_upload: string | null;
  description: string | null;
  status: string;
  send_attachment: number;
}
export const initialCommAutomobileDetails: ICommAutomobileBasicDetailsState = {
  customerInfo: {
    data: {
      customer_name: "",
      policy_id: 0,
      customer_id: 0,
      customer_policy_id: null,
      customer_code: null,
      residential_or_business: null,
      form_of_business: null,
      language: null,
      line_of_business: null,
      sub_type: null,
      effective_date: moment().format("YYYY-MM-DD"),
      expiry_date: moment().add(1, "year").format("YYYY-MM-DD"),
      segment: [],
      transaction_type: null,
      address: "",
      unit_or_suite: null,
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      policy_status: "Active",
      date_cancelled: null,
      cancellation_reason: null,
      cancellation_type: null,
      program: null,
      type_of_policy: null,
      quote_number: null,
      policy_number: null,
      binder_number: null,
      effective_time: moment().startOf("day").add(1, "minutes"),
      send_invoices_renewal_and_receivacble_letter_via_emails: null,
      send_statements_via_email: null,
      send_eDocs_via_email: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  priorPolicy: {
    data: {
      customer_policy_id: 0,
      line_of_business: null,
      policy_expiry_date: null,
      current_insurer: null,
      expiring_premium: null,
      current_status: null,
      non_payment_code: null,
      non_payment_date: null,
      insurer: null,
      amount_owing: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  policyAdmin: {
    data: {
      customer_policy_id: 0,
      insurer: null,
      broker_code: null,
      comission_code: null,
      policy_fee: null,
      branch_code: null,
      producer_one: null,
      producer_one_code: null,
      producer_two: null,
      producer_two_code: null,
      producer_three: null,
      producer_three_code: null,
      marketer: null,
      marketer_code: null,
      csr: null,
      csr_code: null,
      billing_account_number: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  policyAdminManager: {
    data: {
      customer_policy_id: 0,
      insurer: null,
      broker_code: null,
      comission_code: null,
      policy_fee: null,
      house_percentage: null,
      branch_code: null,
      producer_one: null,
      producer_one_code: null,
      producer_one_percentage: null,
      producer_two: null,
      producer_two_code: null,
      producer_two_percentage: null,
      producer_three: null,
      producer_three_code: null,
      producer_three_percentage: null,
      marketer: null,
      marketer_code: null,
      csr: null,
      csr_code: null,
      billing_account_number: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  premium: {
    data: {
      customer_policy_id: 0,
      policy_premium: null,
      agency_bill_or_direct_bill: null,
      payment_plan: null,
      premium_finance_contract_number: null,
      financed_by: null,
      default_tax_region: "ON",
      tax_1: null,
      tax_2: null,
      tax_exempt: null,
      reason: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  attachment: {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      customer_policy_id: null,
      serial_number: null,
      document_name: null,
      file_upload: null,
      description: null,
      status: "ACTIVE",
      send_attachment: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  customerPoliycId: null,
  isNewPolicy: true,
};
