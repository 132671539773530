/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { Autocomplete, CircularProgress } from "@mui/material";
import { ICustomerAutoSearchProps } from "./AutoCompleteSearches.types";
import { debounce } from "lodash";
import { ICustomerFullDetails } from "../../redux/customer/customer.types";
import axios from "axios";

export const CustomerAutoSearch: React.FC<ICustomerAutoSearchProps> = (
  props
) => {
  const { value, onSelect, label, disabled } = props;
  const [options, setOptions] = React.useState<readonly ICustomerFullDetails[]>(
    []
  );
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const initialLoad = React.useRef(false);
  const cancelTokenSource = React.useRef<any>(null);

  const fetchSuggestion = async (value: string, isById: boolean,cancelToken: any) => {
    setLoading(true);
    try {
      let url = `/customer/get-customer-all-detailed-record?status=ACTIVE`;
      if (isById) {
        url = url + `&customer_id=${value}`;
      } else {
        url =
          url +
          `&column=customer_code&column=first_name&column=last_name&column=customer_id&value=${value}`;
      }
      const res = await api.get(url, {
        cancelToken: cancelToken
      });
      const data: ICustomerFullDetails[] = res.data.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: string | ICustomerFullDetails) => {
    if (typeof option === "string") {
      return option;
    }
    return `${option.customer_name || ""} (${option.customer_code})`;
  };
  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (search && search !== value) {
      debounceFn(search, false,cancelTokenSource.token);
    }
    // Cleanup function to cancel the request when the component unmounts
    return () => {
      cancelTokenSource.cancel("Operation canceled due to component unmount.");
    };
  }, [search]);

  React.useEffect(() => {
    if (value && !initialLoad.current) {
      debounceFn(value, true,null);
    }
  }, [value]);

  return (
    <>
      <CustomFormLabel>{label}</CustomFormLabel>
      <Autocomplete
        id="google-map-demo"
        fullWidth
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
            width: "100%",
          },
        }}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) =>
          typeof option === "string"
            ? option === value
            : //@ts-ignore
              option.customer_id === value.customer_id
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={options.find((option) => option.customer_id === value) || null} // Set the selected value based on the selectedId
        loading={loading}
        disabled={disabled}
        noOptionsText="No Customers"
        //@ts-ignore
        onChange={(
          event: React.ChangeEvent<HTMLElement>,
          newValue: ICustomerFullDetails | null
        ) => {
          setOptions(newValue ? [newValue, ...options] : options);
          if (newValue) {
            onSelect(newValue);
          } else {
            onSelect({
              activity: "",
              customer_id: "",
              anti_spam_consent: "",
              assigned_to: "",
              branch: "",
              customer_code: "",
              customer_name: "",
              email: "",
              facebook: "",
              first_name: "",
              form_of_business: "",
              goes_by: "",
              insert_ts: "",
              language: "",
              last_name: "",
              linkedin: "",
              mailing_address: "",
              marketer: "",
              mobile: "",
              phone: "",
              phone_ext: "",
              position: "",
              privacy_act_consent: "",
              privacy_consent: "",
              producer: "",
              salutation: "",
              tax_zone: "",
              telematics_consent_code: "",
              website: "",
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
            initialLoad.current = true;
          }
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
