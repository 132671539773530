import React from "react";
import { IStandardCellProps } from "./interfaces/IStandards";
import { Box, Theme, Typography } from "@mui/material";
import { CustomTypography } from "../formsComponents";

export const StandardCardCell: React.FC<IStandardCellProps> = (props) => {
  const { heading, data, isHide } = props;
  if (isHide) {
    return <></>;
  }

  return (
    <Box>
      <Typography
        variant="h4"
      >
        {heading}
      </Typography>
      <Typography
        variant="body2"
        sx={(theme: Theme) => ({
          mb: 1,
          fontSize: "1.1rem",
          wordBreak: "break-word",
          color:
            theme.palette.mode === "dark"
              ? data.color || "#fff"
              : data.color || theme.palette.grey[700],
        })}
      >
        {data.value && data.value !== "null" && data.value !== "undefined"
          ? data.value
          : "--"}
      </Typography>
    </Box>
  );
};
