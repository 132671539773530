/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";

import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import {
  LoadState,
  MODULE_IDS,
  USER_ROLES,
} from "../../../../../../../constants/enums";
import { InsurerCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches";
import { ComissionCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeAutoSearch";
import { BranchCodeAutoSearch } from "../../../../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { UsersAutoSearchByRole } from "../../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { ICommAutomobilePolicyBasicDetailsProps } from "../CommAutoBasicDetailsprops.types";
import {
  addCommAutomobilePolicyAdminsync,
  fetchCommAutomobilePolicyAdminAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { ComissionCodeSelect } from "../../../../../../../components/AutoCompleteSearches/ComissionCodeSelect";
import { ProducerSearchAndSelect } from "../../../../../../../components/TextAndSearch/ProducerSearchAndSelect";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const CommAutomobilePolicyAdmin: React.FC<
  ICommAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.policyAdmin
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { customerProspect } = useSelector(
    (storeState: IStoreState) => storeState.customer
  );
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addCommAutomobilePolicyAdminsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePolicyAdminAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues({
      ...data,
      branch_code: customerProspect.data.branch_code,
      producer_one: data.producer_one || customerProspect.data.producer,
    });
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Insurer</CustomFormLabel>
            <InsurerCodeAutoSearch
              value={values.insurer}
              onSelect={(value) => {
                setFieldValue("insurer", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Comission Code</CustomFormLabel>
            <ComissionCodeSelect
              insurerCode={values.insurer}
              value={values.comission_code}
              onChange={(value) => {
                setFieldValue("comission_code", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Code</CustomFormLabel>
            <CustomTextField fullWidth value={values.branch_code} disabled />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Policy Fee</CustomFormLabel>
            <CustomTextField
              name="policy_fee"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.policy_fee}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 1"
              value={values.producer_one}
              onSelect={(data) => {
                setFieldValue("producer_one", data.user_fact_id);
                setFieldValue("producer_one_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 2"
              value={values.producer_two}
              onSelect={(data) => {
                setFieldValue("producer_two", data.user_fact_id);
                setFieldValue("producer_two_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              label="Producer 3"
              value={values.producer_three}
              onSelect={(data) => {
                setFieldValue("producer_three", data.user_fact_id);
                setFieldValue("producer_three_code", data.user_code);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.marketer}
              label="Marketer"
              onSelect={(data) => {
                setFieldValue("marketer", data.user_fact_id);
                setFieldValue("marketer_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <UsersAutoSearchByRole
              value={values.csr}
              label="CSR"
              onSelect={(data) => {
                setFieldValue("csr", data.user_fact_id);
                setFieldValue("csr_code", data.user_code);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Billing Account Number</CustomFormLabel>
            <CustomTextField
              name="billing_account_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.billing_account_number}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Broker Code</CustomFormLabel>
            {/* <BranchCodeAutoSearch
                value={values.broker_code}
                onSelect={(value) => {
                  setFieldValue("broker_code", value.value);
                }}
              /> */}
            <CustomTextField
              name="broker_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.broker_code}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.AUTOMOBILE}
            type="submit"
            disabled={saveLoading}
            variant="contained"
          >
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
