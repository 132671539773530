/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { renderDropdownValue } from "../../../../../../../helpers";
import { ICommAutomobilePolicyBasicDetailsProps } from "../CommAutoBasicDetailsprops.types";
import {
  addCommAutomobilePremiumAsync,
  fetchCommAutomobilePolicyAdminAsync,
  fetchCommAutomobilePremiumAsync,
} from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { api } from "../../../../../../../api/api";
import { IInsurerCode } from "../../../../../../../redux/insurer/insurer.types";

export const CommAutomobilePremium: React.FC<
  ICommAutomobilePolicyBasicDetailsProps
> = (props) => {
  const { customerpolicyId } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails.premium
  );

  const policyAdmin = useSelector(
    (storeState: IStoreState) =>
      storeState.commAuto.basicDetails.policyAdmin.data
  );

  const [defaultBilling, setDefaultBilling] = React.useState<string | null>("");

  const fetchInsurers = async () => {
    try {
      const res = await api.get(
        `/insurance/get-insurer-code?column=insurer_code&column=name&value=${policyAdmin.insurer}`
      );
      const data: IInsurerCode[] = res.data.data;
      if (data) {
        setDefaultBilling(data[0].default_billing);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePolicyAdminAsync(customerpolicyId));
    setFieldValue("agency_bill_or_direct_bill", defaultBilling);
  }, [defaultBilling]);

  React.useEffect(() => {
    fetchInsurers();
  }, [policyAdmin.insurer]);

  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { values, handleChange, handleSubmit, setValues, setFieldValue, errors } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        if (values.tax_1 && Number(values.tax_1) > 100) {
          errors.tax_1 = "Tax 1 % cannot be greater than 100";
        }
        if (values.tax_2 && Number(values.tax_2) > 100) {
          errors.tax_2 = "Tax 2 % cannot be greater than 100";
        }
        return errors;
      },
      onSubmit: async (values) => {
        setSaveLoading(true);
        dispatch(
          addCommAutomobilePremiumAsync(
            {
              ...values,
              customer_policy_id: customerpolicyId,
            },
            (isSuccess) => {
              setSaveLoading(false);
            }
          )
        );
      },
    });

  React.useEffect(() => {
    dispatch(fetchCommAutomobilePremiumAsync(customerpolicyId));
  }, []);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <Typography
        variant="h2"
        fontWeight={600}
        sx={{
          mt: 4,
        }}
      >
        Premium
      </Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.policy_premium}
              onChange={(value) => {
                setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="agency_bill_or_direct_bill"
              fullWidth
              value={values.agency_bill_or_direct_bill}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["A", "D"].map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.payment_plan}
              name="payment_plan"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Monthly", "Annual", "Quarterly", "Other"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Fincance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.premium_finance_contract_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.financed_by}
              name="financed_by"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Default Tax Region</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.default_tax_region}
              name="default_tax_region"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1 %</CustomFormLabel>
            <CustomTextField
              name="tax_1"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_1}
              error={errors.tax_1 ? true : false}
              helperText={errors.tax_1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2 %</CustomFormLabel>
            <CustomTextField
              name="tax_2"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_2}
              error={errors.tax_2 ? true : false}
              helperText={errors.tax_2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.tax_exempt}
              name="tax_exempt"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.reason}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton
          moduleId={MODULE_IDS.COMMERCIAL_AUTO}
            type="submit"
            disabled={saveLoading}
            variant="contained"
          >
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
