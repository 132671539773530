import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import {
  IAutomobileAdditionalInformation,
  IAutomobileInterestedParty,
  IAutomobileVehicleInformation,
} from "./automobileVehicle.types";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED";

export const fetchAutomobileVehicleProgress = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_PROGRESS);

export const fetchAutomobileVehicleSuccess = (
  data: IAutomobileVehicleInformation
) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_SUCCESS, {
    data,
  });
export const fetchAutomobileVehicleFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_FAILED, { errorMessage });

export const addAutomobileVehicleAsync =
  (
    data: IAutomobileVehicleInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      const res = await api.post("/policy/edit-policy-auto-vehicle-information", {
        ...data,
      });
      dispatch(fetchAutomobileVehicleSuccess(res.data.data[0]));
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Vehicle information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileVehicleAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileVehicleProgress());
      const res = await api.get(
        `/policy/get-policy-auto-vehicle-information?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileVehicleInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileVehicleSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileVehicleFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileVehicleFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_VEHICLE_INFO = "CLEAR_AUTOMOBILE_VEHICLE_INFO";
export const clearAutoMobileVehicleInfo = () =>
  action(CLEAR_AUTOMOBILE_VEHICLE_INFO);

export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED";

export const fetchAutomobileVehcileListProgress = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_PROGRESS);

export const fetchAutomobileVehiclesListSuccess = (
  data: IAutomobileVehicleInformation[]
) =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileVehcileListFailed = () =>
  action(FETCH_AUTOMOBILE_VEHICLE_INFORMATION_LIST_FAILED);

export const fetchAutomobileVehicleListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileVehcileListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-vehicle-information?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileVehicleInformation[] = res.data.data;
      dispatch(fetchAutomobileVehiclesListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileVehcileListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_ADDITIONAL_INFO =
  "CLEAR_AUTOMOBILE_ADDITIONAL_INFO";

export const clearAutomobileAdditionalInfo = () =>
  action(CLEAR_AUTOMOBILE_ADDITIONAL_INFO);

export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED";

export const fetchAutomobilAdditionalInfoProgress = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_PROGRESS);
export const addAutomobilAdditionalInfo = (
  data: IAutomobileAdditionalInformation
) =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_SUCCESS, {
    data,
  });
export const fetchAutomobilAdditionalInfoFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_FAILED, { errorMessage });

export const addAutomobilAdditionalInfoAsync =
  (
    data: IAutomobileAdditionalInformation,
    vehcileNumber: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-additional-information", {
        ...data,
        vehicle_id:vehcileNumber,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Additional information saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobilAdditionalInfoVehicleAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilAdditionalInfoProgress());
      const res = await api.get(
        `/policy/get-policy-auto-additional-information?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileAdditionalInformation[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          addAutomobilAdditionalInfo({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobilAdditionalInfoFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobilAdditionalInfoFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED";

export const fetchAutomobileAdditionalInfoListProgress = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_PROGRESS);
export const fetchAutomobileAdditionalListSuccess = (
  data: IAutomobileAdditionalInformation[]
) =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileAdditionalInfoListFailed = () =>
  action(FETCH_AUTOMOBILE_ADDITIONAL_INFORMATION_LIST_FAILED);

export const fetchAutomobileAdditioalListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileAdditionalInfoListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-additional-information?value=${customerPolicyId}`
      );
      const data: IAutomobileAdditionalInformation[] = res.data.data;
      dispatch(fetchAutomobileAdditionalListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileAdditionalInfoListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_INTERESTED_PARTY_PROGRESS =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_PROGRESS";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_SUCCESS =
  "ADD_AUTOMOBILE_INTERESTED_PARTY";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_FAILED =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_FAILED";

export const fetchAutomobilIntrestedPartiesInfoProgress = () =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_PROGRESS);
export const fetchAutomobilIntrestedPartiesInfoSuccess = (
  data: IAutomobileInterestedParty
) =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_SUCCESS, {
    data,
  });
export const fetchAutomobilIntrestedPartiesInfoFailed = (
  errorMessage: string
) => action(FETCH_AUTOMOBILE_INTERESTED_PARTY_FAILED, { errorMessage });

export const addAutomobilIntrestedPartiesAsync =
  (
    data: IAutomobileInterestedParty,
    vehicleNumber: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-auto-interested-party", {
        ...data,
        vehicle_id: vehicleNumber,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Intrested party saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileIntrestedPartieAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobilIntrestedPartiesInfoProgress());
      const res = await api.get(
        `/policy/get-policy-auto-interested-party?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileInterestedParty[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobilIntrestedPartiesInfoSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobilIntrestedPartiesInfoFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobilIntrestedPartiesInfoFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEARAUTOMOBILE_INTERESTED_PARTY =
  "CLEARAUTOMOBILE_INTERESTED_PARTY";

export const clearAutomobileIntrestedParty = () =>
  action(CLEARAUTOMOBILE_INTERESTED_PARTY);

export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED =
  "FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED";

export const fetchAutomobileIntrestedPartieListProgress = () =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_PROGRESS);

export const fetchAutomobileIntrestedPartieListSuccess = (
  data: IAutomobileInterestedParty[]
) =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileIntrestedPartieListFailed = () =>
  action(FETCH_AUTOMOBILE_INTERESTED_PARTY_LIST_FAILED);

export const fetchAutomobileIntrestedPartieListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileIntrestedPartieListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-interested-party?vehicle_id=${customerPolicyId}`
      );
      const data: IAutomobileInterestedParty[] = res.data.data;
      dispatch(fetchAutomobileIntrestedPartieListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileIntrestedPartieListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_VEHICLE_STATE = "CLEAR_AUTOMOBILE_VEHICLE_STATE";

export const clearAutomobileVehicleState = () =>
  action(CLEAR_AUTOMOBILE_VEHICLE_STATE);
