import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { IBusinessPolicyFineArt } from "../../../../../../redux/businessPolicy/businessPolicyFineArt/businessPolicyFineArt.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchBusinessPolicyFineArtListAsync } from "../../../../../../redux/businessPolicy/businessPolicyFineArt/businessPolicyFineArtActions";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { CustomLink } from "../../../../../../components/CustomLink/CustomLink";

export const FineArtList = () => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.fineArt
  );

  const dispatch = useDispatchWrapper();

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchBusinessPolicyFineArtListAsync(customerPolicyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  const fineArtTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/fine-art`
                  );
                }}
              >
                Add Fine Art
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "fine_art_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyFineArt) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/fine-art/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },

      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 20,
      },

      {
        key: "serial_number",
        headerName: "Serial Number",
        fieldName: "serial_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellRender: (value, row: IBusinessPolicyFineArt) => {
          return (
            <CustomLink
              to={`/business-policy/${customerId}/schedule-tab/${customerPolicyId}/fine-art/${row.incremental_number}`}
              label={row.serial_number}
            />
          );
        },
      },
      {
        key: "limit_amount",
        headerName: "Limit Amount",
        fieldName: "limit_amount",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "purchase_date",
        headerName: "Purchase Date",
        fieldName: "purchase_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },
      {
        key: "fine_art_submission_checkbox",
        headerName: "Submission",
        fieldName: "fine_art_submission_checkbox",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyFineArt) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/schedule-tab/${customerPolicyId}/fine-art/${row.incremental_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...fineArtTableProps} />
      </Box>
    </>
  );
};
