import { LoadState } from "../../../constants/enums";

export interface IAccountingPaymentAccountsRecievableState {
  list: IPaymentAccountsRecievable[];
  listLoading: LoadState;
  data: IPaymentAccountsRecievable;
  loading: LoadState;
  error: string | null;
}

export interface IPaymentAccountsRecievable {
  invoice_id: number | null;
  payment_id: number | null;
  entry_type: string | null;
  gl_accounts: string | null;
  date: string | null;
  batch_id: string | null;
  receipt_id: string | null;
  customer_or_code: string | null;
  policy_number: string | null;
  branch: string | null;
  insurer: string | null;
  broker_code: string | null;
  default_tax_region: string | null;
  financed: string | null;
  financed_by: string | null;
  financed_fee: string | null;
  transaction: string | null;
  payment_type: string | null;
  payment_currency: string | null;
  payment_amount: string | null;
  service_fee: string | null;
  type: string | null;
  bank: string | null;
  reference_info: string | null;
  tax_1_percentage: string | null;
  tax_1_amount: string | null;
  tax_2_percentage: string | null;
  tax_2_amount: string | null;
  commission_percentage: string | null;
  commission_amount: string | null;
  status: string;
}

export const initialAccountingPaymentAccountsRecievableState: IAccountingPaymentAccountsRecievableState =
  {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      invoice_id: null,
      payment_id: null,
      entry_type: null,
      gl_accounts: null,
      date: null,
      batch_id: null,
      receipt_id: null,
      customer_or_code: null,
      policy_number: null,
      branch: null,
      insurer: null,
      broker_code: null,
      default_tax_region: "ON",
      financed: null,
      financed_by: null,
      financed_fee: null,
      transaction: null,
      payment_type: null,
      payment_currency: null,
      payment_amount: null,
      service_fee: null,
      type: null,
      bank: null,
      reference_info: null,
      tax_1_percentage: null,
      tax_1_amount: null,
      tax_2_percentage: null,
      tax_2_amount: null,
      commission_percentage: null,
      commission_amount: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
