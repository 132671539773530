import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatDate, formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { fetchTaskPolicyCancellationAysnc } from "../../../../redux/taskUrgentTransactions/taskUrgentTransactionsActions";
import { ITaskPolicyCancellation } from "../../../../redux/taskUrgentTransactions/taskUrgentTransactions.types";

export const ViewCancellation: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.urgentTransactions.policyCancellation
  );

  const dispatch = useDispatchWrapper();
  const [cancellationDetails, setCancellationDetails] =
    React.useState<ITaskPolicyCancellation>();

  React.useEffect(() => {
    dispatch(fetchTaskPolicyCancellationAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setCancellationDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {cancellationDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: cancellationDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: cancellationDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: cancellationDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: {
                    type: "text",
                    value: cancellationDetails.line_of_business,
                  },
                },

                {
                  heading: "Policy Type",
                  data: {
                    type: "text",
                    value: cancellationDetails.policy_type,
                  },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: cancellationDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: cancellationDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: cancellationDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: cancellationDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: cancellationDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Cancellation Request"
              colSize={3}
              list={[
                {
                  heading: "Date of Cancellation",
                  data: {
                    type: "text",
                    value: formatDate(
                      cancellationDetails.date_of_cancellation || ""
                    ),
                  },
                },
                {
                  heading: "Time of Cancellation",
                  data: {
                    type: "text",
                    value: formatTime(
                      cancellationDetails.time_of_cancellation || ""
                    ),
                  },
                },
                {
                  heading: "Reason for Cancellation",
                  data: {
                    type: "text",
                    value: `${
                      cancellationDetails.requested_by_insured
                        ? "Requested By Insurer"
                        : "--"
                    }`,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: formatDate(cancellationDetails.effective_date || ""),
                  },
                },
                {
                  heading: "Expiration Date",
                  data: {
                    type: "text",
                    value: formatDate(
                      cancellationDetails.expiration_date || ""
                    ),
                  },
                },
                {
                  heading: "Insurance Company",
                  data: {
                    type: "text",
                    value: cancellationDetails.insurance_company,
                  },
                },
                {
                  heading: "Methods of Cancellation",
                  data: {
                    type: "text",
                    value:
                      cancellationDetails.flat ||
                      cancellationDetails.short_rate ||
                      cancellationDetails.prorata,
                  },
                },
                {
                  heading: "Premium Paid to Broker",
                  data: {
                    type: "text",
                    value: cancellationDetails.premium_paid_to_broker,
                  },
                },
                {
                  heading: "Remarks",
                  data: {
                    type: "text",
                    value: cancellationDetails.remarks,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
