import Avatar from "@mui/material/Avatar";
import React from "react";
import { StandadCard } from "../../../../../../components/Cards";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { DataTable } from "../../../../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../../../../components/Table/plugins";
import { IBreadcrumbProps } from "../../../../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatchWrapper, useRecordCounts } from "../../../../../../hooks";
import { useDateFilter } from "../../../../../../components/Table/hooks/useDateFilter";
import moment from "moment";
import { CustomDatePicker } from "../../../../../../components/formsComponents/CustomDatePicker";
import { DateFilterDropdown } from "../../../../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { Button, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { capitalizeWords, renderDropdownValue } from "../../../../../../helpers";
import { IconicMultiColumnCard } from "../../../../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { fetchTasksListAsync } from "../../../../../../redux/tasks/tasksActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { ITask } from "../../../../../../redux/tasks/task.types";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { LeadOppStatus } from "../../../../../../components/LeadOppStatus/LeadOppStatus";
import {
  clearRecordCounts,
  fetchRecordCountsAsync,
} from "../../../../../../redux/common/commonActions";
import { task_status } from "../../../../../../constants/constants";
import { RoleBasedCSVExportPlugin } from "../../../../../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { BeforeCreateTypes } from "../../../../../tasks/BeforeCreate/BeforeCreate.types";
import { TaskAssignedTo } from "../../../../../tasks/BeforeCreate/TaskAssignedTo";
import { ViewTaskDetails } from "../../../../../tasks/BeforeCreate/ViewTaskDetails/ViewTaskDetails";
import { clearBusinessPolicyRestrutant } from "../../../../../../redux/businessPolicy/businessPolicyRestrutant/businessPolicyRestrutantActions";
import { useBreadcrumbContext } from "../../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";

export const BusinessTaskActivity: React.FC = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerPolicyId = data1[1];
  const { addBreadcrumb } = useBreadcrumbContext();
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/policies/",
      title: "all-policies ",
    },
    {
      to: "/policies/Business%20Policy",
      title: "business",
    },
    {
      to: "/customer-overview/" + customerId,
      title: "customer",
    },
    {
      title: "all-tasks",
    },
  ];

  const { list, loading, totalRecords } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeCreateTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeCreateTypes["status"] | "-1"
  >("-1");

  const [openView, setOpenView] = React.useState<ITask | null>(null);

  const [searchType, setSearchType] = React.useState<string[]>(["task_name"]);
  const [search, setSearch] = React.useState<string>("");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });

  const dispatch = useDispatchWrapper();

  const navigate = useNavigate();
  const fetchList = async () => {
    dispatch(
      fetchTasksListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType,
        customerPolicyId
      )
    );
  };
  React.useEffect(() => {
    addBreadcrumb({ title: "", items: BCrumb });
    return () => {
      dispatch(clearBusinessPolicyRestrutant());
    };
  }, []);

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, status, search, searchType]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const opportunitesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value={-1}>{"All Status"}</MenuItem>;
                {task_status.map((task) => {
                  return <MenuItem value={task}>{task}</MenuItem>
                })}
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                moduleId={MODULE_IDS.BUSINESS_POLICY}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: ["opportunity_id", "customer_id", "lead_id", "status"],
          //   items: list,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Task Name", value: "task_name" },
                    { label: "Task Code", value: "task_code" },
                    { label: "Task Type", value: "task_type" },
                    { label: "Task Sub-Type", value: "task_sub_type" },
                    { label: "Task Priority", value: "task_priority" },
                    { label: "Assigned To", value: "assigned_to_id" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "task_status",
        headerName: "Status",
        fieldName: "task_status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ITask) => {
          return <LeadOppStatus status={row.status} />;
        },
      },

      {
        key: "task_type",
        headerName: "Transaction",
        fieldName: "task_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      {
        key: "task_sub_type",
        headerName: "Sub-Type",
        fieldName: "task_sub_type",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },
      // {
      //   key: "customer_name",
      //   headerName: "Customer Name",
      //   fieldName: "customer_name",
      //   exportCellWidth: 25,
      //   renderType: RenderType.TEXT_DARK,
      // },
      // {
      //   key: "policy_number",
      //   headerName: "Policy Number",
      //   fieldName: "policy_number",
      //   exportCellWidth: 25,
      //   renderType: RenderType.TEXT_DARK,
      // },
      {
        key: "task_code",
        headerName: "Task Code",
        fieldName: "task_code",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_SUCCESS,
        // onRowCellRender: (value, row: ITask) => {
        //   return <LeadOppStatus status={row.task_status} />;
        // },
      },
      
      {
        key: "task_created_date",
        headerName: "Date Created",
        fieldName: "task_created_date",
        renderType: RenderType.DATE,
      },
      {
        key: "task_due_date",
        headerName: "Due Date",
        fieldName: "task_due_date",
        renderType: RenderType.DATE,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: RenderType.DATE,
      },
      // {
      //   key: "assigned_to",
      //   headerName: "Assigned To",
      //   fieldName: "",
      //   renderType: RenderType.CUSTOM_RENDER,
      //   onRowCellRender: (value, row) => {
      //     return (
      //       <TaskAssignedTo
      //         task={row}
      //         onComplete={fetchList}
      //       />
      //     );
      //   },
      // },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
            onViewClick={() => {
              navigate(`/task-view/${row.task_code}`);
            }}
              onEditClick={() => {
                navigate(`/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks/`+row.task_code);
              }}
            />
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeCreateTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const handleClick = () => {
    navigate(`/business-policy/${customerId}/task-activity-tab/${customerPolicyId}/tasks`);
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  React.useEffect(() => {
    dispatch(
      fetchRecordCountsAsync(
        "latest_task",
        "status",
        date.fromDate,
        date.toDate
      )
    );
    return () => {
      dispatch(clearRecordCounts());
    };
  }, [date.fromDate, date.toDate]);

  return (
    <PageContainer
      title="business task/activity"
      description="this is business task/activity"
    >
      {/* <IconicMultiColumnCard
      loading={recordCountsLoading !== LoadState.Loaded}
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.name),
            value: item.cnt,
            colSize: 2,
          };
        })}
      /> */}

      <StandadCard
        heading="All Task/Activity"
        rightHeading={
          <Button variant="contained" onClick={handleClick}>
            Create Task
          </Button>
        }
      >
        <DataTable {...opportunitesTableProps} />
      </StandadCard>
      {/* {openView && (
        <ViewTaskDetails
          open={true}
          taskDetails={openView}
          onClose={() => setOpenView(null)}
        />
      )} */}
    </PageContainer>
  );
};
