import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { IBusinessPolicyCoverage } from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businessPolicyCoverage.types";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchBusinessPolicyCoverageListAsync } from "../../../../../../redux/businessPolicy/businessPolicyCoverage/businesPolicyCoverageActions";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";

export const CoverageList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.coverage
  );
  const dispatch = useDispatchWrapper();

  const coverageTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage`
                  );
                }}
              >
                Add Coverage
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "coverage_number",
        headerName: "#",
        fieldName: "coverage_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.coverage_number}`
                );
              }}
            >
              {row.coverage_number}
            </Link>
          );
        },
      },
      {
        key: "risk_location",
        headerName: "Risk Location",
        fieldName: "risk_location",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 20,
        onRowCellRender: (value, row: IBusinessPolicyRiskLocation) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.location_number}`
                );
              }}
            >
              {row.location_number} {row.address} {row.city}{" "}
              {row.province_or_state} {row.country} {row.unit_or_suite}{" "}
              {row.postal_code}
            </Link>
          );
        },
      },

      {
        key: "created_date",
        headerName: "Created Date",
        fieldName: "insert_ts",
        exportCellWidth: 25,
        renderType: RenderType.DATE,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBusinessPolicyCoverage) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/business-policy/${customerId}/coverage-tab/${customerPolicyId}/coverage/${row.coverage_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    dispatch(fetchBusinessPolicyCoverageListAsync(customerPolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...coverageTableProps} />
      </Box>
    </>
  );
};
