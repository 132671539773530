import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface ILifeInsuranceCoverageNeedState {
  data: ILifeInsuranceCoverageNeed;
  loading: LoadState;
  error: string | null;
}

export interface ILifeInsuranceCoverageNeed {
  customer_policy_id: number | null;
  identify_current_debts_and_financial_needs: {
    key: string;
    SrNo: string;
    debt: string;
    amount: string | null;
  }[];
  determine_your_family_s_future_financial_needs: {
    key: string;
    SrNo: string;
    debt: string;
    amount: string | null;
  }[];
  deduct_your_assets_and_financial_resources: {
    key: string;
    SrNo: string;
    debt: string;
    amount: string | null;
  }[];
  status: string | null;
}

export const defaultLifeInsuranceCoverageNeed: ILifeInsuranceCoverageNeedState =
  {
    data: {
      customer_policy_id: null,
      identify_current_debts_and_financial_needs: [
        {
          key: getUniqueId(),
          SrNo: "1",
          debt: "Mortgage(s)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "2",
          debt: "Loans (e.g. auto, credit cards, personal, etc.)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "3",
          debt: "Final Expenses (e.g. burial, probate, executor fees, etc.)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "3.1",
          debt: "",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "3.2",
          debt: "",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "3.3",
          debt: "",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "3.4",
          debt: "",
          amount: null,
        },
      ],
      determine_your_family_s_future_financial_needs: [
        {
          key: getUniqueId(),
          SrNo: "4",
          debt: "Current Family Annual Income (Husband & Wife)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "5",
          debt: "How much Annual income your spouse needs after your death to meet family expenses?",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "6",
          debt: "Ongoing Annual Income of your survivor spouse from all sources",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "7",
          debt: "Additional Income needed by survivor or Annual Income Shortfall.",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "8",
          debt: "The number of years of income protection needed after your death?",
          amount: null,
        },
      ],
      deduct_your_assets_and_financial_resources: [
        {
          key: getUniqueId(),
          SrNo: "9",
          debt: "Cash and Savings",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "10",
          debt: "Non-registered stocks, bonds, mutual funds, etc.",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "11",
          debt: "Registered investments (enter estimated value if desired to sell assets and death)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "12",
          debt: "Tax Free Saving Account (TFSA) (enter estimated value if desired to sell assets and death)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "13",
          debt: "Real Estate (enter estimated value if desired to sell assets and death)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "14",
          debt: "Business or farm assets (enter estimated value if desired to sell assets and death)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "15",
          debt: "Insured Nortgage(s) and other loan balance",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "16",
          debt: "Existing life insurance benefit (personal, group and other) (list details on reverse)",
          amount: null,
        },
        {
          key: getUniqueId(),
          SrNo: "17",
          debt: "Other death benefits (from pension plan, CPP/QPP)",
          amount: null,
        },
      ],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  };
