import { LoadState } from "../../constants/enums";
import { IBranchOfficeState } from "./branchOffice.types";

export const initialBranchOfficeState: IBranchOfficeState = {
    branchOffice: {
        data: {
            branch_office_primary_id: null,
            branch_office_id: null,
            name: "",
            branch_code: "",
            non_inheritable: null,
            logo: null,
            description: null,
            address: "",
            city: "",
            province_or_state: "",
            country: "",
            postal_code: "",
            branch_manager: "",
            department: null,
            email: null,
            telephone: null,
            ext: null,
            toll_Free: null,
            fax: null,
            website: null,
            unit_or_suite: "",
            cl_email: null,
            accounts_email: null,
            status: "ACTIVE",
            insert_ts: "",
        },
        loading: LoadState.NotLoaded,
        saveLoading: false,
        error: null,
    },

};
