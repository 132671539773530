import moment from "moment";
import { LoadState } from "../../constants/enums";
import { STANDARD_APP_DATE_FORMAT, STANDARD_APP_TIME_FORMAT } from "../../constants/constants";

export interface INoticesState {
  list: INotices[];
  totalRecords: number;
  loading: LoadState;
}

export interface INotices {
  incremental_number: number | null;
  notice_code: string | null;
  notice_type: string | null;
  description: string | null;
  customer: number | null;
  policy_number: string | null;
  line_of_business: string | null;
  producer: string | null;
  csr: string | null;
  branch: string | null;
  status_other: string | null;
  action_taken: string | null;
  file_upload: string | null;
  notes: string | null;
  assigned_to_id: number | null;
  status: string;
  due_date: string | null;
  create_ts: string | null;
  due_time: string | null;
  insurer_id: string | null;
  policy_type: string | null;
}

export const initialNoticesState: INoticesState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};

export const initialNotices: INotices = {
  incremental_number: null,
  notice_code: null,
  notice_type: null,
  description: null,
  customer: null,
  policy_number: null,
  line_of_business: null,
  producer: null,
  csr: null,
  branch: null,
  status_other: null,
  action_taken: null,
  file_upload: null,
  notes: null,
  assigned_to_id: null,
  status: "Open",
  due_date: moment().format(STANDARD_APP_DATE_FORMAT),
  create_ts: null,
  due_time: moment().endOf("day").toString(),
  insurer_id: null,
  policy_type: null,
};
