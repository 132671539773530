import { action } from "typesafe-actions";
import {
  ICompanyInformation,
  initialAccountingCompanyInformation,
} from "./companyInformation.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_ACCOUNTING_COMPANY_INFORMATION_PROGRESS =
  "FETCH_ACCOUNTING_COMPANY_INFORMATION_PROGRESS";
export const FETCH_ACCOUNTING_COMPANY_INFORMATION_SUCCESS =
  "FETCH_ACCOUNTING_COMPANY_INFORMATION_SUCCESS";
export const FETCH_ACCOUNTING_COMPANY_INFORMATION_FAILURE =
  "FETCH_ACCOUNTING_COMPANY_INFORMATION_FAILURE";

export const fetchAccountingCompanyInformationProgress = () =>
  action(FETCH_ACCOUNTING_COMPANY_INFORMATION_PROGRESS);
export const fetchAccountingCompanyInformationSuccess = (
  data: ICompanyInformation
) => action(FETCH_ACCOUNTING_COMPANY_INFORMATION_SUCCESS, { data });
export const fetchAccountingCompanyInformationFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_COMPANY_INFORMATION_FAILURE, { errorMessage });

export const fetchAccountingCompanyInformationAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingCompanyInformationProgress());

      const res = await api.get(
        `/accounting/get-account-company-information`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingCompanyInformationSuccess(data[0]));
      } else {
        dispatch(
          fetchAccountingCompanyInformationSuccess(
            initialAccountingCompanyInformation["data"]
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAccountingCompanyInformationFailed("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingCompanyInformationAysnc =
  (
    data: ICompanyInformation,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-company-information", data);
      let message = "Company Information saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
