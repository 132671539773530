import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../../helpers";
import {
  cancelType,
  policyStatus,
  transactionTypes,
  typeOfPolicy,
  form_of_buisness,
  segment,
  cancellationReason,
} from "../../../../../../../constants/constants";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../../../../components/LocationAutoComplete/LocationAutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../../components/formsComponents/CustomDatePicker";
import {
  useCustomerFullInfo,
  useCustomerPropspect,
  useDispatchWrapper,
  useFetchPolicies,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { MODULE_IDS, POLICIES } from "../../../../../../../constants/enums";
import moment from "moment";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { addCommAutomobileCustomerInfoAsync } from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetailsActions";
import { ICommAutomobileCustomerInfo } from "../../../../../../../redux/commAutoPolicy/commAutoBasicDetails/commAutoBasicDetails.types";
import { useNavigate } from "react-router-dom";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { BinderNumber } from "../../../../../../../components/BinderNumber/BinderNumber";

export const CommAutomobileCustomerInfo: React.FC<{
  customerId: number;
  customerPolicyId?: number | null;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const { policies, getPolicySubtypes } = useFetchPolicies();
  const customerFullDetails = useCustomerFullInfo();
  const customerProspect = useCustomerPropspect();
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.basicDetails
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: customerInfo.data,
    validate: (values) => {
      const errors: any = {};
      if (!values.line_of_business) {
        errors.line_of_business = "*Line of Business is required";
      } else if (Number(values.sub_type) === 0) {
        errors.sub_type = "*Line of Business is required";
      }
      return errors;
    },
    onSubmit: async (values: any) => {
      setSaveLoading(true);
      dispatch(
        addCommAutomobileCustomerInfoAsync(
          { ...values, customer_id: customerId },
          (isSuccess, policyId) => {
            if (isSuccess && policyId) {
              navigate(
                `/comm-automobile-policy/${props.customerId}/basic-details/${policyId}`
              );
            }
            setSaveLoading(false);
          }
        )
      );
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };
  const handleCheckBox =
    (key: keyof ICommAutomobileCustomerInfo) =>
    (e: React.SyntheticEvent<Element>, checked: boolean) => {
      setFieldValue(key, checked);
    };

  const handleLineOfBusiness = (event: SelectChangeEvent<unknown>) => {
    setValues({
      ...values,
      line_of_business: event.target.value as string,
      sub_type: "",
    });
  };
  const handleBinderSave = (
    binderNumber: string | null,
    onCallBack: (isSuccess: boolean) => void
  ) => {
    dispatch(
      addCommAutomobileCustomerInfoAsync(
        { ...values, binder_number: binderNumber },
        (isSuccess) => {
          onCallBack(isSuccess);
        }
      )
    );
  };

  React.useEffect(() => {
    if (!customerPolicyId && customerFullDetails) {
      setValues({
        ...customerInfo.data,
        customer_code: customerProspect.data.customer_code,
        customer_name: customerProspect.data.customer_name,
        address: customerProspect.data.mailing_address,
        province_or_state: customerProspect.data.province_or_state,
        city: customerProspect.data.city,
        postal_code: customerProspect.data.postal_code,
        country: customerProspect.data.country,
        unit_or_suite: customerProspect.data.unit_or_suite,
        language: customerProspect.data.language,
        line_of_business: POLICIES.COMMERCIAL_AUTO_POLICY,
        form_of_business: customerProspect.data.form_of_business,
      });
    } else {
      setValues(customerInfo.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.data, customerProspect.data, customerPolicyId]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Customer Name</CustomFormLabel>
          <CustomTextField
            id="customer_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.customer_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Customer Code</CustomFormLabel>
          <CustomTextField
            name="customer_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            disabled
            value={values.customer_code}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Form of Business</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="form_of_business"
            fullWidth
            value={values.form_of_business}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
            options={form_of_buisness}
            error={Boolean(errors.form_of_business)}
          />
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Transaction Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="transaction_type"
            fullWidth
            value={values.transaction_type}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {transactionTypes.map((transactionType) => {
              return (
                <MenuItem value={transactionType}>{transactionType}</MenuItem>
              );
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Language</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.language}
            name="language"
            onChange={handleChange}
            placeholder="Select One"
            displayEmpty
            options={["E", "F"].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Address Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="residential_or_business"
            fullWidth
            value={values.residential_or_business}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {["R", "B"].map((transactionType) => {
              return (
                <MenuItem value={transactionType}>{transactionType}</MenuItem>
              );
            })}
          </CustomSelect>
        </Grid>
        <Grid item xs={12} lg={3.6}>
          <CustomFormLabel>Address</CustomFormLabel>
          <LocationAutoComplete
            id="address"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.address}
            onLocationChange={handleAddress}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Unit/Suite</CustomFormLabel>
          <CustomTextField
            id="unit_or_suite"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.unit_or_suite}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>City</CustomFormLabel>
          <CustomTextField
            id="city"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>State/Province</CustomFormLabel>
          <CustomTextField
            id="province_or_state"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.province_or_state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.2}>
          <CustomFormLabel>Postal Code</CustomFormLabel>
          <CustomTextField
            id="postal_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.postal_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <CustomFormLabel>Country</CustomFormLabel>
          <CustomTextField
            id="country"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.country}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <LabelCard heading="Policy Information" />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Quote Number</CustomFormLabel>
          <CustomTextField
            name="quote_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.quote_number}
            onChange={handleChange}
          />
        </Grid>

        <BinderNumber
          value={values.binder_number}
          policyId={customerPolicyId}
          onSave={handleBinderSave}
        ></BinderNumber>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Number</CustomFormLabel>
          <CustomTextField
            name="policy_number"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.policy_number}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Effective Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.effective_date}
            onChange={(newValue) => {
              setFieldValue("effective_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Effective Time</CustomFormLabel>
          <CustomTimePicker
            fullWidth
            value={values.effective_time}
            onChange={(newValue) => {
              setFieldValue("effective_time", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Expiry Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.expiry_date || ""}
            onChange={(newValue) => {
              setFieldValue("expiry_date", newValue);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Segment</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            name="segment"
            value={values.segment}
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            multiple
            options={segment.map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Type</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="line_of_business"
            fullWidth
            value={values.line_of_business}
            onChange={handleLineOfBusiness}
            disabled
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {policies.map((policy) => {
              return <MenuItem value={policy.type}>{policy.type}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Line of Business</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="sub_type"
            fullWidth
            value={Number(values.sub_type)}
            onChange={handleChange}
            displayEmpty
            placeholder="Select One"
            helperText={errors.sub_type}
            options={getPolicySubtypes(values.line_of_business || "").map(
              (item) => {
                return { label: item.name, value: item.policy_id };
              }
            )}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Program</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            value={values.program}
            name="program"
            onChange={handleChange}
            placeholder="Select one"
            displayEmpty
            options={["Option 1", "Option 2"].map((template) => {
              return { label: template, value: template };
            })}
          ></ControlledCustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Type of Policy</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="type_of_policy"
            fullWidth
            value={values.type_of_policy}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {typeOfPolicy.map((policyType) => {
              return <MenuItem value={policyType}>{policyType}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} lg={2.4}>
          <CustomFormLabel>Policy Status</CustomFormLabel>
          <CustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            name="policy_status"
            value={values.policy_status}
            onChange={handleChange}
            displayEmpty
            renderValue={renderDropdownValue(`Select one`)}
          >
            {policyStatus.map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>
        {values.policy_status === "Cancelled" && (
          <>
            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Cancel Reason</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="cancellation_reason"
                fullWidth
                value={values.cancellation_reason}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {cancellationReason.map((reason) => {
                  return <MenuItem value={reason}>{reason}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date of Cancel</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_cancelled || ""}
                onChange={(newValue) => {
                  setFieldValue("date_cancelled", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Cancel Type</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="cancellation_type"
                value={values.cancellation_type}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {cancelType.map((status) => {
                  return <MenuItem value={status}>{status}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <LabelCard heading="Preferences" />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox(
                "send_invoices_renewal_and_receivacble_letter_via_emails"
              )}
              control={
                <Checkbox
                  checked={
                    values.send_invoices_renewal_and_receivacble_letter_via_emails ||
                    false
                  }
                />
              }
              label="Send Invoices , Renewal , Recievable By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("send_statements_via_email")}
              control={
                <Checkbox
                  name="send_statements_via_email"
                  checked={values.send_statements_via_email || false}
                />
              }
              label="Send Statement By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              labelPlacement="end"
              onChange={handleCheckBox("send_eDocs_via_email")}
              control={
                <Checkbox
                  name="send_eDocs_via_email"
                  checked={values.send_eDocs_via_email || false}
                />
              }
              label="Send Docs By Email"
              sx={{ m: 0, fontSize: 15 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <RoleBasedCustomButton
          moduleId={MODULE_IDS.COMMERCIAL_AUTO}
          type="submit"
          disabled={saveLoading}
          variant="contained"
        >
          Save
        </RoleBasedCustomButton>
      </Box>
    </form>
  );
};
