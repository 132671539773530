import React from "react";
import { IStandardCellListProps } from "./interfaces/IStandards";
import { Box, Grid, Typography } from "@mui/material";
import { StandardCardCell } from "./StandardCell";
import { StandadCard } from "../Cards";
import { head } from "lodash";

export const StandardCellList: React.FC<IStandardCellListProps> = (props) => {
  const { list,heading, colSize } = props;
  return (
    <StandadCard>
      {heading && <Typography variant="h2" fontWeight={600} color="primary" marginBottom={2}>{heading}</Typography>}
      <Grid container spacing={1}>
        {list.map((item) => {
          if (!item.isHide) {
            return (
              <Grid key={item.heading} item xs={12} md={item.colSize || colSize}>
                <StandardCardCell
                  heading={item.heading}
                  isHide={item.isHide}
                  data={item.data}
                />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </StandadCard>
  );
};
