import {
  IRow,
  TABLE_ROW_TYPE,
} from "../../components/Table/interfaces/IDataTableProps";
import { LoadState } from "../../constants/enums";
import { getUniqueId } from "../../helpers";

export interface IAutomobileScheduleState {
  scheduleA: {
    loading: LoadState;
    data: IAutomobileScheduleA;
    listData: IAutomobileScheduleA[];
    listLoading: LoadState;
  };
}

export interface IAutomobileScheduleA {
  incremental_number: number | null;
  customer_policy_id: number;
  vehicle_number: number | null;
  coverage_number: number | null;
  section_one_third_party_liability: ICommScheduleAColumns[];
  section_two_accident_benefits: ICommScheduleAColumns[];
  optional_increased_accident_benefits: ICommScheduleAColumns[];
  section_three: ICommScheduleAColumns[];
  section_four: ICommScheduleAColumns[];
  section_five_loss_or_damage: ICommScheduleAColumns[];
  section_six_policy_change_forms: ICommScheduleAColumns[];
  // endorsements: ICommScheduleAColumns[];
  set_as_default: string | null;
  status: string | null;
  minimum_retained_premium: number | null;
  total_premium: number | null;
}

export interface ICommScheduleAColumns {
  key?: string;
  label: string | null;
  declined: string | null;
  deductibleOrLimit: string | null;
  premium: string;
  action: string | null;
}

export interface IScheduleCCoverage extends IRow {
  type: string;
  rowType: TABLE_ROW_TYPE;
  rowLabel?: string;
  DECLINED?: string | null;
  DEDUCTIBLE?: string | null;
  PREMIUM?: string | null;
  ACTION?: string | null;
  LIMIT?: string | null;
}

export interface IScheduleCLoss {
  label: string;
  locationPerItem1: string;
  subsectionInsured: string;
  deductibleOfEachOccurrence: string;
  limitofLiability: string;
  advacnePremimum: string;
}

export interface IScheduleCCollision {
  label: string;
  locationPerItem1: string;
  maximumNumberOfCustomers: string;
  deductibleOfEachOccurrence: string;
  limitofLiability: string;
  advacnePremimum: string;
  action?: string;
  rowCellsConfig?: IRow["rowCellsConfig"];
}

export interface IAutomobileScheduleCoverage extends IRow {
  type: string;
  rowType: TABLE_ROW_TYPE;
  rowLabel?: string;
  DECLINED?: string | null;
  DEDUCTIBLE?: string | null;
  PREMIUM?: string | null;
  ACTION?: string | null;
  LIMIT?: string | null;
}

export interface IScheduleCCollision {}

export const initialAutomoblieSchedule: IAutomobileScheduleState = {
  scheduleA: {
    data: {
      incremental_number: null,
      customer_policy_id: 0,
      vehicle_number: null,
      coverage_number: null,
      set_as_default: "N",
      minimum_retained_premium: null,
      total_premium: null,
      section_one_third_party_liability: [
        {
          key: getUniqueId(),
          label: "Body Injury",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: "Property Damage",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_two_accident_benefits: [
        {
          label: "Accidents Benefits ( Standard Benefits )",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      optional_increased_accident_benefits: [
        {
          label: "Income Replacement ($600/$800/$1000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Medical, Rehabilitation and Attendant Care($1,30,000 or $1,000,000)",
          declined: "N",
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label:
            "Optional Catastrophic Impairment Benefit (Additional $1,000,000 added to Standard Benefit or Optional Medical, Rehabilitation & Attendant Care)",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Caregiver, Housekeeping and Home Maintenance",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Death and Funeral",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Dependent Care",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
        {
          label: "Indexation Benefits ( Consumer Price Index )",
          declined: "N",
          deductibleOrLimit: "As Stated in Section 4 of the Policy",
          premium: "",
          action: null,
        },
      ],
      section_three: [
        {
          label: "Uninsured Automobile",
          declined: null,
          deductibleOrLimit: "As Stated in Section 5 of the Policy",
          premium: "",
          action: null,
        },
      ],
      section_four: [
        {
          label: "Direct Compensation Property Damage (DCPD)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_five_loss_or_damage: [
        {
          label: "Specified Perils (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Comprehensive (excluding Collision or Upset)",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "Collision or Upset",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          label: "All Perils",
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      section_six_policy_change_forms: [
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
        {
          key: getUniqueId(),
          label: null,
          declined: null,
          deductibleOrLimit: null,
          premium: "",
          action: null,
        },
      ],
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    listData: [],
    listLoading: LoadState.NotLoaded,
  },
};
