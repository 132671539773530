import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ITransactionDescription } from "./TransactionDescription.types";

export const FETCH_TRANSACTION_DESCRIPTION_FAILURE =
  "FETCH_TRANSACTION_DESCRIPTION_FAILURE";
export const FETCH_TRANSACTION_DESCRIPTION_PROGRESS =
  "FETCH_TRANSACTION_DESCRIPTION_PROGRESS";
export const FETCH_TRANSACTION_DESCRIPTION_SUCCESS =
  "FETCH_TRANSACTION_DESCRIPTION_SUCCESS";

export const fetchTransactionDescriptionProgress = () =>
  action(FETCH_TRANSACTION_DESCRIPTION_PROGRESS);
export const fetchTransactionDescriptionSuccess = (
  data: ITransactionDescription
) => action(FETCH_TRANSACTION_DESCRIPTION_SUCCESS, { data });
export const fetchTransactionDescriptionFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_DESCRIPTION_FAILURE, { errorMessage });

export const fetchAccountingTransactionDescriptionAsync =
  (
    transactionDescriptionId: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionDescriptionProgress());

      const res = await api.get(
        `/accounting/get-account-transaction-description?transaction_description_id=${transactionDescriptionId}`
      );
      const data: ITransactionDescription[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTransactionDescriptionSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(
        fetchTransactionDescriptionFailure("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingTransactionDescriptionAysnc =
  (
    data: ITransactionDescription,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-transaction-description", {
        ...data,
      });
      let message = "Transaction saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TRANSACTION_DESCRIPTION_LIST_PROGRESS =
  "FETCH_TRANSACTION_DESCRIPTION_LIST_PROGRESS";
export const FETCH_TRANSACTION_DESCRIPTION_LIST_SUCCESS =
  "FETCH_TRANSACTION_DESCRIPTION_LIST_SUCCESS";
export const FETCH_TRANSACTION_DESCRIPTION_LIST_FAILURE =
  "FETCH_TRANSACTION_DESCRIPTION_LIST_FAILURE";

export const fetchTransactionDescriptionListProgress = () =>
  action(FETCH_TRANSACTION_DESCRIPTION_LIST_PROGRESS);
export const fetchTransactionDescriptionListSuccess = (
  data: ITransactionDescription[]
) => action(FETCH_TRANSACTION_DESCRIPTION_LIST_SUCCESS, { data });
export const fetchTransactionDescriptionListFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_DESCRIPTION_LIST_FAILURE, { errorMessage });

export const fetchAccountingTransactionDescriptionListAsync = (): ThunkAction<
  void,
  IStoreState,
  {},
  AnyAction
> => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionDescriptionListProgress());
      const res = await api.get(
        "/accounting/get-account-transaction-description"
      );
      const data: ITransactionDescription[] = res.data.data;
      dispatch(fetchTransactionDescriptionListSuccess(data));
    } catch (err: any) {
      dispatch(
        fetchTransactionDescriptionListFailure("Something went to be wrong!")
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
};

export const CLEAR_TRANSACTION_DESCRIPTION = "CLEAR_TRANSACTION_DESCRIPTION";
export const clearTransactionDescription = () =>
  action(CLEAR_TRANSACTION_DESCRIPTION);
