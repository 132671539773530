import { LoadState } from "../../constants/enums";
import { IUserProfile, IUsersState } from "./userProfile.types";

export const initialUser: IUserProfile = {
  user_fact_id: 0,
  username: "",
  user_code: "",
  job_title: "",
  first_name: null,
  last_name: null,
  user_type: null,
  mobile: null,
  home_phone: null,
  linkedin_profile: null,
  hire_date: null,
  last_day_at_work: null,
  branch: null,
  phone_ext: null,
  reports_to: null,
  department: null,
  fax: null,
  assigned_phone_number: null, 
  date_of_birth: null,
  sin: null,
  broker: null,
  mother_maiden_name: null,
  photo: null,
  signature: null,
  street_address: null,
  unit_or_suite: null,
  city: null,
  province_or_state: null,
  postal_code: null,
  non_active_firm: null,
  non_active_firm_registered_with_ribo: null,
  non_active_firm_name: null,
  business_number: null,
  bank_name: null,
  bank: null,
  transit: null,
  account: null,
  languages_known: null,
  documents: null,
  csr: null,
  marketer: null,
  producer_one: null,
  producer_two: null,
  producer_three: null,
  branch_code: null,
  bonus_liability_code: null,
  bonus_expense_code: null,
  commission_liability_code: null,
  commission_expense_code: null,
  email: null,
  personal_email: null,
  country: null,
  shared_email: null,
  status: "ACTIVE",
  config_json: "",
  created_by_id: null,
  defaults_role: null,
  insert_ts: null,
  manager_id: null,
  role_id: null,
  user_profile_id: 0,
  producer_one_code: "",
  producer_three_code: "",
  producer_two_code: "",
  marketer_code: "",
  csr_code: "",
};

export const initialUserProfileState: IUsersState = {
  users: {
    data: [],
    totalNumberOfRecords: 0,
    loading: LoadState.NotLoaded,
    user: initialUser,
    userLoading: LoadState.NotLoaded,
    error: null,
  },
  userProfile: initialUser,
  userProfileLoading: LoadState.NotLoaded,  
};


// {
//   first_name: null,
//   last_name: null,
//   user_type: null,
//   mobile: null,
//   home_phone: null,
//   linkedin_profile: null,
//   date_of_birth: null,
//   sin: null,
//   mother_maiden_name: null,
//   photo: null,
//   signature: null,
//   street_address: null,
//   unit_or_suite: null,
//   city: null,
//   province_or_state: null,
//   postal_code: null,
//   country: null,
//   non_active_firm: null,
//   non_active_firm_registered_with_ribo: null,
//   non_active_firm_name: null,
//   business_number: null,
//   bank_name: null,
//   bank: null,
//   bank_number: null,
//   transit: null,
//   account: null,
//   languages_known: null,
//   documents: null,
//   personal_email: null
// },