import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ICommAutomobileScheduleA,
  ICommAutomobileScheduleB,
  ICommAutomobileScheduleC,
  initialCommAutomoblieSchedule,
} from "./commAutoSchedule.types";

export const CLEAR_COMM_AUTOMOBILE_SCHEDULE = "CLEAR_COMM_AUTOMOBILE_SCHEDULE";

export const clearCommAutomobileSchedule = () =>
  action(CLEAR_COMM_AUTOMOBILE_SCHEDULE);

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_FAILED";

export const fetchCommAutomobileScheduleAProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_PROGRESS);
export const fetchCommAutomobileScheduleASuccess = (
  data: ICommAutomobileScheduleA
) => action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_SUCCESS, { data });
export const fetchCommAutomobileScheduleAFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_FAILED);

export const fetchCommAutomobileScheduleAAsync =
  (
    customerPolicyId: number,
    incrementalNumber: number,
    vehicleNumber: number | null
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleAProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-a?customer_policy_id=${customerPolicyId}&incremental_number=${incrementalNumber}&vehicle_number=${vehicleNumber}`
      );
      if (res.data.data) {
        dispatch(fetchCommAutomobileScheduleASuccess(res.data.data[0]));
      } else {
        dispatch(
          fetchCommAutomobileScheduleASuccess({
            ...initialCommAutomoblieSchedule["scheduleA"].data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleAFailed());
    }
  };

export const addCommAutomobileScheduleAAsync =
  (
    data: ICommAutomobileScheduleA,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/policy/edit-policy-commauto-vcs-schedule-a", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Schedule A saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleASuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_FAILED";

export const fetchCommAutomobileScheduleBProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_PROGRESS);
export const fetchCommAutomobileScheduleBSuccess = (
  data: ICommAutomobileScheduleB
) => action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_SUCCESS, { data });
export const fetchCommAutomobileScheduleBFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_FAILED);

export const fetchCommAutomobileScheduleBAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleBProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-b?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${serialNumber}`
      );
      const data: ICommAutomobileScheduleB[] = res.data;
      if (data.length > 0) {
        dispatch(fetchCommAutomobileScheduleBSuccess(data[0]));
      } else {
        dispatch(
          fetchCommAutomobileScheduleBSuccess({
            ...initialCommAutomoblieSchedule["scheduleB"].data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleBFailed());
    }
  };

export const addCommAutomobileScheduleBAsync =
  (
    data: ICommAutomobileScheduleB,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-commauto-vcs-schedule-b", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Schedule B saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleBSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

/***-------- */

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_FAILED";

export const fetchCommAutomobileScheduleCProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_PROGRESS);
export const fetchCommAutomobileScheduleCSuccess = (
  data: ICommAutomobileScheduleC
) => action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_SUCCESS, { data });
export const fetchCommAutomobileScheduleCFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_FAILED);

export const fetchCommAutomobileScheduleCAsync =
  (
    customerPolicyId: number,
    serialNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleCProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-c?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${serialNumber}`
      );
      const data: ICommAutomobileScheduleC[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileScheduleCSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileScheduleCSuccess({
            ...initialCommAutomoblieSchedule["scheduleC"].data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleCFailed());
    }
  };

export const addCommAutomobileScheduleCAsync =
  (
    data: ICommAutomobileScheduleC,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-policy-commauto-vcs-schedule-c", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Schedule C saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchCommAutomobileScheduleCSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_FAILED";

export const fetchCommAutomobileScheduleAsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_PROGRESS);

export const fetchCommAutomobileScheduleAListSuccess = (
  data: ICommAutomobileScheduleA[]
) =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileScheduleAsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_A_LIST_FAILED);

export const fetchCommAutomobileScheduleAListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleAsListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-a?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileScheduleA[] = res.data.data;
      dispatch(fetchCommAutomobileScheduleAListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileScheduleAsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_FAILED";

export const fetchCommAutomobileScheduleBsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_PROGRESS);

export const fetchCommAutomobileScheduleBListSuccess = (
  data: ICommAutomobileScheduleB[]
) =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileScheduleBsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_B_LIST_FAILED);

export const fetchCommAutomobileScheduleBListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleBsListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-b?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileScheduleB[] = res.data.data;
      dispatch(fetchCommAutomobileScheduleBListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileScheduleBsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_FAILED";

export const fetchCommAutomobileScheduleCsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_PROGRESS);

export const fetchCommAutomobileScheduleCListSuccess = (
  data: ICommAutomobileScheduleC[]
) =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_SUCCESS, {
    data,
  });
export const fetchCommAutomobileScheduleCsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_SCHEDULE_C_LIST_FAILED);

export const fetchCommAutomobileScheduleCListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileScheduleCsListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-vcs-schedule-c?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileScheduleC[] = res.data.data;
      dispatch(fetchCommAutomobileScheduleCListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileScheduleCsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
