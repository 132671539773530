import React from "react";
import { ITask } from "../../../redux/tasks/task.types";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import {
  PermContactCalendarOutlined,
  PersonAddAltOutlined,
} from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { UsersAutoSearchByRole } from "../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { useDispatchWrapper } from "../../../hooks";
import { upsertTaskAysnc } from "../../../redux/tasks/tasksActions";
import { useCurrentUserId } from "../../../hooks/useAssignedTo";
import { CustomLightTypography } from "../../../components/formsComponents";

export const TaskAssignedTo: React.FC<{
  task: ITask;
  onComplete: () => void;
}> = (props) => {
  const { task } = props;
  const id = useCurrentUserId();
  const [value, setValue] = React.useState({
    ...task,
    assigned_to_id: task.assigned_to_id || id,
  });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatchWrapper();

  const handleAssign = () => {
    setLoading(true);
    dispatch(
      upsertTaskAysnc({
        ...value,
      }, (isSuccess, taskCode) => {
        if (isSuccess) {
          setOpen(false);
          props.onComplete();
        }
        setLoading(false);
      })
    );
  };

  const handleCancel = () => {
    setOpen(false);
  }

  if (!open) {
    return (
      <Stack direction={"row"} spacing={2} justifyContent={"center"}>
        {task.assigned_to_id ? (
          <CustomLightTypography>
            {task.first_name} {task.last_name}
          </CustomLightTypography>
        ) : (
          ""
        )}

        <Tooltip title="Assign To">
          {Number(task.assigned_to_id) === 0 ? (
            <PersonAddAltOutlined
              color="primary"
              onClick={() => setOpen(true)}
            />
          ) : (
            <Edit color="primary" onClick={() => setOpen(true)} />
          )}
        </Tooltip>
      </Stack>
    );
  }
  return (
    <>
      {open && (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Box sx={{ minWidth: 200 }}>
            <UsersAutoSearchByRole
              value={value.assigned_to_id}
              onSelect={(data) =>
                setValue({
                  ...value,
                  assigned_to_id: data.user_fact_id as number,
                })
              }
            />
          </Box>
          <Button
            variant="contained"
            size="small"
            onClick={handleAssign}
            disabled={loading}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </>
  );
};
