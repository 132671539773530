import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IBinders } from "./binder.types";

export const FETCH_BINDERS_LIST_PROGRESS =
  "FETCH_BINDERS_LIST_PROGRESS";
export const FETCH_BINDERS_LIST_SUCCESS =
  "FETCH_BINDERS_LIST_SUCCESS";
export const FETCH_BINDERS_LIST_FAILED =
  "FETCH_BINDERS_LIST_FAILED";
  
export const fetchBindersListProgress = () =>
  action(FETCH_BINDERS_LIST_PROGRESS);
export const fetchBindersListSuccess = (
  list: IBinders[],
  totalRecords: number
) => action(FETCH_BINDERS_LIST_SUCCESS, { list, totalRecords });
export const fetchBindersListFailed = () =>
  action(FETCH_BINDERS_LIST_FAILED);

export const fetchBindersListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
    isBinder: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBindersListProgress());
      let url = "";
      let isBinderUrl: string = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      if(isBinder === "-1"){
        // eslint-disable-next-line no-useless-concat
        isBinderUrl = "&isBinder=" + "false";
      } else{
        isBinderUrl = "&isBinder=" + isBinder;
      }
      let finalUrl = `/tasks/get-tasks-all-binder-record?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}${isBinderUrl}`;
      if(searchType.length > 0 && searchValue){
        finalUrl=`/tasks/get-tasks-all-binder-record?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&column=${[searchType]}&value=${searchValue}`;
      }
      const res = await api.get(
       finalUrl
      );

      const data: IBinders[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBindersListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBindersListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
