import React from "react";
import { Box, Button, Link, MenuItem } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../components/Table/DataTable";
import { IBusinessPolicyRiskLocation } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocation.types";
import { useDispatchWrapper } from "../../../../../../hooks";
import { fetchBusinessPolicyRiskListAsync } from "../../../../../../redux/businessPolicy/buisenessPolicyRiskLocation/businessPolicyRiskLocationActions";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { IHabitationalPaymentAuthorization } from "../../../../../../redux/habitationalPolicy/habitationalPaymentAuthorization/habitationalPaymentAuthorization.types";
import { fetchHabitationalPaymentAuthorizationListAsync } from "../../../../../../redux/habitationalPolicy/habitationalPaymentAuthorization/habitationalPaymentAuthorizationActions";
import { CustomSelect } from "../../../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../../../helpers";
import { CustomChip } from "../../../../../../components/CustomChip";
import { NotificationsActive, ThumbUpAlt } from "@mui/icons-material";

export const HabitationalPaymentAuthorizationList = () => {
  const data1 = useOutletContext() as any;
  const customerId = data1[0];
  const customerpolicyId = data1[1];
  const navigate = useNavigate();

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.paymentAuthorization
  );
  const dispatch = useDispatchWrapper();

  const [selectedAuth, setselectedAuth] = React.useState<{
    isAllSelected: boolean;
    certificate: IHabitationalPaymentAuthorization[];
  }>({
    isAllSelected: false,
    certificate: [],
  });

  const menuItems =
    selectedAuth.certificate.length > 0 || selectedAuth.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const habitationalPaymentAuthTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    selectionMode: "multiple",
    uniqueRowKeyName: "serial_number",
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/payment`
                  );
                }}
              >
                Add Payment Authorization
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: IHabitationalPaymentAuthorization) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/payment/${row.serial_number}`
                )
              }
            >
              {row.serial_number}
            </Link>
          );
        },
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPaymentAuthorization) => {
          if (row.status === "ACTIVE") {
            return (
              <CustomChip
                color="success"
                icon={<ThumbUpAlt />}
                content={"Active"}
                size="small"
              />
            );
          } else {
            return (
              <CustomChip
                color="error"
                icon={<NotificationsActive />}
                content={"Cancel"}
                size="small"
              />
            );
          }
        },
      },

      {
        key: "insurance_company_address",
        headerName: "Insurance Company Address",
        fieldName: "insurance_company_address",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        onRowCellValueRender: (
          value,
          row: IHabitationalPaymentAuthorization
        ) => {
          return row.insurance_company_address + ", " + row.postal_code;
        },
      },

      {
        key: "mode_of_payment",
        headerName: "Mode of Payment",
        fieldName: "mode_of_payment",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
      },
      {
        key: "next_payment_date",
        headerName: "Next Payment Date",
        fieldName: "next_payment_date",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPaymentAuthorization) => {
          return (
            <StandardTableActions
              //onViewClick={() => setOpenView(row)}
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-forms-tab/${customerpolicyId}/payment/${row.serial_number}`
                );
              }}
              //  more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
    onSelection: (
      isAllSelected: boolean,
      rows: IHabitationalPaymentAuthorization[]
    ) => {
      setselectedAuth({ isAllSelected: isAllSelected, certificate: rows });
    },
  };

  React.useEffect(() => {
    dispatch(fetchHabitationalPaymentAuthorizationListAsync(customerpolicyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerpolicyId]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <DataTable {...habitationalPaymentAuthTableProps} />
      </Box>
    </>
  );
};
