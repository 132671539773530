import React from "react";
import { CustomTextField } from "../formsComponents";
import { InputAdornment, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { AttachMoney, Percent } from "@mui/icons-material";

interface ICurrencyTextFieldProps {
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  value: any;
  showInputProps?: "dollar" | "percent";
  placeholder?: string;
  onChange?: (newValue: string) => void;
}

export const CurrencyTextField: React.FC<ICurrencyTextFieldProps> = (props) => {
  const { value, fullWidth, sx, onChange, showInputProps, placeholder } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Remove non-numeric characters except for periods (decimal points)
    const sanitizedValue = value.replace(/[^0-9.]/g, "");

    // Ensure there's only one decimal point
    const parts = sanitizedValue.split(".");
    if (parts.length > 2) return;

    // Format the number with commas
    if (parts[0]) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (onChange) {
      onChange(parts.join("."));
    }
  };

  return (
    <CustomTextField
      fullWidth={fullWidth}
      sx={sx}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      InputProps={
        showInputProps === "dollar"
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoney />
                </InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position="start">
                  <Percent />
                </InputAdornment>
              ),
            }
      }
    />
  );
};
