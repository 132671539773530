import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { Button, Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { IViewTaskDetailsProps } from "./ViewTaskDetails.types";
import { formatDate } from "../../../../helpers";
import { ViewTaskDetailsLayout } from "../../ViewTaskDetails/ViewTaskDetailsLayout";
import { useNavigate, useParams } from "react-router-dom";
import { StandadCard } from "../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import {
  clearTaskCancel,
  fetchTaskAysnc,
  fetchTaskMultiFileUploadAysnc,
} from "../../../../redux/tasks/tasksActions";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import EditIcon from "@mui/icons-material/Edit";

export const ViewTaskDetails: React.FC = () => {
  // const { open, taskDetails, onClose } = props;

  const { taskCode } = useParams() as { taskCode?: string };

  const { task: taskDetails, taskLoading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.allTasks
  );

  const navigate = useNavigate();
  const dispatch = useDispatchWrapper();
  React.useEffect(() => {
    if (taskCode) {
      dispatch(fetchTaskAysnc(taskCode));
    }
  }, [taskCode]);

  const [attachment, setAttachment] = React.useState<
    ITaskFileUploadAttachment[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (taskCode) {
      dispatch(
        fetchTaskMultiFileUploadAysnc(taskCode, (isSuccess, data) => {
          if (isSuccess) {
            if (data && data.length > 0) {
              setAttachment(data[0].attachments);
            }
          }
          setLoading(false);
        })
      );
    }
  }, [taskCode]);

  const handleClick = () => {
    if (taskCode) {
      navigate("/tasks/" + taskCode);
    } else {
      navigate("/dashboard");
    }
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearTaskCancel());
    };
  }, []);

  return (
    <PageLoader loading={taskLoading === LoadState.InProgress}>
      <StandadCard
        heading="Task Details"
        rightHeading={
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={handleClick}
          >
            Edit
          </Button>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="About Customer"
              colSize={3}
              list={[
                {
                  heading: "Task Name",
                  data: {
                    type: "text",
                    value: taskDetails.task_name,
                  },
                },
                {
                  heading: "Type",
                  data: {
                    type: "text",
                    value: taskDetails.task_type,
                  },
                },
                {
                  heading: "Sub Type",
                  data: {
                    type: "text",
                    value: taskDetails.task_sub_type,
                  },
                },
                {
                  heading: "Status",
                  data: { type: "text", value: taskDetails.status },
                },

                {
                  heading: "Labels",
                  data: { type: "text", value: taskDetails.task_labels },
                },
                {
                  heading: "Send Email Flag",
                  data: {
                    type: "text",
                    value: taskDetails.send_email_flag,
                  },
                },
                {
                  heading: "Url",
                  data: {
                    type: "text",
                    value: taskDetails.task_url,
                  },
                },
                {
                  heading: "Related To/Id",
                  data: {
                    type: "text",
                    value:
                      taskDetails.task_related_to +
                      "/" +
                      taskDetails.task_related_to_id,
                  },
                },
                {
                  heading: "Priority",
                  data: {
                    type: "text",
                    value: taskDetails.task_priority,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Others"
              colSize={3}
              list={[
                {
                  heading: "Created Date",
                  data: {
                    type: "text",
                    value: formatDate(taskDetails.task_created_date),
                  },
                },
                {
                  heading: "Due Date",
                  data: {
                    type: "text",
                    value: formatDate(taskDetails.task_due_date),
                  },
                },
                {
                  heading: "Completed date",
                  data: {
                    type: "text",
                    value:
                      taskDetails.task_completed_date !== ""
                        ? formatDate(taskDetails.task_completed_date)
                        : "--",
                  },
                },
              ]}
            />
          </Grid>
        </Grid>

        {/* {showMore && ( */}
        <ViewTaskDetailsLayout
          taskCode={taskDetails.task_code}
          subType={taskDetails.task_sub_type}
          attachment={attachment}
        />
        {/* )} */}

        {/* <Button
        onClick={() => setShowMore(!showMore)}
        variant="contained"
        sx={{
          mt: 2,
        }}
      >
        Show {showMore ? "Less" : "More"}
      </Button> */}
      </StandadCard>
    </PageLoader>
  );
};
