import { LoadState } from "../../constants/enums";

export interface ITemplateState {
  list: ICreateTemplate[];
  loading: LoadState;
  totalRecords: number;
  template: ICreateTemplate;
  templateLoading: LoadState;
  error: string | null;
}

export interface ICreateTemplate {
  templates_id: number | null;
  template_name: string;
  template_category: string;
  module: string | null;
  sub_mobules: string | null;
  field: string | null;
  body: string | null;
  status: string;
}

export const initialTemplateState: ITemplateState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  template: {
    templates_id: null,
    template_name: "",
    template_category: "",
    module: null,
    sub_mobules: null,
    field: null,
    body: null,
    status: "ACTIVE",
  },
  templateLoading: LoadState.NotLoaded,
  error: null,
};
