import React, { useRef } from "react";
import {
    Avatar,
    BottomNavigation,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { Comment, HistoryOutlined, InsertCommentOutlined, Send } from "@mui/icons-material";
import { RightPanel } from "../RightPanel";
import { CustomTextField } from "../formsComponents";
import { IComment } from "../../redux/comments/commentsTypes";
import { useDispatchWrapper } from "../../hooks";
import {
    fetchCommentsAysnc,
    upsertCommentAysnc,
} from "../../redux/comments/commentsActions";
import { PageLoader } from "../PageLoader/PageLoader";
import { useFormik } from "formik";
import { formatDateWithTime } from "../../helpers";
import { IHistorySidebarProps } from "./HistorySideBar.types";
import { fetchHistoryByTableColumnAsync } from "../../redux/common/commonActions";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { LoadState } from "../../constants/enums";
import ReactJson from "react-json-view";
import { IDataTableProps, RenderType } from "../Table/interfaces/IDataTableProps";
import { StandardTableActions } from "../StandardTableActions/StandardTableActions";
import { IGeneralHistory } from "../../redux/common/common.types";
import { DataTable } from "../Table/DataTable";
import { HistoryViewDialog } from "./HistoryViewDialog/HistoryViewDialog";

export const HistorySideBar: React.FC<IHistorySidebarProps> = (props) => {
    const { columnName, columnValue, tableName } = props;
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <Tooltip title="View History" onClick={handleToggle}>
                <HistoryOutlined color="primary" />
            </Tooltip>

            {open && (
                <RightPanel
                    heading="History"
                    subHeading="View history"
                    open={true}
                    onClose={handleToggle}
                    actionButtons={() => {
                        return (
                              <Button
                                variant="contained"
                                
                                onClick={handleToggle}
                              >
                                Close
                              </Button>
                          
                        );
                      }}
                >
                    {" "}
                    <RenderSideBar tableName={tableName} columnName={columnName} columnValue={columnValue} onToogle={handleToggle} />
                </RightPanel>
            )}
        </>
    );
};

const RenderSideBar: React.FC<{
    columnName: string;
    columnValue: string | number | null;
    tableName: string;
    onToogle: () => void;
}> = (props) => {
    const { tableName, columnName, columnValue } = props;
    const theme = useTheme();
    const { list, loading } = useSelector((storeState: IStoreState) => storeState.common.generalHistory);
    const dispatch = useDispatchWrapper();
    const [openView, setOpenView] = React.useState<IGeneralHistory | null>(null)

    const historyTableProps: IDataTableProps = {
        isPagination: false,
        isDataLoading: loading === LoadState.InProgress,
        tableCommandBarProps: {
            leftItems: {
                customPlugins: [

                ],
            },
            rightItems: {
                customPlugins: [],
            },
        },

        columns: [
            {
                key: "sr_no.",
                headerName: "Sr. No.",
                fieldName: "sr_no.",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row) => {
                    return <Typography variant="body1" fontWeight={700}>{value.column.rowIndex + 1}</Typography>
                },
                exportCellWidth: 15,
                enableSorting: true,
            },
        
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IGeneralHistory) => {
                    return <StandardTableActions onViewClick={() => setOpenView(row)} />;
                },
            },
        ],
        items: list || [],
    };

    const fetchHistory = async () => {
        dispatch(
            fetchHistoryByTableColumnAsync(tableName, columnName, columnValue)
        );
    };

    React.useEffect(() => {
        fetchHistory();
    }, []);


    return (
        <>
            <PageLoader loading={loading === LoadState.InProgress}>
                <Grid container spacing={1}>

                    <Grid item xs={12} lg={12} >
                        <DataTable {...historyTableProps} />
                    </Grid>

                </Grid>
            </PageLoader>
            {openView && (
                <HistoryViewDialog
                    open={true}
                    data={openView}
                    onClose={() => setOpenView(null)}
                />
            )}

        </>
    );
};
