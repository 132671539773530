import React from "react";
import { Box, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const TemplateOne = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = React.useState("");

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          ".ql-container": {
            minHeight: 200,
          },
        }}
      >
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </Box>
    </Grid>
  );
};
