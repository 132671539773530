import {
  useCustomerPropspect,
  useDispatchWrapper,
  useFetchPolicies,
  useTaskCustomerInfo,
} from "../../../../../hooks";
import { StandadCard } from "../../../../../components/Cards";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../components/formsComponents";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { LabelCard } from "../../../../../components/LabelCard/LabelCard";
import { TaskFileUploadButton } from "../../../../tasks/TaskFileUploadTable/TaskFileUploadTable";
import {
  clearTaskUrgentTransactions,
  fetchTaskPolicyCancellationAysnc,
  upsertTaskPolicyCancellationAysnc,
} from "../../../../../redux/taskUrgentTransactions/taskUrgentTransactionsActions";
import { useFormik } from "formik";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import React from "react";
import { RoleBasedCustomButton } from "../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../constants/enums";
import { renderDropdownValue } from "../../../../../helpers";
import { IPolicy } from "../../../../Policies/Policies.types";

export const PolicyCancellationForm: React.FC<{
  handleNavigate: () => void;
  customerId: number;
  customerPolicyId: number;
  taskCode: string;
  agencyBill: string | null;
  policy: string;
  lineOfBusiness: string | null;
  subType: string | null;
  policyNumber: string | null;
}> = (props) => {
  const {
    customerId,
    customerPolicyId,
    taskCode,
    handleNavigate,
    agencyBill,
    policy,
    lineOfBusiness,
    subType,
    policyNumber,
  } = props;
  const { customerDetails, getCustomerDetailsByPolicyNumber } =
    useTaskCustomerInfo(customerId || 0);

  const customerProspect = useCustomerPropspect();
  const [policies, setPolicy] = React.useState<IPolicy | null>(null);

  const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
    const policyNumber = e.target.value as number;
    const policy = getCustomerDetailsByPolicyNumber(policyNumber);
    if (policy) {
      setValues({
        ...values,
        policy_number: policy.customer_policy_id,
      });
      setPolicy(policy);
    }
  };

  const { data, loading } = useSelector(
    (storeState: IStoreState) =>
      storeState.tasks.urgentTransactions.policyCancellation
  );

  const dispatch = useDispatchWrapper();

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);

  const { getPolicySubtypes } = useFetchPolicies();
  const PolicySubType = getPolicySubtypes(lineOfBusiness || "")
    .filter((item) => item.policy_id === Number(subType))
    .map((item) => {
      return item.name;
    });

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: data,
      validate: (values) => {},
      onSubmit: (values) => {
        if (customerPolicyId) {
          setSaveLoading(true);
          dispatch(
            upsertTaskPolicyCancellationAysnc(
              {
                ...values,
                policy_number: Number(policyNumber),
                policy_type: lineOfBusiness,
                line_of_business: PolicySubType[0],
                branch: customerProspect.data.branch,
                broker_code: customerProspect.data.branch_code,
                producer: customerProspect.data.producer_code,
                csr: customerProspect.data.csr_code,
                task_code: taskCode || "",
              },
              (isSuccess) => {
                if (isSuccess) {
                  window.scrollTo(0, 0);
                  handleNavigate();
                }
                setSaveLoading(false);
              }
            )
          );
        }
      },
    });

  React.useEffect(() => {
    if (taskCode) {
      dispatch(fetchTaskPolicyCancellationAysnc(taskCode));
    }
  }, [taskCode]);

  React.useEffect(() => {
    setValues({
      ...data,
      customer_code: customerDetails.customerCode,
      customer_name: customerDetails.customerName,
      bill_type: agencyBill,
      pc_type:
        policy === "Commercial Auto" || policy === "Business"
          ? "Commercial"
          : "Personal",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customerDetails, agencyBill, policy, taskCode]);

  React.useEffect(() => {
    return () => {
      dispatch(clearTaskUrgentTransactions());
    };
  }, []);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <StandadCard>
          <LabelCard heading="Customer Information" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Policy Number</CustomFormLabel>
              <Chip
                label={policyNumber}
                size="medium"
                color="warning"
                sx={{ color: "#000", minWidth: 100, fontSize: 18 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Agency/Direct Bill</CustomFormLabel>
              <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="bill_type"
                fullWidth
                disabled
                value={values.bill_type}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select one`)}
              >
                {["A", "D"].map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Personal/Commercial</CustomFormLabel>
              <CustomTextField
                name="pc_type"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                disabled
                value={values.pc_type}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Customer Code</CustomFormLabel>
              <Chip
                label={values.customer_code}
                size="medium"
                color="warning"
                sx={{ color: "#000", minWidth: 100, fontSize: 18 }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Customer Name</CustomFormLabel>
              <Typography
                variant="h5"
                sx={{ color: "primary.main" }}
                fontWeight={"bold"}
              >
                {values.customer_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Line of Business</CustomFormLabel>
              <Typography variant="h5">{PolicySubType || "--"}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Policy Type</CustomFormLabel>
              <Typography variant="h5">{lineOfBusiness || "--"}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Branch</CustomFormLabel>
              <Typography variant="h5">
                {customerProspect.data.branch || "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CustomFormLabel>Broker Code</CustomFormLabel>
              <Typography variant="h5">
                {customerProspect.data.branch_code || "--"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Insurer</CustomFormLabel>
              <Typography variant="h5">{"--"}</Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Producer</CustomFormLabel>
              <Typography variant="h5">
                {customerProspect.data.producer_code || "--"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>CSR</CustomFormLabel>
              <Typography variant="h5">
                {customerProspect.data.csr_code || "--"}
              </Typography>
            </Grid>
          </Grid>

          <LabelCard heading="Cancellation Request" />

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CustomFormLabel>Date of Cancellation</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.date_of_cancellation || ""}
                onChange={(newValue) => {
                  setFieldValue("date_of_cancellation", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Time of Cancellation</CustomFormLabel>
              <CustomTimePicker
                fullWidth
                value={moment(values.time_of_cancellation)}
                onChange={(newValue) => {
                  const newTime = moment(newValue).format();
                  setFieldValue("time_of_cancellation", newTime);
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomFormLabel>Reason for Cancellation</CustomFormLabel>
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.requested_by_insured === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue(
                        "requested_by_insured",
                        checked ? "Y" : "N"
                      );
                      setFieldValue("rewritten", checked && "N");
                      setFieldValue("other", checked && "N");
                    }}
                  />
                  <Typography>REQUESTED BY INSURED</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.rewritten === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue("rewritten", checked ? "Y" : "N");
                      setFieldValue("requested_by_insured", checked && "N");
                      setFieldValue("other", checked && "N");
                    }}
                  />
                  <Typography>REWRITTEN (Complete Below)</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.other === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue("other", checked ? "Y" : "N");
                      setFieldValue("requested_by_insured", checked && "N");
                      setFieldValue("rewritten", checked && "N");
                    }}
                  />
                  <Typography>OTHER (State in REMARKS)</Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Effective Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.effective_date || ""}
                onChange={(newValue) => {
                  setFieldValue("effective_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Expiration Date</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                value={values.expiration_date || ""}
                onChange={(newValue) => {
                  setFieldValue("expiration_date", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Insurance Company</CustomFormLabel>
              <CustomTextField
                name="insurance_company"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.insurance_company}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CustomFormLabel>Methods of Cancellation</CustomFormLabel>
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.flat === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue("flat", checked ? "Y" : "N");
                      setFieldValue("short_rate", checked && "N");
                      setFieldValue("prorata", checked && "N");
                    }}
                  />
                  <Typography>FLAT</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.short_rate === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue("short_rate", checked ? "Y" : "N");
                      setFieldValue("flat", checked && "N");
                      setFieldValue("prorata", checked && "N");
                    }}
                  />
                  <Typography>SHORT RATE</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={values.prorata === "Y"}
                    onChange={(e, checked) => {
                      setFieldValue("prorata", checked ? "Y" : "N");
                      setFieldValue("flat", checked && "N");
                      setFieldValue("short_rate", checked && "N");
                    }}
                  />
                  <Typography>PRO-RATA</Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <CustomFormLabel>Premium Paid to Broker</CustomFormLabel>
              <CustomTextField
                name="premium_paid_to_broker"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.premium_paid_to_broker}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <CustomFormLabel>Remarks</CustomFormLabel>
              <CustomTextField
                name="remarks"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={values.remarks}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <LabelCard heading="RELEASE(s) ATTACHED" />
          <Grid container spacing={0.5}>
            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.insured === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("insured", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>INSURED</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.mortgagee === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("mortgagee", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>MORTGAGEE</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.lienholder === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("lienholder", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>LIENHOLDER</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.finance_company === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("finance_company", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>FINANCE COMPANY</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.loss_payee === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("loss_payee", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>LOSS PAYEE</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.lessor === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue("lessor", checked ? "Y" : "N")
                  }
                />
                <CustomFormLabel>LESSOR</CustomFormLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Checkbox
                  checked={values.releases_are_not_attached === "Y"}
                  onChange={(e, checked) =>
                    setFieldValue(
                      "releases_are_not_attached",
                      checked ? "Y" : "N"
                    )
                  }
                />
                <CustomFormLabel>
                  WHERE RELEASES ARE NOT ATTACHED, PLEASE ISSUE CANCELLATION
                  NOTICES TO ALL INTERESTS ON THE CANCELLED POLICY
                </CustomFormLabel>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
              <TaskFileUploadButton taskCode={"taskCode"} />
            </Grid>
          </Grid>

          <Stack direction={"row"} spacing={3} sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              moduleId={MODULE_IDS.COMMERCIAL_AUTO}
              variant="contained"
              type="submit"
              onClick={() => setButtonClicked("submit")}
            >
              Save
            </RoleBasedCustomButton>
          </Stack>
        </StandadCard>
      </form>
    </PageLoader>
  );
};
