import produce from 'immer';
import { EDocsActions } from '.';
import { LoadState } from '../../constants/enums';
import { IStoreState } from '../initialStoreState';
import { initialEDocsState } from './eDocs.types';
import {
  FETCH_EDOCS_LIST_PROGRESS,
  FETCH_EDOCS_LIST_SUCCESS,
  FETCH_EDOCS_LIST_FAILED,
  FETCH_EDOCS_PROGRESS,
  FETCH_EDOCS_SUCCESS,
  FETCH_EDOCS_FAILED
} from './eDocsActions';
import { initialEDocs } from './eDocs.types';

const eDocsReducer = (state: IStoreState["eDocs"] = initialEDocsState,
  action: EDocsActions) => {
  switch (action.type) {
    case FETCH_EDOCS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_EDOCS_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_EDOCS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_EDOCS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.eDocLoading = LoadState.InProgress;
        draftState.eDoc = initialEDocs;
      });
      return newState;
    }
    case FETCH_EDOCS_SUCCESS: {
      const { eDoc } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.eDocLoading = LoadState.Loaded;
        draftState.eDoc = eDoc;
      });
      return newState;
    }
    case FETCH_EDOCS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.eDocLoading = LoadState.Failed;
        draftState.errro = errorMessage;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};

export default eDocsReducer;
