import { LoadState } from "../../../constants/enums";

export interface ITransactionTypeState {
  list: ITransactionType[];
  listLoading: LoadState;
  data: ITransactionType;
  loading: LoadState;
  error: string | null;
}

export interface ITransactionType {
  transaction_type_id: string | null;
  customer_id: string | null;
  customer_policy_id: string | null;
  invoice_id: string | null;
  transaction_type: string;
  gl_account_code: string | null;
  detail: string | null;
  status: string;
}

export const initialTransactionTypeState: ITransactionTypeState = {
  list: [],
  listLoading: LoadState.NotLoaded,
  data: {
    transaction_type_id: null,
    customer_id: null,
    customer_policy_id: null,
    invoice_id: null,
    transaction_type: "",
    gl_account_code: null,
    detail: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
  error: null,
};
