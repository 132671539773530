import React from "react";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { IInsuranceReportsDialogProps } from "./InsuranceReportDialog.types";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { TabPanel } from "../../../../components/Tabs/TabPanel";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../components/formsComponents";
import { renderDropdownValue } from "../../../../helpers";
import { RoleBasedCustomButton } from "../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { UsersAutoSearchByRole } from "../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { BranchCodeAutoSearch } from "../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { LabelCard } from "../../../../components/LabelCard/LabelCard";
import { InsurerCodeAutoSearch } from "../../../../components/AutoCompleteSearches";
import moment from "moment";
import { CustomDatePicker } from "../../../../components/formsComponents/CustomDatePicker";
import { addCommAutomobileDriverTasksAsync } from "../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriverActions";
import { useDispatchWrapper } from "../../../../hooks";
import { addAutomobileDriverTasksAsync } from "../../../../redux/automobileDriver/automobileDriverActions";
import { addNoPolicyDriverInsuranceReportAsync } from "../../../../redux/insuranceReports/insuranceReportsActions";
import { useCurrentUserId } from "../../../../hooks/useAssignedTo";
import { CustomerAutoSearch } from "../../../../components/AutoCompleteSearches/CustomerAutoSearch";
import { ICustomerFullDetails } from "../../../../redux/customer/customer.types";
import { getAllCustomerPolicyId } from "../../../../helpers/getAllCustomerPolicyId";
import { IPolicy } from "../../../Policies/Policies.types";
import produce from "immer";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { POLICIES } from "../../../../constants/enums";

export const InsuranceReportDialog: React.FC<IInsuranceReportsDialogProps> = (
  props
) => {
  const { open, onClose, data, onComplete } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const handleStep = (stepValue: number) => {
    stepValue === 0 ? setActiveStep(1) : setActiveStep(0);
  };
  const currentUser = useCurrentUserId();

  const [typeOfPolicy, setTypeOfPolicy] = React.useState("");
  const handlePolicyType = (event: any) => {
    setTypeOfPolicy(event.target.value);
  };

  const [currentCustomer, setCurrentCustomer] =
    React.useState<ICustomerFullDetails | null>(null);
  const [customerName, setCustomerName] = React.useState<number | string | null>(null);
  const [customerCode, setCustomerCode] = React.useState("");
  const [customerPolicyId, setCustomerPolicyId] = React.useState<number | null>(
    null
  );
  const [existingCustomer, setExistingCustomer] = React.useState<string | "-1">(
    "-1"
  );

  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<IPolicy[]>([]);
  const [file, setFile] = React.useState<any>(null);
  const dispatch = useDispatchWrapper();

  const [typeOfPolicyArray, setTypeOfPolicyArray] = React.useState<string[]>(
    []
  );

  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: data,
      validate: (values) => {
        const errors: any = {};
        return errors;
      },
      onSubmit: async (values) => {
        if (activeStep === 0) {
          onClose();
        }
        if (currentCustomer && customerPolicyId) {
          setLoading(true);
          typeOfPolicy === "Commercial Automobile" &&
            activeStep === 1 &&
            dispatch(
              addCommAutomobileDriverTasksAsync(
                {
                  ...values,
                  customer_policy_id: customerPolicyId,
                },
                currentCustomer.customer_id,
                file,
                file,
                (isSuccess) => {
                  if (isSuccess) {
                    window.scrollTo(0, 0);
                    onClose();
                  }
                  setLoading(false);
                }
              )
            );

          typeOfPolicy === "Automobile" &&
            activeStep === 1 &&
            dispatch(
              addAutomobileDriverTasksAsync(
                {
                  ...values,
                  customer_policy_id: customerPolicyId,
                },
                currentCustomer.customer_id,
                file,
                file,
                (isSuccess) => {
                  if (isSuccess) {
                    window.scrollTo(0, 0);
                    onClose();
                  }
                  setLoading(false);
                }
              )
            );
        } else {
          typeOfPolicy === "No Policy" &&
            activeStep === 1 &&
            dispatch(
              addNoPolicyDriverInsuranceReportAsync(
                {
                  ...values,
                  request_status: values.request_status || "Submitted",
                },
                null,
                null,
                (isSuccess) => {
                  if (isSuccess) {
                    window.scrollTo(0, 0);
                    onClose();
                    onComplete();
                  }
                  setLoading(false);
                }
              )
            );
        }
      },
    });

  React.useEffect(() => {
    setFieldValue("assigned_to", currentUser);
    setFieldValue("requested_by", currentUser);
  }, [currentUser]);

  const getList = async (id: number| string) => {
    const list = await getAllCustomerPolicyId(id);
    if (typeOfPolicy === "Commercial Automobile" && list) {
      const filteredArray = list.filter(
        (item) => item.line_of_business === POLICIES.COMMERCIAL_AUTO_POLICY
      );
      setList(filteredArray || []);
    } else if (typeOfPolicy === "Automobile" && list) {
      const filteredArray = list.filter(
        (item) => item.line_of_business === POLICIES.AUTOMOBILE_POLICY
      );
      setList(filteredArray || []);
    }
  };

  const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as number;
    setCustomerPolicyId(value);
    const policyDetails = list.find((x) => x.customer_policy_id === value);
    if (policyDetails) {
      const newValues = produce(values, (draftState) => {
        draftState.branch = policyDetails.branch_code;
        draftState.producer = policyDetails.producer_one;
        draftState.csr = policyDetails.csr;
      });
      setValues(newValues);
    }
  };

  React.useEffect(() => {
    if (currentCustomer) {
      setCustomerName(currentCustomer.customer_id);
      setCustomerCode(currentCustomer.customer_code);
      getList(currentCustomer.customer_id);
    }
  }, [currentCustomer, customerName]);

  React.useEffect(() => {
    if (existingCustomer === "prospect") {
      setTypeOfPolicy("No Policy");
      setTypeOfPolicyArray(["No Policy"]);
    } else if (existingCustomer === "existing") {
      setTypeOfPolicy("");
      setTypeOfPolicyArray(["Commercial Automobile", "Automobile"]);
    } else if (existingCustomer === "-1") {
      setTypeOfPolicy("");
    }
  }, [existingCustomer]);

  return (
    <Dialog
      open={open}
      title="Order Insurance Report"
      onClose={onClose}
      size={activeStep === 0 ? "sm" : "lg"}
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: activeStep === 0 ? "Next" : "Back",
          variant: "contained",
          onClick: () => {
            handleStep(activeStep);
          },
          disabled: typeOfPolicy === "",
        },
        {
          type: "submit",
          label: "Save & Close",
          variant: "contained",
          disabled: loading,
          hidden: activeStep === 0 && true,
        },
      ]}
    >
      <Stepper sx={{ mb: 3, mt: 3 }} activeStep={activeStep}>
        {["Step 1", "Step 2"].map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant="h4">{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <TabPanel value={activeStep} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Stack direction="row" spacing={4}>
              <Stack direction="row" spacing={0}>
                <CustomFormLabel>Existing Customer</CustomFormLabel>
                <Checkbox
                  sx={{ mt: 0.5 }}
                  value={existingCustomer}
                  checked={existingCustomer === "existing"}
                  onChange={(e, checked) => {
                    setExistingCustomer(checked ? "existing" : "-1");
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={0}>
                <CustomFormLabel>Prospect</CustomFormLabel>
                <Checkbox
                  sx={{ mt: 0.5 }}
                  value={existingCustomer}
                  checked={existingCustomer === "prospect"}
                  onChange={(e, checked) => {
                    setExistingCustomer(checked ? "prospect" : "-1");
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={12}>
            <CustomFormLabel>Type of Policy</CustomFormLabel>
            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="typeOfPolicy"
              value={typeOfPolicy}
              onChange={handlePolicyType}
              displayEmpty
              fullWidth
              renderValue={renderDropdownValue(`Select one`)}
            >
              {typeOfPolicyArray.map((policyType) => {
                return <MenuItem value={policyType}>{policyType}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={activeStep} index={1}>
        <form onSubmit={handleSubmit}>
          {typeOfPolicy !== "No Policy" && (
            <Grid container spacing={1}>
              <Grid item lg={4}>
                <CustomerAutoSearch
                  label="Customer Name"
                  value={customerName}
                  onSelect={(newValue) => {
                    setCurrentCustomer(newValue);
                    setCustomerName(newValue.customer_id);
                  }}
                />
              </Grid>

              <Grid item lg={4}>
                <CustomFormLabel>Customer Code</CustomFormLabel>
                <CustomTextField
                  name="customerCode"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={customerCode}
                  disabled
                />
              </Grid>

              <Grid item lg={4}>
                <CustomFormLabel>Policy Number</CustomFormLabel>
                <ControlledCustomSelect
                  name="cutomerPolicyId"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  options={list
                    .filter((id) => id.policy_number !== null)
                    .map((id) => {
                      return {
                        label: id.policy_number,
                        value: id.customer_policy_id,
                      };
                    })}
                  value={customerPolicyId}
                  onChange={handlePolicyChange}
                  placeholder="Select one"
                ></ControlledCustomSelect>
              </Grid>
            </Grid>
          )}

          <LabelCard heading="Insurance Reports" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={1}>
              <CustomFormLabel>#</CustomFormLabel>
              <CustomTextField
                name="incremental_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.incremental_number}
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Driver Name</CustomFormLabel>
              <CustomTextField
                name="driver_name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.driver_name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Driver License Number</CustomFormLabel>
              <CustomTextField
                name="license_number"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.license_number}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Business Reason</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.business_reason}
                name="business_reason"
                onChange={handleChange}
                displayEmpty
                options={["Verify Driver Record", "Option 2"].map(
                  (template) => {
                    return { label: template, value: template };
                  }
                )}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Date and Time</CustomFormLabel>
              <CustomDatePicker
                fullWidth
                disabled
                value={values.date_and_time || ""}
                onChange={(newValue, date_and_time) => {
                  setFieldValue("date_and_time", date_and_time);
                }}
              />
            </Grid>

            {/* {taskNumber && (
              <Grid item xs={12} lg={1.4}>
                <CustomFormLabel>Days Left</CustomFormLabel>
                <CustomTextField
                  name="days_left"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.days_left}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
            )} */}

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Transaction Type</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.transaction_type}
                name="transaction_type"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={[
                  "New",
                  "Remarket",
                  "Endorsement - Add Driver",
                  "Policy Change",
                  "Certificate of Insurance",
                  "Report Claim",
                ].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Reports Requested</CustomFormLabel>
              <ControlledCustomSelect
                fullWidth
                value={values.report_requested}
                name="report_requested"
                onChange={handleChange}
                placeholder="Select one"
                displayEmpty
                options={["MVR", "A+", "MVR & A+", "Hits"].map((template) => {
                  return { label: template, value: template };
                })}
              ></ControlledCustomSelect>
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel># of Disclosed Convictions</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="number_of_disclosed_convictions"
                value={values.number_of_disclosed_convictions}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={2.4}>
              <CustomFormLabel>Insurer Code</CustomFormLabel>
              <InsurerCodeAutoSearch
                value={values.insurer_code}
                onSelect={(value) => {
                  setFieldValue("insurer_code", value.value);
                }}
              />
            </Grid>

            {values.insurer_code && ["HALI"].includes(values.insurer_code) && (
              <Grid item xs={12} lg={2.4}>
                <CustomFormLabel>Intact Branch</CustomFormLabel>
                <ControlledCustomSelect
                  fullWidth
                  value={values.intact_branch}
                  name="intact_branch"
                  onChange={handleChange}
                  placeholder="Select one"
                  displayEmpty
                  options={[
                    "Intact-Personal Insurance",
                    "Intact-Commercial Insurance",
                    "Intact-Group Insurance",
                    "N/A",
                  ].map((template) => {
                    return { label: template, value: template };
                  })}
                ></ControlledCustomSelect>
              </Grid>
            )}

            {/* {values.report_requested === "Hits" && (
              <>
                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>Years at This address?</CustomFormLabel>
                  <CustomTextField
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    name="years_at_this_address"
                    value={values.years_at_this_address}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>Current Address Type</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="current_address_type"
                    fullWidth
                    value={values.current_address_type}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["R", "B"].map((transactionType) => {
                      return (
                        <MenuItem value={transactionType}>
                          {transactionType}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <CustomFormLabel>Current Address</CustomFormLabel>
                  <LocationAutoComplete
                    id="current_address"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address}
                    onLocationChange={handleAddress}
                  />
                </Grid>

                <Grid item xs={12} lg={1}>
                  <CustomFormLabel>Unit/Suite</CustomFormLabel>
                  <CustomTextField
                    id="current_address_unit_or_suite"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_unit_or_suite}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>City</CustomFormLabel>
                  <CustomTextField
                    id="current_address_city"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.5}>
                  <CustomFormLabel>State/Province</CustomFormLabel>
                  <CustomTextField
                    id="current_address_state_or_province"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_state_or_province}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <CustomFormLabel>Postal Code</CustomFormLabel>
                  <CustomTextField
                    id="current_address_postal_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.current_address_postal_code}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>New Address Type</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="new_address_type"
                    fullWidth
                    value={values.new_address_type}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["R", "B"].map((transactionType) => {
                      return (
                        <MenuItem value={transactionType}>
                          {transactionType}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={3.6}>
                  <CustomFormLabel>New Address</CustomFormLabel>
                  <LocationAutoComplete
                    id="new_address"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address}
                    onLocationChange={handleNewAddress}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>Unit/Suite</CustomFormLabel>
                  <CustomTextField
                    id="new_address_unit_or_suite"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_unit_or_suite}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>City</CustomFormLabel>
                  <CustomTextField
                    id="new_address_city"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>State/Province</CustomFormLabel>
                  <CustomTextField
                    id="new_address_state_or_province"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_state_or_province}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={1.68}>
                  <CustomFormLabel>Postal Code</CustomFormLabel>
                  <CustomTextField
                    id="new_address_postal_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.new_address_postal_code}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )} */}
          </Grid>

          {/* {taskNumber && (
            <>
              <LabelCard heading="Status & Updates" />
              <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Written</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    value={values.date_wriitten || ""}
                    disabled
                    onChange={(newValue) => {
                      const newDate = moment(newValue).format("YYYY-MM-DD");
                      setFieldValue("date_voided", null);
                      setFieldValue("date_cancelled", null);
                      setFieldValue("date_wriitten", newDate);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Cancelled</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_cancelled || ""}
                    onChange={(newValue) => {
                      const newDate = moment(newValue).format("YYYY-MM-DD");
                      setFieldValue("date_wriitten", null);
                      setFieldValue("date_voided", null);
                      setFieldValue("date_destroyed", null);
                      setFieldValue("date_cancelled", newDate);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Voided</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_voided || ""}
                    onChange={(newValue) => {
                      const newDate = moment(newValue).format("YYYY-MM-DD");
                      setFieldValue("date_wriitten", null);
                      setFieldValue("date_cancelled", null);
                      setFieldValue("date_destroyed", null);
                      setFieldValue("date_voided", newDate);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date Destroyed</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_destroyed || ""}
                    onChange={(newValue) => {
                      const newDate = moment(newValue).format("YYYY-MM-DD");
                      setFieldValue("date_wriitten", null);
                      setFieldValue("date_cancelled", null);
                      setFieldValue("date_voided", null);
                      setFieldValue("date_destroyed", newDate);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Request Status</CustomFormLabel>
                  <ControlledCustomSelect
                    fullWidth
                    value={values.request_status}
                    name="request_status"
                    onChange={handleChange}
                    placeholder="Select one"
                    displayEmpty
                    options={[
                      "Submitted",
                      "Voided",
                      "Completed",
                      "Cancelled",
                    ].map((template) => {
                      return { label: template, value: template };
                    })}
                  ></ControlledCustomSelect>
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Policy Status Cancelled</CustomFormLabel>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="policy_status_cancelled"
                    value={values.policy_status_cancelled}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((policyType) => {
                      return (
                        <MenuItem value={policyType}>{policyType}</MenuItem>
                      );
                    })}
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Customer Name</CustomFormLabel>
                  <CustomTextField
                    name="customer_name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.customer_name}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Customer Code</CustomFormLabel>
                  <CustomTextField
                    name="customer_code"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.customer_code}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Policy Number</CustomFormLabel>
                  <CustomTextField
                    name="policy_number"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    value={values.policy_number}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <CustomFormLabel>Insurer Code</CustomFormLabel>
                  <InsurerCodeAutoSearch
                    value={values.insurer_code_written}
                    onSelect={(value) => {
                      setFieldValue("insurer_code_written", value.value);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )} */}

          <LabelCard heading="Office Information" />
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={values.requested_by}
                label="Requested By"
                onSelect={(value) => {
                  setFieldValue("requested_by", value.user_fact_id);
                  setFieldValue("requested_by_code", value.user_code);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Requested By Code</CustomFormLabel>
              <CustomTextField
                name="requested_by_code"
                type="text"
                value={values.requested_by_code}
                disabled
                fullWidth
                onChange={handleChange}
              ></CustomTextField>
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Branch</CustomFormLabel>
              <BranchCodeAutoSearch
                value={values.branch}
                onSelect={(value) => setFieldValue("branch", value.value)}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={values.producer}
                label="Producer"
                onSelect={(value) => {
                  setFieldValue("producer", value.user_fact_id);
                  setFieldValue("producer_code", value.user_code);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Producer Code</CustomFormLabel>
              <CustomTextField
                name="producer_code"
                type="text"
                value={values.producer_code}
                disabled
                fullWidth
                onChange={handleChange}
              ></CustomTextField>
            </Grid>

            <Grid item xs={12} lg={3}>
              <UsersAutoSearchByRole
                value={values.csr}
                label="CSR"
                onSelect={(value) => {
                  setFieldValue("csr", value.user_fact_id);
                  setFieldValue("csr_code", value.user_code);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>CSR Code</CustomFormLabel>
              <CustomTextField
                name="csr_code"
                type="text"
                value={values.csr_code}
                disabled
                fullWidth
                onChange={handleChange}
              ></CustomTextField>
            </Grid>

            <Grid item xs={12} lg={12}>
              <CustomFormLabel>Notes</CustomFormLabel>
              <CustomTextField
                variant="outlined"
                size="small"
                type="string"
                fullWidth
                name="notes"
                value={values.notes}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </TabPanel>
    </Dialog>
  );
};
