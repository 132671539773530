import { useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../hooks";
import React from "react";
import { Grid } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import { formatTime } from "../../../../helpers";
import { IDeleteGaragePlate } from "../../../../redux/TaskReducer/GaragePlate/garagePlate.types";
import { fetchDeleteGaragePlateAysnc } from "../../../../redux/TaskReducer/GaragePlate/garagePlateActions";
import { LoadState } from "../../../../constants/enums";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";

export const ViewDeleteGaragePlate: React.FC<{
  taskCode: string;
}> = (props) => {
  const { taskCode } = props;

  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.garagePlate.deleteGaragePlate
  );

  const dispatch = useDispatchWrapper();
  const [garagePlateDetails, setGaragePlateDetails] = React.useState<IDeleteGaragePlate>();

  React.useEffect(() => {
    dispatch(fetchDeleteGaragePlateAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    setGaragePlateDetails(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {garagePlateDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: garagePlateDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: garagePlateDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: garagePlateDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: { type: "text", value: garagePlateDetails.line_of_business },
                },

                {
                  heading: "Policy Type",
                  data: { type: "text", value: garagePlateDetails.policy_type },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: garagePlateDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: garagePlateDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: garagePlateDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: garagePlateDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: garagePlateDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Garage Plate Information"
              colSize={3}
              list={[
                {
                  heading: "Plate number",
                  data: {
                    type: "text",
                    value: garagePlateDetails.plate,
                  },
                },
                {
                  heading: "Plate Type",
                  data: {
                    type: "text",
                    value: garagePlateDetails.plate_type,
                  },
                },
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: garagePlateDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(garagePlateDetails.effective_time_of_change.toString()),
                  },
                },
                {
                  heading: "Details",
                  data: {
                    type: "text",
                    value: garagePlateDetails.details,
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
