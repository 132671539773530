import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import {
  ICommAutomobileLiabilityInsurance,
  ICommAutomobileLiabilitySlips,
  ICommAutomobileOCEP16,
  ICommAutomobileOCEP17,
  ICommAutomobileOCEP28A,
} from "./commAutoCertificates.types";

export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchCommAutomobileLiabilitySlipProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchCommAutomobileLiabilitySlipSuccess = (
  data: ICommAutomobileLiabilitySlips
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchCommAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addCommAutomobileLiabilitySlipAsync =
  (
    data: ICommAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-liability-slip-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilitySlipFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP =
  "CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP";
export const clearCommAutomobileLiabiliySlip = () =>
  action(CLEAR_COMM_AUTOMOBILE_LIABILITY_SLIP);

export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED";

export const fetchCommAutomobileLiabilitySlipsListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS);
export const fetchCommAutomobileLiabilitySlipsListSuccess = (
  data: ICommAutomobileLiabilitySlips[]
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS, { data });
export const fetchCommAutomobileLiabilitySlipsListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED);

export const fetchCommAutomobileLiabilitySlipsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilitySlipsListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileLiabilitySlips[] = res.data.data;
      dispatch(fetchCommAutomobileLiabilitySlipsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const previewCommAutomobileLiabilitySlipsPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
  
export const FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP16_FAILED";

export const fetchCommAutomobileOcep16rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_PROGRESS);
export const fetchCommAutomobileOcep16Success = (data: ICommAutomobileOCEP16) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_SUCCESS, { data });
export const fetchCommAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_FAILED, { errorMessage });

export const addCommAutomobileOcep16Async =
  (
    data: ICommAutomobileOCEP16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-sixteen-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP16 = "CLEAR_COMM_AUTOMOBILE_OCEP16";
export const clearCommAutomobileOcep16 = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP16);

export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED";

export const fetchCommAutomobileOcep16ListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_PROGRESS);
export const fetchCommAutomobileOcep16ListSuccess = (
  data: ICommAutomobileOCEP16[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep16ListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP16_LIST_FAILED);

export const fetchCommAutomobileOcep16ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep16ListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP16[] = res.data.data;
      dispatch(fetchCommAutomobileOcep16ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep16PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP17_FAILED";

export const fetchCommAutomobileOcep17rogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_PROGRESS);
export const fetchCommAutomobileOcep17Success = (data: ICommAutomobileOCEP17) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_SUCCESS, { data });
export const fetchCommAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_FAILED, { errorMessage });

export const addCommAutomobileOcep17Async =
  (
    data: ICommAutomobileOCEP17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-seventeen-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP17 = "CLEAR_COMM_AUTOMOBILE_OCEP17";
export const clearCommAutomobileOcep17 = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP17);

export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED";

export const fetchCommAutomobileOcep17ListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_PROGRESS);
export const fetchCommAutomobileOcep17ListSuccess = (
  data: ICommAutomobileOCEP17[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep17ListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP17_LIST_FAILED);

export const fetchCommAutomobileOcep17ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep17ListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP17[] = res.data.data;
      dispatch(fetchCommAutomobileOcep17ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep17PDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED";

export const fetchCommAutomobileOcep28Arogress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_PROGRESS);
export const fetchCommAutomobileOcep28ASuccess = (
  data: ICommAutomobileOCEP28A
) => action(FETCH_COMM_AUTOMOBILE_OCEP28A_SUCCESS, { data });
export const fetchCommAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_FAILED, { errorMessage });

export const addCommAutomobileOcep28Async =
  (
    data: ICommAutomobileOCEP28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileOCEP28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_OCEP28A = "CLEAR_COMM_AUTOMOBILE_OCEP28A";
export const clearCommAutomobileOcep28A = () =>
  action(CLEAR_COMM_AUTOMOBILE_OCEP28A);

export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED";

export const fetchCommAutomobileOcep28AListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_PROGRESS);
export const fetchCommAutomobileOcep28AListSuccess = (
  data: ICommAutomobileOCEP28A[]
) => action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_SUCCESS, { data });
export const fetchCommAutomobileOcep28AListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_OCEP28A_LIST_FAILED);

export const fetchCommAutomobileOcep28AListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileOcep28AListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileOCEP28A[] = res.data.data;
      dispatch(fetchCommAutomobileOcep28AListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewCommAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-commauto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchCommAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchCommAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchCommAutomobileLiabilityInsuranceSuccess = (
  data: ICommAutomobileLiabilityInsurance
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchCommAutomobileLiabilityInsuranceFailed = (
  errorMessage: string
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addCommAutomobileLiabilityInsuranceAsync =
  (
    data: ICommAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-commauto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchCommAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: ICommAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchCommAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchCommAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE =
  "CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE";
export const clearCommAutomobileLiabiliyInsurance = () =>
  action(CLEAR_COMM_AUTOMOBILE_LIABILITY_INSURANCE);

export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS";
export const FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED =
  "FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED";

export const fetchCommAutomobileLiabilityInsuranceListProgress = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS);
export const fetchCommAutomobileLiabilityInsuranceListSuccess = (
  data: ICommAutomobileLiabilityInsurance[]
) => action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS, { data });
export const fetchCommAutomobileLiabilityInsuranceListFailed = () =>
  action(FETCH_COMM_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED);

export const fetchCommAutomobileLiabilityInsuranceListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCommAutomobileLiabilityInsuranceListProgress());
      const res = await api.get(
        `/policy/get-policy-commauto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: ICommAutomobileLiabilityInsurance[] = res.data.data;
      dispatch(fetchCommAutomobileLiabilityInsuranceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
// export const downloadCommAutomobileLiabilityInsurancePDFAsync =
// (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
// async (dispatch, getState) => {
//   try {
//     const res = await api.get(
//       `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,{
//         responseType: 'arraybuffer',
//       }
//     );

//     const pdfBuffer = res.data;

//     const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });

//     const pdfUrl = URL.createObjectURL(pdfBlob);

//     const link = document.createElement('a');
//     link.href = pdfUrl;
//     link.download = 'downloaded.pdf';
//     link.click();

//     URL.revokeObjectURL(pdfUrl);
//     onCallback();

//   } catch (err: any) {
//     dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
//     dispatch(
//       showMessage({
//         type: "error",
//         message: err.response.data.message,
//         displayAs: "snackbar",
//       })
//     );
//   }
// };

// export const previewCommAutomobileLiabilityInsurancePDFAsync =
// (customerPolicyId: number,incremental_number: number, onCallback: ()=> void): ThunkAction<void, IStoreState, {}, AnyAction> =>
// async (dispatch, getState) => {
//   try {
//     const res = await api.get(
//       `/policy/get-policy-commauto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
//     );

//     for (const key in res.data.data) {
//       const newTab = window.open("", "_blank");
//       if (newTab) {
//         newTab.document.write(res.data.data[key]);
//         newTab.document.close();
//       }
//     }
//     onCallback();

//   } catch (err: any) {
//     dispatch(fetchCommAutomobileLiabilityInsuranceListFailed());
//     dispatch(
//       showMessage({
//         type: "error",
//         message: err.response.data.message,
//         displayAs: "snackbar",
//       })
//     );
//   }
// };

export const FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE =
  "FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE";

export const clearCommAutoCertificatesState = () =>
  action(FETCH_COMM_AUTOMOBILE_CERTIFICATES_STATE);
