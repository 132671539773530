import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  ITransactionType,
  initialTransactionTypeState,
} from "./TransactionType.types";

export const FETCH_TRANSACTION_TYPE_FAILURE = "FETCH_TRANSACTION_TYPE_FAILURE";
export const FETCH_TRANSACTION_TYPE_PROGRESS =
  "FETCH_TRANSACTION_TYPE_PROGRESS";
export const FETCH_TRANSACTION_TYPE_SUCCESS = "FETCH_TRANSACTION_TYPE_SUCCESS";

export const fetchTransactionTypeProgress = () =>
  action(FETCH_TRANSACTION_TYPE_PROGRESS);
export const fetchTransactionTypeSuccess = (data: ITransactionType) =>
  action(FETCH_TRANSACTION_TYPE_SUCCESS, { data });
export const fetchTransactionTypeFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_TYPE_FAILURE, { errorMessage });

export const fetchAccountingTransactionTypeAsync =
  (TransactionTypeId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionTypeProgress());

      const res = await api.get(
        `/accounting/get-account-transaction-type?transaction_type_id=${TransactionTypeId}`
      );
      const data: ITransactionType[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTransactionTypeSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(fetchTransactionTypeFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingTransactionTypeAysnc =
  (
    data: ITransactionType,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-transaction-type", {
        ...data,
      });
      let message = "Transaction saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_TRANSACTION_TYPE_LIST_PROGRESS =
  "FETCH_TRANSACTION_TYPE_LIST_PROGRESS";
export const FETCH_TRANSACTION_TYPE_LIST_SUCCESS =
  "FETCH_TRANSACTION_TYPE_LIST_SUCCESS";
export const FETCH_TRANSACTION_TYPE_LIST_FAILURE =
  "FETCH_TRANSACTION_TYPE_LIST_FAILURE";

export const fetchTransactionTypeListProgress = () =>
  action(FETCH_TRANSACTION_TYPE_LIST_PROGRESS);
export const fetchTransactionTypeListSuccess = (data: ITransactionType[]) =>
  action(FETCH_TRANSACTION_TYPE_LIST_SUCCESS, { data });
export const fetchTransactionTypeListFailure = (errorMessage: string) =>
  action(FETCH_TRANSACTION_TYPE_LIST_FAILURE, { errorMessage });

export const fetchAccountingTransactionTypeListAsync = (): ThunkAction<
  void,
  IStoreState,
  {},
  AnyAction
> => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchTransactionTypeListProgress());
      const res = await api.get("/accounting/get-account-transaction-type");
      const data: ITransactionType[] = res.data.data;
      dispatch(fetchTransactionTypeListSuccess(data));
    } catch (err: any) {
      dispatch(fetchTransactionTypeListFailure("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
};

export const CLEAR_TRANSACTION_TYPE = "CLEAR_TRANSACTION_TYPE";
export const clearTransactionType = () => action(CLEAR_TRANSACTION_TYPE);
