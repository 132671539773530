import moment, { Moment } from "moment";
import { LoadState, POLICIES } from "../../../constants/enums";

export interface IBusinessBasicDetailsState {
  customerInfo: {
    data: ICustomerInfo;
    loading: LoadState;
    error: string | null;
  };
  priorPolicy: {
    data: IPriorPolicy;
    loading: LoadState;
    error: string | null;
  };
  policyAdmin: {
    data: IPolicyAdmin;
    loading: LoadState;
    error: string | null;
  };
  policyAdminManager: {
    data: IPolicyAdminManager;
    loading: LoadState;
    error: string | null;
  };
  claim: {
    data: IClaims[];
    loading: LoadState;
    error: string | null;
  };
  premium: {
    data: IPremium;
    loading: LoadState;
    error: string | null;
  };
  attachment: {
    list: IBusinessAttachment[];
    listLoading: LoadState;
    data: IBusinessAttachment;
    loading: LoadState;
    error: string | null;
  };

  customerPoliycId?: number | null;
}

export interface IBusinessAttachment {
  customer_policy_id: number | null;
  serial_number: number | null;
  document_name: string | null;
  file_upload: string | null;
  description: string | null;
  send_attachment: number;
  status: string;
}
export interface ICustomerInfo {
  policy_id: number;
  customer_id: number;
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  form_of_business: string | number | null;
  language: string | null;
  line_of_business: string | null;
  sub_type: string | null;
  effective_date: string;
  binder_number: string | null;
  expiry_date: string;
  segment: string[];
  transaction_type: string | null;
  residential_or_business: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  policy_status: string | null;
  date_cancelled: string | null;
  cancellation_reason: string | null;
  cancellation_type: string | null;
  program: string | null;
  type_of_policy: string | null;
  quote_number: string | null;
  policy_number: string | null;
  effective_time: Moment;
  send_invoices_renewal_and_receivacble_letter_via_emails: boolean | null;
  send_statements_via_email: boolean | null;
  send_eDocs_via_email: boolean | null;
  status: string;
}

export interface IPriorPolicy {
  customer_policy_id: number;
  line_of_business: string | null;
  policy_expiry_date: string | null;
  current_insurer: string | null;
  expiring_premium: string | null;
  current_status: string | null;
  non_payment_code: string | null;
  non_payment_date: string | null;
  insurer: string | null;
  amount_owing: string | null;
}

export interface IPolicyAdmin {
  customer_policy_id: number;
  insurer: string | null;
  broker_code: string | null;
  comission_code: string | null;
  policy_fee: string | null;
  branch_code: string | null;
  producer_1: string | null;
  producer_1_code: string | null;
  producer_2: string | null;
  producer_2_code: string | null;
  producer_3: string | null;
  producer_3_code: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  billing_account_number: string | null;
}
export interface IPolicyAdminManager {
  customer_policy_id: number;
  insurer: string | null;
  broker_code: string | null;
  comission_code: string | null;
  policy_fee: string | null;
  house_percentage: string | null;
  branch_code: string | null;
  producer_1: string | null;
  producer_1_code: string | null;
  producer_1_percentage: string | null;
  producer_2: string | null;
  producer_2_code: string | null;
  producer_2_percentage: string | null;
  producer_3: string | null;
  producer_3_code: string | null;
  producer_3_percentage: string | null;
  marketer: string | null;
  marketer_code: string | null;
  csr: string | null;
  csr_code: string | null;
  billing_account_number: string | null;
}

export interface IClaims {
  customer_policy_id: number;
  claim_number: string | null;
  date_of_loss: string | null;
  time_of_loss: Moment;
  value_of_loss: string | null;
  risk_location: string | null;
  amount_paid_dollar: string | null;
  loss_details: string | null;
  ours: string | null;
  status: string;
  producer: string | null;
  branch: string | null;
  adjuster: string | null;
  witness: string | null;
  witness_phone: string | null;
  witness_name: string | null;
  loss_reported_to_police: string | null;
  report_number: string | null;
  phone: string | null;
  phone_ext: string | null;
  mobile: string | null;
  email: string | null;
  company_name: string | null;
  at_fault: string | null;
  claim_status: string | null;
  third_party_liability: string | null;
  bodily_injury: string | null;
  accidents_benefits: string | null;
  dcpd: string | null;
  collision: string | null;
  comprehensive: string | null;
  reserves: string | null;
  cgl: string | null;
  stock: string | null;
  crime: string | null;
  equipment_breakdown: string | null;
  business_interruption: string | null;
  other: string | null;
  total_claim: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  contact_phone_ext: string | null;
  contact_mobile: string | null;
  contact_email: string | null;
  preferred_contact_method: string | null;
}

export interface IPremium {
  customer_policy_id: number;
  policy_premium: string | null;
  agency_bill_or_direct_bill: string | null;
  payment_plan: string | null;
  premium_finance_contract_number: string | null;
  financed_by: string | null;
  default_tax_region: string | null;
  tax_1: string | null;
  tax_2: string | null;
  tax_exempt: string | null;
  reason: string | null;
}

export const initialBasicDetails: IBusinessBasicDetailsState = {
  customerInfo: {
    data: {
      customer_name: "",
      policy_id: 0,
      customer_id: 0,
      customer_policy_id: null,
      unit_or_suite: null,
      customer_code: null,
      form_of_business: null,
      language: null,
      line_of_business: POLICIES.BUSINESS_POLICY,
      sub_type: null,
      effective_date: moment().format("YYYY-MM-DD"),
      expiry_date: moment().add(1, "year").format("YYYY-MM-DD"),
      transaction_type: null,
      segment: [],
      residential_or_business: "",
      address: "",
      city: "",
      province_or_state: "",
      country: "",
      postal_code: "",
      policy_status: "Active",
      date_cancelled: null,
      cancellation_reason: null,
      cancellation_type: null,
      program: null,
      type_of_policy: null,
      quote_number: null,
      policy_number: null,
      binder_number: null,
      effective_time: moment().startOf("day").add(1, "minute"),
      send_invoices_renewal_and_receivacble_letter_via_emails: null,
      send_statements_via_email: null,
      send_eDocs_via_email: null,
      status: "ACTIVE",
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  priorPolicy: {
    data: {
      customer_policy_id: 0,
      line_of_business: null,
      policy_expiry_date: null,
      current_insurer: null,
      expiring_premium: null,
      current_status: null,
      non_payment_code: null,
      non_payment_date: null,
      insurer: null,
      amount_owing: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  policyAdmin: {
    data: {
      customer_policy_id: 0,
      insurer: null,
      broker_code: null,
      comission_code: null,
      policy_fee: null,
      branch_code: null,
      producer_1: null,
      producer_1_code: null,
      producer_2: null,
      producer_2_code: null,
      producer_3: null,
      producer_3_code: null,
      marketer: null,
      marketer_code: null,
      csr: null,
      csr_code: null,
      billing_account_number: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  policyAdminManager: {
    data: {
      customer_policy_id: 0,
      insurer: null,
      broker_code: null,
      comission_code: null,
      policy_fee: null,
      house_percentage: null,
      branch_code: null,
      producer_1: null,
      producer_1_code: null,
      producer_1_percentage: null,
      producer_2: null,
      producer_2_code: null,
      producer_2_percentage: null,
      producer_3: null,
      producer_3_code: null,
      producer_3_percentage: null,
      marketer: null,
      marketer_code: null,
      csr: null,
      csr_code: null,
      billing_account_number: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  claim: {
    data: [],
    loading: LoadState.NotLoaded,
    error: null,
  },
  premium: {
    data: {
      customer_policy_id: 0,
      policy_premium: null,
      agency_bill_or_direct_bill: null,
      payment_plan: null,
      premium_finance_contract_number: null,
      financed_by: null,
      default_tax_region: "ON",
      tax_1: null,
      tax_2: null,
      tax_exempt: null,
      reason: null,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },
  attachment: {
    list: [],
    listLoading: LoadState.NotLoaded,
    data: {
      customer_policy_id: null,
      serial_number: null,
      document_name: null,
      file_upload: null,
      description: null,
      status: "ACTIVE",
      send_attachment: 0,
    },
    loading: LoadState.NotLoaded,
    error: null,
  },

  customerPoliycId: null,
};
