/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StandadCard } from "../../../../../components/Cards";
import {
  AccountCircle,
  AdminPanelSettings,
  StickyNote2,
  StickyNote2Outlined,
} from "@mui/icons-material";
import { TabPanel } from "../../../../../components/Tabs/TabPanel";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../constants/enums";
import { useDispatchWrapper, useQueryParms } from "../../../../../hooks";
import { TabsList } from "../../../../../components/Tabs/TabsList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useBreadcrumbContext } from "../../../../../contexts/BreadcrumbProvider/BreadcrumbProvider";
import { fetchLifeInsuranceCustomerInfoAsync } from "../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { LifeInsurancePolicies } from "./LifeInsurancePolicies/Policy/Policy";
import { LifeInsuranceComplianceCheckList } from "./LifeInsurancePolicies/ComplieanceChecklist/ComplieanceChecklist";
import { LifeInsuranceCoverageNeed } from "./LifeInsurancePolicies/CoverageNeed/LifeInsuranceCoverageNeed";
import { ExistingPropertyCasualty } from "./LifeInsuranceAdditionalInformation/ExistingPropertyCasuality/ExistingPropertyCasuality";
import { LifeInsuranceFamilyTreeList } from "./LifeInsuranceAdditionalInformation/FamilyTreeExisting/FamilyTreeList";

export const LifeInsuranceAdditionalInformationRoot: React.FC = () => {
 
  const data= useOutletContext() as any;
  const customerId = data[0];
  const customerPolicyId = data[1];
  const {addBreadcrumb} = useBreadcrumbContext();
  const {getParm} = useQueryParms();
  const tabValue = getParm('tab');
  const selectedTab = tabValue ? Number(tabValue) : 0;
  
  const { customerInfo } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails
  );
  const navigate = useNavigate();
  

  const dispatch = useDispatchWrapper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
   
    navigate(
      `/life-insurance-policy/${customerId}/additional-information-tab/${customerPolicyId}?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    if (customerPolicyId) {
      dispatch(fetchLifeInsuranceCustomerInfoAsync(customerPolicyId));
    }
  }, [customerPolicyId]);

  React.useEffect(()=>{
    addBreadcrumb({title: "", items:  [
      {
        to: "/dashboard",
        title: "dashboard",
      },
      {
        to: "/view-customers",
        title: "customers",
      },
      {
        to: "/customer-overview/" + customerId,
        title: "customer-overview",
      },
      {
        to: "/life-insurance-policy/life-insurance/" + customerId + "/" + customerPolicyId,
        title: "additional-information",
      },
    
    ]})
  },[])



  return (
    <PageLoader
      loading={customerInfo.Loading === LoadState.InProgress}
      error={customerInfo.error ? { message: customerInfo.error } : null}
    >
      <StandadCard>
        <TabsList
          sx={{ mb: 1 }}
          varaint="fullWidth"
          selectedTab={selectedTab}
          tabs={[
            { label: "Existing Property", icon: <AccountCircle /> },
            {
              label: "Family Tree",
              icon: <AdminPanelSettings />,
            },
          ]}
          onChange={handleChange}
        />
        <TabPanel value={selectedTab} index={0}>
          <ExistingPropertyCasualty
            // customerId={customerId}
            customerpolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <LifeInsuranceFamilyTreeList
            customerId={customerId}
            customerPolicyId={customerPolicyId}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <LifeInsuranceCoverageNeed
            // customerId={customerId}
            // customerpolicyId={customerPolicyId}
          />
        </TabPanel>
      </StandadCard>
    </PageLoader>
  );
};
