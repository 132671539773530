import { Avatar, Box, Button, Grid, Link } from "@mui/material";
import React from "react";
import {
  useDispatchWrapper,
  useIsLifeInsuranceNewPolicy,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { fetchClaimsAsync } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetailsActions";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../../../../constants/enums";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { IClaims } from "../../../../../../../redux/businessPolicy/businessPolicyBasicDetails/businessPolicyBasicDetails.types";
import { AppRegistration, AspectRatio } from "@mui/icons-material";
import { ContextMenu } from "../../../../../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ILifeInsurancePolicyBasicDetailsProps } from "../LifeInsuranceBasicDetails.types";
import { LifeInsuranceAddClaimDialog } from "./AddClaimDialog";
import { ViewLifeInsurerClaimDialog } from "./ViewClaimDialog";
import { fetchLifeInsuranceClaimsAsync } from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetailActions";
import { ILifeInsuranceClaims } from "../../../../../../../redux/lifeInsurancePolicy/lifeInsuranceBasicDetail/lifeInsuranceBasicDetail.types";
import { StatusRenderer } from "../../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { useOutletContext } from "react-router-dom";

const INITIAL_STATE = {
  customer_policy_id: 0,
  serial_number: 0,
  claim_number: null,
  date_of_loss: null,
  risk_location: null,
  amount_paid_dollar: null,
  ours: null,
  type: null,
  producer: null,
  branch: null,
  amount_paid: null,
  loss_details: null,
  status: null,
};

export const LifeInsurerClaim: React.FC = () => {
  const data2 = useOutletContext() as any;
  const customerpolicyId = data2[1];
  const isNewPolicy = useIsLifeInsuranceNewPolicy();
  const { data, Loading } = useSelector(
    (storeState: IStoreState) => storeState.lifeInsurance.basicDetails.claim
  );
  const [openClaim, setOpenClaim] = React.useState<ILifeInsuranceClaims | null>(
    null
  );
  const [openView, setOpenView] = React.useState<ILifeInsuranceClaims | null>(
    null
  );
  const dispatch = useDispatchWrapper();

  const claimTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: Loading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() =>
                  setOpenClaim({
                    ...INITIAL_STATE,
                    customer_policy_id: customerpolicyId,
                  })
                }
              >
                Add New Claim
              </Button>
            ),
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "serial_number",
        headerName: "#",
        fieldName: "serial_number",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (values, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "claim_number",
        headerName: "Claim Number",
        fieldName: "claim_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: ILifeInsuranceClaims) => {
          return (
            <Link sx={{ cursor: "pointer" }} onClick={() => setOpenClaim(row)}>
              {row.claim_number}
            </Link>
          );
        },
      },

      {
        key: "date_of_loss",
        headerName: "Date of Loss",
        fieldName: "date_of_loss",
        renderType: RenderType.DATE,
        exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "branch",
        headerName: "Branch",
        fieldName: "branch",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "producer",
        headerName: "Producer",
        fieldName: "producer",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },
      {
        key: "amount_paid_dollar",
        headerName: "Amount Paid ($)",
        fieldName: "amount_paid_dollar",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "loss_details",
        headerName: "Loss Details",
        fieldName: "loss_details",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ILifeInsuranceClaims) => {
          return (
            <StandardTableActions
              onViewClick={() => setOpenView(row)}
              onEditClick={() => setOpenClaim(row)}
              // more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],
    items: data,
  };

  React.useEffect(() => {
    if (!isNewPolicy) {
      dispatch(fetchLifeInsuranceClaimsAsync(customerpolicyId));
    }
  }, []);

  return (
    <>
      <PageLoader loading={Loading === LoadState.InProgress}>
        <Box sx={{ mt: 2 }}>
          <DataTable {...claimTableProps} />
        </Box>
      </PageLoader>
      {openClaim && (
        <LifeInsuranceAddClaimDialog
          open={true}
          claim={openClaim}
          onClose={() => setOpenClaim(null)}
        />
      )}
      {openView && (
        <ViewLifeInsurerClaimDialog
          open={true}
          claim={openView}
          onClose={() => setOpenView(null)}
        />
      )}
    </>
  );
};
