import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
export interface ITaskLessorState {
  addLessor: {
    data: IAddLessor;
    loading: LoadState;
  };
  deleteLessor: {
    data: IDeleteLessor;
    loading: LoadState;
  };
  changeLessor: {
    data: IChangeLessor;
    loading: LoadState;
  }
}

export interface IAddLessor {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  type: string | null;
  name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: Moment;
  details: string | null;
  file_upload: string | null;
}

export interface IDeleteLessor {
  task_code: string,
  customer_code: string | null,
  customer_name: string | null,
  policy_number: number | null,
  line_of_business: string | null,
  policy_type: string | null,
  branch: string | null,
  broker_code: string | null,
  insurer: string | null,
  producer: string | null,
  csr: string | null,
  interested_party_code: string | null,
  effective_date_of_change: string | null,
  effective_time_of_change: Moment,
  details: string | null,
  file_upload: string | null
}

export interface IChangeLessor {
  task_code: string;
  customer_code: string | null;
  customer_name: string | null;
  policy_number: number | null;
  line_of_business: string | null;
  policy_type: string | null;
  branch: string | null;
  broker_code: string | null;
  insurer: string | null;
  producer: string | null;
  csr: string | null;
  interested_party_code: string | null;
  type: string | null;
  name: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  effective_date_of_change: string | null;
  effective_time_of_change: Moment;
  details: string | null;
  file_upload: string | null;
}

export const initialTaskLessorState: ITaskLessorState = {
  addLessor: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      type: null,
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute"),
      details: null,
      file_upload: null,
    },
    loading: LoadState.Loaded,
  },
  deleteLessor: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute"),
      details: null,
      file_upload: null
    },
    loading: LoadState.Loaded,
  },
  changeLessor: {
    data: {
      task_code: "",
      customer_code: null,
      customer_name: null,
      policy_number: null,
      line_of_business: null,
      policy_type: null,
      branch: null,
      broker_code: null,
      insurer: null,
      producer: null,
      csr: null,
      interested_party_code: null,
      type: null,
      name: null,
      address: null,
      unit_or_suite: null,
      city: null,
      province_or_state: null,
      postal_code: null,
      country: null,
      effective_date_of_change: null,
      effective_time_of_change: moment()
      .startOf("day")
      .add(1, "minute"),
      details: null,
      file_upload: null,
    },
    loading: LoadState.Loaded,
  },
};
