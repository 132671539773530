import { action } from "typesafe-actions";
import {
  IAutomobileLiabilityInsurance,
  IAutomobileLiabilitySlips,
  IAutomobileOCEP16,
  IAutomobileOCEP17,
  IAutomobileOCEP28A,
} from "./automobileCertificates.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED";

export const fetchAutomobileLiabilitySlipProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_PROGRESS);
export const fetchAutomobileLiabilitySlipSuccess = (
  data: IAutomobileLiabilitySlips
) => action(FETCH_AUTOMOBILE_LIABILITY_SLIP_SUCCESS, { data });
export const fetchAutomobileLiabilitySlipFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIP_FAILED, { errorMessage });

export const addAutomobileLiabilitySlipAsync =
  (
    data: IAutomobileLiabilitySlips,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-liability-slip-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability slip saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileLiabilityAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilitySlipProgress());
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilitySlips[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilitySlipSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.POLICY_NOT_FOUND)
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_LIABILITY_SLIP =
  "CLEAR_AUTOMOBILE_LIABILITY_SLIP";
export const clearAutomobileLiabiliySlip = () =>
  action(CLEAR_AUTOMOBILE_LIABILITY_SLIP);

export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED";

export const fetchAutomobileLiabilitySlipsListProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_PROGRESS);
export const fetchAutomobileLiabilitySlipsListSuccess = (
  data: IAutomobileLiabilitySlips[]
) => action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_SUCCESS, { data });
export const fetchAutomobileLiabilitySlipsListFailed = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_SLIPS_LIST_FAILED);

export const fetchAutomobileLiabilitySlipsListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilitySlipsListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileLiabilitySlips[] = res.data.data;
      dispatch(fetchAutomobileLiabilitySlipsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const downloadAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileLiabilitySlipsListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-liability-slip-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_liability_slip_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilitySlipsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_OCEP16_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP16_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP16_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP16_FAILED = "FETCH_AUTOMOBILE_OCEP16_FAILED";

export const fetchAutomobileOcep16rogress = () =>
  action(FETCH_AUTOMOBILE_OCEP16_PROGRESS);
export const fetchAutomobileOcep16Success = (data: IAutomobileOCEP16) =>
  action(FETCH_AUTOMOBILE_OCEP16_SUCCESS, { data });
export const fetchAutomobileOcep16Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP16_FAILED, { errorMessage });

export const addAutomobileOcep16sync =
  (
    data: IAutomobileOCEP16,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-sixteen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF16 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep16Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep16rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileOCEP16[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep16Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP16 = "CLEAR_AUTOMOBILE_OCEP16";
export const clearAutomobileOcep16 = () => action(CLEAR_AUTOMOBILE_OCEP16);

export const FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP16_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP16_LIST_FAILED";

export const fetchAutomobileOcep16ListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_PROGRESS);
export const fetchAutomobileOcep16ListSuccess = (data: IAutomobileOCEP16[]) =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_SUCCESS, { data });
export const fetchAutomobileOcep16ListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP16_LIST_FAILED);

export const fetchAutomobileOcep16ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep16ListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP16[] = res.data.data;
      dispatch(fetchAutomobileOcep16ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep16ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-sixteen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_sixteen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep16ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const FETCH_AUTOMOBILE_OCEP17_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP17_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP17_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP17_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP17_FAILED = "FETCH_AUTOMOBILE_OCEP17_FAILED";

export const fetchAutomobileOcep17rogress = () =>
  action(FETCH_AUTOMOBILE_OCEP17_PROGRESS);
export const fetchAutomobileOcep17Success = (data: IAutomobileOCEP17) =>
  action(FETCH_AUTOMOBILE_OCEP17_SUCCESS, { data });
export const fetchAutomobileOcep17Failed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP17_FAILED, { errorMessage });

export const addAutomobileOcep17sync =
  (
    data: IAutomobileOCEP17,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
          incremental_number: null,
        });
      } else {
        await api.post("/policy/edit-policy-auto-opcf-seventeen-certificate", {
          ...data,
        });
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF17 saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep17Async =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep17rogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileOCEP17[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep17Success({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17Failed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP17 = "CLEAR_AUTOMOBILE_OCEP17";
export const clearAutomobileOcep17 = () => action(CLEAR_AUTOMOBILE_OCEP17);

export const FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP17_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP17_LIST_FAILED";

export const fetchAutomobileOcep17ListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_PROGRESS);
export const fetchAutomobileOcep17ListSuccess = (data: IAutomobileOCEP17[]) =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_SUCCESS, { data });
export const fetchAutomobileOcep17ListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP17_LIST_FAILED);

export const fetchAutomobileOcep17ListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep17ListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP17[] = res.data.data;
      dispatch(fetchAutomobileOcep17ListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep17ListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-seventeen-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_seventeen_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep17ListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_OCEP28A_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP28A_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP28A_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP28A_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP28A_FAILED =
  "FETCH_AUTOMOBILE_OCEP28A_FAILED";

export const fetchAutomobileOcep28Arogress = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_PROGRESS);
export const fetchAutomobileOcep28ASuccess = (data: IAutomobileOCEP28A) =>
  action(FETCH_AUTOMOBILE_OCEP28A_SUCCESS, { data });
export const fetchAutomobileOcep28AFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_OCEP28A_FAILED, { errorMessage });

export const addAutomobileOcep28Async =
  (
    data: IAutomobileOCEP28A,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-opcf-twenty-eight-a-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "OPCF28A saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileOcep28AAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep28Arogress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileOCEP28A[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileOcep28ASuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.POLICY_NOT_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_OCEP28A = "CLEAR_AUTOMOBILE_OCEP28A";
export const clearAutomobileOcep28A = () => action(CLEAR_AUTOMOBILE_OCEP28A);

export const FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED =
  "FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED";

export const fetchAutomobileOcep28AListProgress = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_PROGRESS);
export const fetchAutomobileOcep28AListSuccess = (data: IAutomobileOCEP28A[]) =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_SUCCESS, { data });
export const fetchAutomobileOcep28AListFailed = () =>
  action(FETCH_AUTOMOBILE_OCEP28A_LIST_FAILED);

export const fetchAutomobileOcep28AListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileOcep28AListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileOCEP28A[] = res.data.data;
      dispatch(fetchAutomobileOcep28AListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
export const downloadAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}`,
        {
          responseType: "arraybuffer",
        }
      );

      const pdfBuffer = res.data;

      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "downloaded.pdf";
      link.click();

      URL.revokeObjectURL(pdfUrl);
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const previewAutomobileOcep28AListPDFAsync =
  (
    customerPolicyId: number,
    incremental_number: number,
    onCallback: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/policy/get-policy-auto-opcf-twenty-eight-a-certificate-pdf?customer_policy_id=${customerPolicyId}&incremental_number=${incremental_number}&is_opcf_twenty_eight_a_certificate_preview=true`
      );

      for (const key in res.data.data) {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.document.write(res.data.data[key]);
          newTab.document.close();
        }
      }
      onCallback();
    } catch (err: any) {
      dispatch(fetchAutomobileOcep28AListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED";

export const fetchAutomobileLiabilityInsuranceProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_PROGRESS);
export const fetchAutomobileLiabilityInsuranceSuccess = (
  data: IAutomobileLiabilityInsurance
) => action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_SUCCESS, { data });
export const fetchAutomobileLiabilityInsuranceFailed = (errorMessage: string) =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_FAILED, { errorMessage });

export const addAutomobileLiabilityInsuranceAsync =
  (
    data: IAutomobileLiabilityInsurance,
    onCallback: (isSuccess: boolean) => void,
    isDuplicate?: boolean
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      if (isDuplicate) {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
            incremental_number: null,
          }
        );
      } else {
        await api.post(
          "/policy/edit-policy-auto-add-liability-insurance-certificate",
          {
            ...data,
          }
        );
      }

      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Liability insurance saved successfully!",
          displayAs: "snackbar",
        })
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const fetchAutomobileLiabilityInsuranceAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilityInsuranceProgress());
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}&column=incremental_number&value=${incremental_number}`
      );
      const data: IAutomobileLiabilityInsurance[] = res.data.data;
      if (data.length > 0) {
        dispatch(
          fetchAutomobileLiabilityInsuranceSuccess({
            ...data[0],
          })
        );
      } else {
        dispatch(
          fetchAutomobileLiabilityInsuranceFailed(
            ERROR_MESSAGES.POLICY_NOT_FOUND
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchAutomobileLiabilityInsuranceFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_LIABILITY_INSURANCE =
  "CLEAR_AUTOMOBILE_LIABILITY_INSURANCE";
export const clearAutomobileLiabiliyInsurance = () =>
  action(CLEAR_AUTOMOBILE_LIABILITY_INSURANCE);

export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED =
  "FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED";

export const fetchAutomobileLiabilityInsuranceListProgress = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_PROGRESS);
export const fetchAutomobileLiabilityInsuranceListSuccess = (
  data: IAutomobileLiabilityInsurance[]
) => action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_SUCCESS, { data });
export const fetchAutomobileLiabilityInsuranceListFailed = () =>
  action(FETCH_AUTOMOBILE_LIABILITY_INSURANCE_LIST_FAILED);

export const fetchAutomobileLiabilityInsuranceListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileLiabilityInsuranceListProgress());
      const res = await api.get(
        `/policy/get-policy-auto-add-liability-insurance-certificate?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileLiabilityInsurance[] = res.data.data;
      dispatch(fetchAutomobileLiabilityInsuranceListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileLiabilityInsuranceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };
