import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import {
  IAutomobileApplication,
  initialAutomobileApplicationState,
} from "./automobileApplication.types";

export const FETCH_AUTOMOBILE_APPLICATION_PROGRESS =
  "FETCH_AUTOMOBILE_APPLICATION_PROGRESS";
export const FETCH_AUTOMOBILE_APPLICATION_SUCCESSS =
  "FETCH_AUTOMOBILE_APPLICATION_SUCCESSS";
export const FETCH_AUTOMOBILE_APPLICATION_FAILED =
  "FETCH_AUTOMOBILE_APPLICATION_FAILED";

export const fetchAutomobileApplicationProgress = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_PROGRESS);
export const fetchAutomobileApplicationSuccess = (
  data: IAutomobileApplication
) => action(FETCH_AUTOMOBILE_APPLICATION_SUCCESSS, { data });
export const fetchAutomobileApplicationFailed = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_FAILED);

export const fetchAutomobileApplicationAsync =
  (
    customerPolicyId: number,
    incremental_number: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileApplicationProgress());
      const res = await api.get(
        `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}&value=${incremental_number}`
      );
      const data: IAutomobileApplication[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAutomobileApplicationSuccess(data[0]));
      } else {
        dispatch(
          fetchAutomobileApplicationSuccess(
            initialAutomobileApplicationState["application"]
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchAutomobileApplicationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAutomobileApllication =
  (
    data: IAutomobileApplication,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress())
      await api.post("/policy/edit-auto-automobile-application", {
        ...data,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Application saved successfully!",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchAutomobileApplicationSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
    finally {
      dispatch(saveLoaderCompleted())
    }
  };

export const FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS";
export const FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS";
export const FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED =
  "FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED";

export const fetchAutomobileApplicationListProgress = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_PROGRESS);

export const fetchAutomobileApplicationsListSuccess = (
  data: IAutomobileApplication[]
) =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_SUCCESS, {
    data,
  });
export const fetchAutomobileApplicationListFailed = () =>
  action(FETCH_AUTOMOBILE_APPLICATION_LIST_FAILED);

export const fetchAutomobileApplicationListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAutomobileApplicationListProgress());
      const res = await api.get(
        `/policy/get-auto-automobile-application?customer_policy_id=${customerPolicyId}`
      );
      const data: IAutomobileApplication[] = res.data.data;
      dispatch(fetchAutomobileApplicationsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAutomobileApplicationListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_AUTOMOBILE_APPLICATION_STATE =
  "CLEAR_AUTOMOBILE_APPLICATION_STATE";

export const clearAutomobileApplicationState = () =>
  action(CLEAR_AUTOMOBILE_APPLICATION_STATE);
