import { Grid, Stack, Typography } from "@mui/material";
import { StandardCellList } from "../../../../components/Standards";
import React from "react";
import { fetchTaskGeneralChangeAysnc } from "../../../../redux/generalChange/generalChangeActions";
import { useDispatchWrapper } from "../../../../hooks";
import { IGeneralChange } from "../../../../redux/generalChange/generalChange.types";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { formatTime } from "../../../../helpers";
import { PageLoader } from "../../../../components/PageLoader/PageLoader";
import { LoadState } from "../../../../constants/enums";
import { ITaskFileUploadAttachment } from "../../../../redux/tasks/task.types";
import { FileUpload } from "../../../../components/FileUpload/FileUpload";
import { StandadCard } from "../../../../components/Cards";

export const ViewGeneralChange: React.FC<{
  taskCode: string;
  attachment: ITaskFileUploadAttachment[];
}> = (props) => {
  const { taskCode, attachment } = props;
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.tasks.generalChange.generalChange
  );
  const dispatch = useDispatchWrapper();
  const [taskDetails, setTaskDetails] = React.useState<IGeneralChange>();

  React.useEffect(() => {
    dispatch(fetchTaskGeneralChangeAysnc(taskCode));
  }, [taskCode]);

  React.useEffect(() => {
    if (data) {
      setTaskDetails(data);
    }
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      {taskDetails && (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Customer Information"
              colSize={3}
              list={[
                {
                  heading: "Policy Number",
                  data: {
                    type: "text",
                    value: taskDetails.policy_number,
                  },
                },
                {
                  heading: "Customer Code",
                  data: {
                    type: "text",
                    value: taskDetails.customer_code,
                  },
                },
                {
                  heading: "Customer Name",
                  data: {
                    type: "text",
                    value: taskDetails.customer_name,
                  },
                },
                {
                  heading: "Line of Business",
                  data: { type: "text", value: taskDetails.line_of_business },
                },

                {
                  heading: "Policy Type",
                  data: { type: "text", value: taskDetails.policy_type },
                },
                {
                  heading: "Branch",
                  data: {
                    type: "text",
                    value: taskDetails.branch,
                  },
                },
                {
                  heading: "Broker Code",
                  data: {
                    type: "text",
                    value: taskDetails.broker_code,
                  },
                },
                {
                  heading: "Insurer",
                  data: {
                    type: "text",
                    value: taskDetails.insurer,
                  },
                },
                {
                  heading: "Producer",
                  data: {
                    type: "text",
                    value: taskDetails.producer,
                  },
                },
                {
                  heading: "CSR",
                  data: {
                    type: "text",
                    value: taskDetails.csr,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandardCellList
              heading="Coverage Change Information"
              colSize={3}
              list={[
                {
                  heading: "Effective Date",
                  data: {
                    type: "text",
                    value: taskDetails.effective_date_of_change,
                  },
                },
                {
                  heading: "Effective Time",
                  data: {
                    type: "text",
                    value: formatTime(
                      taskDetails.effective_time_of_change || ""
                    ),
                  },
                },
                {
                  heading: "Details",
                  data: {
                    type: "text",
                    value: taskDetails.details,
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StandadCard>
              <Typography
                variant="h2"
                fontWeight={600}
                color="primary"
                marginBottom={2}
              >
                {"Attachments"}
              </Typography>
              {attachment.length > 0 ? (
                attachment.map((item) => {
                  return (
                    <Stack
                      key={item.key}
                      direction={"row"}
                      justifyContent={"center"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Typography variant="h4" fontWeight={"bold"}>
                        {" "}
                        {item.fileName}{" "}
                      </Typography>
                      <FileUpload value={item.url} />
                    </Stack>
                  );
                })
              ) : (
                <Typography variant="body1">No attachments</Typography>
              )}
            </StandadCard>
          </Grid>
        </Grid>
      )}
    </PageLoader>
  );
};
