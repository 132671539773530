import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IAccountingBill, initialAccountingBillState } from "./Bill.types";

export const FETCH_ACCOUNTING_BILL_PROGRESS = "FETCH_ACCOUNTING_BILL_PROGRESS";
export const FETCH_ACCOUNTING_BILL_SUCCESS = "FETCH_ACCOUNTING_BILL_SUCCESS";
export const FETCH_ACCOUNTING_BILL_FAILURE = "FETCH_ACCOUNTING_BILL_FAILURE";

export const fetchAccountingBillProgress = () =>
  action(FETCH_ACCOUNTING_BILL_PROGRESS);

export const fetchAccountingBillSuccess = (data: IAccountingBill) =>
  action(FETCH_ACCOUNTING_BILL_SUCCESS, { data });

export const fetchAccountingBillFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BILL_FAILURE, { errorMessage });

export const fetchAccountingBillAsync =
  (billId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillProgress());

      const res = await api.get(
        `/accounting/get-account-create-bill?column=bill_id&value=${billId}`
      );
      const data: IAccountingBill[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchAccountingBillSuccess(data[0]));
      }
    } catch (err: any) {
      dispatch(fetchAccountingBillFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addAccountingBillAysnc =
  (
    data: IAccountingBill,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/accounting/edit-account-create-bill", {
        ...data,
      });
      let message = "Bill saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ACCOUNTING_BILL_LIST_PROGRESS =
  "FETCH_ACCOUNTING_BILL_LIST_PROGRESS";
export const FETCH_ACCOUNTING_BILL_LIST_SUCCESS =
  "FETCH_ACCOUNTING_BILL_LIST_SUCCESS";
export const FETCH_ACCOUNTING_BILL_LIST_FAILURE =
  "FETCH_ACCOUNTING_BILL_LIST_FAILURE";

export const fetchAccountingBillListProgress = () =>
  action(FETCH_ACCOUNTING_BILL_LIST_PROGRESS);

export const fetchAccountingBillListSuccess = (data: IAccountingBill[]) =>
  action(FETCH_ACCOUNTING_BILL_LIST_SUCCESS, { data });

export const fetchAccountingBillListFailed = (errorMessage: string) =>
  action(FETCH_ACCOUNTING_BILL_LIST_FAILURE, { errorMessage });

export const fetchAccountingBillListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAccountingBillListProgress());

      const res = await api.get(`/accounting/get-account-create-bill`);
      const data: IAccountingBill[] = res.data.data;
      dispatch(fetchAccountingBillListSuccess(data));
    } catch (err: any) {
      dispatch(fetchAccountingBillListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_ACCOUNTING_BILL = "CLEAR_ACCOUNTING_BILL";
export const clearAccountingBill = () => action(CLEAR_ACCOUNTING_BILL);
