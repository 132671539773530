import moment from "moment";
import { LoadState } from "../../constants/enums";
import { STANDARD_APP_DATE_FORMAT } from "../../constants/constants";

export interface ITaskState {
  list: ITask[];
  totalRecords: number;
  loading: LoadState;
  task: ITask;
  taskLoading: LoadState;
  error?: string | null;
}
export interface ITask {
  task_id: number | null;
  first_name: string | null;
  last_name: string | null;
  task_code: string;
  task_name: string;
  task_created_date: string;
  task_due_date: string;
  task_completed_date: string;
  task_type: string;
  task_sub_type: string;
  task_status: string;
  task_labels: string;
  task_url: string;
  task_priority: string;
  task_related_to: string;
  task_related_to_id: number | null;
  send_email_flag: string;
  assigned_to_id: number | null;
  invite_email: string;
  notes: string;
  status: string;
  insert_ts: string;
  customer_policy_id: number | null;
  policy_number: string | null;
  marketer: string | null;
  marketer_code: string | null;
  producer_one: string | null;
  producer_one_code: string | null;
  producer_two: string | null;
  producer_two_code: string | null;
  producer_three: string | null;
  producer_three_code: string | null;
  branch_code: string | null;
  related_to_customer_code: string | null;
  related_to_customer_name: string | null;
  line_of_business: string | null;
}

export const initialTask: ITask = {
  task_id: null,
  task_code: "",
  first_name: null,
  last_name: null,
  policy_number: null,
  task_name: "Policy Change",
  task_created_date: moment().format(STANDARD_APP_DATE_FORMAT),
  task_due_date: moment().add(5, "days").format(STANDARD_APP_DATE_FORMAT),
  task_completed_date: "",
  task_type: "",
  task_sub_type: "",
  task_status: "",
  task_labels: "",
  task_url: "",
  task_priority: "",
  task_related_to: "Customers",
  task_related_to_id: null,
  send_email_flag: "",
  assigned_to_id: null,
  invite_email: "",
  notes: "",
  status: "ACTIVE",
  insert_ts: "2023-08-17T09:13:15.000Z",
  customer_policy_id: null,
  branch_code: null,
  marketer: null,
  marketer_code: null,
  producer_one_code: null,
  producer_three_code: null,
  producer_two_code: null,
  producer_one: null,
  producer_three: null,
  producer_two: null,
  related_to_customer_code: null,
  related_to_customer_name: null,
  line_of_business: null,
};

export const initialTaskState: ITaskState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  task: initialTask,
  taskLoading: LoadState.NotLoaded,
  error: null,
};

export interface ITaskFileUpload {
  task_code: string;
  attachments: ITaskFileUploadAttachment[];
}

export interface ITaskFileUploadAttachment {
  key: string;
  fileName: string | null;
  url: string | null;
  file: File | null;
}

export interface ITaskFileUploadFetch {
  attachments: ITaskFileUploadAttachment[];
  created_by_id: number;
  insert_ts: string;
  task_code: string;
  task_multi_attachment_id: string;
}
