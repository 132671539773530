import React, { useState } from "react";
import { useDispatchWrapper, useTaskCustomerInfo } from "../../../hooks";
import { ErrorMessage, useFormik } from "formik";
import { Dialog } from "../../../components/Dialogs/Dialog";
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { createNewUserAsync } from "../../../redux/UserProfileList/userProfileActions";
import { IUserCreation } from "../../../redux/UserProfileList/userProfile.types";
import { useNavigate } from "react-router-dom";
import { IAddEDocsProps } from "./AddEDocsDialog.types";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../redux/initialStoreState";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { upsertLegalAysnc } from "../../../redux/legals/legalsActions";
import { upload } from "@testing-library/user-event/dist/upload";
import { FileUpload } from "../../../components/FileUpload/FileUpload";
import { CommentField } from "../../leads/components/CommentField/CommentField";
import { CustomerAutoSearch } from "../../../components/AutoCompleteSearches/CustomerAutoSearch";
import { UsersAutoSearchByRole } from "../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { upsertEDocsAysnc } from "../../../redux/eDocs/eDocsActions";

export const AddEDocsDialog: React.FC<IAddEDocsProps> = (props) => {
  const { eDoc } = useSelector((storeState: IStoreState) => storeState.eDocs);

  const { open, onClose, onComplete, eDocs } = props;
  const [file, setFile] = React.useState<File | null>(null);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: eDocs,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer) {
        errors.customer = "*Customer name is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertEDocsAysnc({ ...values }, (isSuccess) => {
          if (isSuccess) {
            onClose();
            navigate("/legals");
            onComplete();
          }
          setSaveLoading(false);
        })
      );
    },
  });
  const [upload, setUpload] = useState([
    <FileUpload
      value={values.file_attachment}
      onChange={(file) => setFile(file)}
    />,
  ]);

  const { customerDetails, getCustomerDetailsByPolicyNumber } =
    //@ts-ignore
    useTaskCustomerInfo(values.customer);

  const handlePolicyChange = (e: SelectChangeEvent<unknown>) => {
    const policyNumber = e.target.value as number;
    const policy = getCustomerDetailsByPolicyNumber(policyNumber);
    if (policy) {
      setValues({
        ...values,
        policy_number: policy.policy_number,
        branch: policy.branch_code,
        csr: policy.csr_code,
        line_of_business: policy.line_of_business,

        producer: policy.producer_one_code,
      });
    }
  };

  return (
    <Dialog
      open={open}
      title="Create E-Docs"
      onClose={onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Document Type</CustomFormLabel>
          <ControlledCustomSelect
            name="type"
            fullWidth
            value={values.document_type}
            options={[
              { label: "Policy Change", value: "Policy Change" },
              { label: "New Business", value: "New Business" },
              { label: "Renewal", value: "Renewal" },
              { label: "Cancellation", value: "Cancellation" },
              { label: "Reissued", value: "Reissued" },
              { label: "Electronic Memo", value: "Electronic Memo" },
              { label: "Direct Bill Reconciliation", value: "Direct Bill Reconciliation" },
              { label: "Other", value: "Other" },
            ]}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <CustomFormLabel>Description</CustomFormLabel>
          <CustomTextField
            name="customer_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.description}
            // error={errors.customer_name ? true : false}
            // helperText={errors.customer_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CustomerAutoSearch
            label="Customer Name"
            value={values.customer}
            onSelect={(newValue) => {
              setFieldValue("customer", newValue.customer_id);
              setFieldValue("policy_number", null);
            }}
          />
          {/* {errors.customer && <ErrorMessage value={errors.customer} />} */}
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomFormLabel>Policy Number</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            fullWidth
            displayEmpty
            value={values.policy_number}
            onChange={handlePolicyChange}
            placeholder="Select One"
            options={customerDetails.policies.map((item) => {
              return {
                label: item.policy_number,
                value: item.policy_number,
              };
            })}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <CustomFormLabel>Producer</CustomFormLabel>
          <CustomTextField
            id="producer"
            fullWidth
            disabled
            value={values.producer}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CustomFormLabel>CSR</CustomFormLabel>
          <CustomTextField
            id="csr"
            fullWidth
            disabled
            value={values.csr}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Branch</CustomFormLabel>
          <CustomTextField
            id="branch"
            fullWidth
            disabled
            value={values.branch}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Line of Business</CustomFormLabel>
          <CustomTextField
            id="line_of_business"
            fullWidth
            disabled
            value={values.line_of_business}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Status</CustomFormLabel>
          <ControlledCustomSelect
            fullWidth
            name="status"
            value={values.status}
            onChange={handleChange}
            // placeholder="Status"
            // displayEmpty
            options={["Open", "Close"].map((consent) => {
              return { label: consent, value: consent };
            })}
          ></ControlledCustomSelect>
        </Grid>
        <Grid item xs={12} md={3}>
          <UsersAutoSearchByRole
            label="Assigned To"
            value={values.assign_to}
            onSelect={(data) => {
              setFieldValue("assigned_to_id", data.user_fact_id);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Date Created</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.date_created || ""}
            onChange={(newValue) => {
              setFieldValue("date_created", newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Due Date</CustomFormLabel>
          <CustomDatePicker
            fullWidth
            value={values.due_date || ""}
            onChange={(newValue) => {
              setFieldValue("due_date", newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomFormLabel>Time</CustomFormLabel>
          <CustomTimePicker
            fullWidth
            value={moment(values.time)}
            onChange={(newValue) => {
              setFieldValue("time", newValue.toString());
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>File Upload</CustomFormLabel>
          <FileUpload
            value={values.file_attachment}
            onChange={(newFile) => {
              setFile(newFile);
            }}
            onDelete={() => {
              setFile(null);
              setFieldValue("file_attachment", null);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <CustomFormLabel>Notes</CustomFormLabel>
          <CommentField
            comments={
              values.comments && values.comments.length > 0
                ? JSON.parse(values.comments)
                : []
            }
            onChange={(value) =>
              setFieldValue("comments", JSON.stringify(value))
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
