/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../../components/formsComponents";
import {
  useDispatchWrapper,
  useIsAutomobileNewPolicy,
} from "../../../../../../../hooks";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { renderDropdownValue } from "../../../../../../../helpers";
import { IAutomobilePolicyBasicDetailsProps } from "../AutomobileBasicDetails.types";
import {
  addAutomobilePremiumAsync,
  fetchAutomobilePremiumAsync,
} from "../../../../../../../redux/automobileBasicDetails/automobileBasicDetailsActions";
import { CurrencyTextField } from "../../../../../../../components/CurrencyTextField/CurrencyTextField";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const AutomobilePremium: React.FC<IAutomobilePolicyBasicDetailsProps> = (
  props
) => {
  const { customerpolicyId } = props;
  const isNewPolicy = useIsAutomobileNewPolicy();
  const { data, loading } = useSelector(
    (storeState: IStoreState) => storeState.automobile.basicDetails.premium
  );
  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { values, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        addAutomobilePremiumAsync(
          {
            ...values,
            customer_policy_id: customerpolicyId,
          },
          (isSuccess) => {
            setSaveLoading(false);
          }
        )
      );
    },
  });

  React.useEffect(() => {
    if (!isNewPolicy) {
      dispatch(fetchAutomobilePremiumAsync(customerpolicyId));
    }
  }, []);

  React.useEffect(() => {
    setValues(data);
  }, [data]);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Policy Premium</CustomFormLabel>
            <CurrencyTextField
              fullWidth
              value={values.policy_premium}
              onChange={(value) => {
                setFieldValue("policy_premium", value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Agency or Direct Bill</CustomFormLabel>

            <CustomSelect
              variant="outlined"
              size="small"
              type="string"
              name="agency_bill_or_direct_bill"
              fullWidth
              value={values.agency_bill_or_direct_bill}
              onChange={handleChange}
              displayEmpty
              renderValue={renderDropdownValue(`Select one`)}
            >
              {["A", "D"].map((type) => {
                return <MenuItem value={type}>{type}</MenuItem>;
              })}
            </CustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Payment Plan</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.payment_plan}
              name="payment_plan"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Monthly", "Annual", "Quarterly", "Other"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Premium Finance Contract Number</CustomFormLabel>
            <CustomTextField
              name="premium_finance_contract_number"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.premium_finance_contract_number}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Financed By</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.financed_by}
              name="financed_by"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["IFS", "CAFO", "First Insurance Funding", "SNAP"].map(
                (template) => {
                  return { label: template, value: template };
                }
              )}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Default Tax Region</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.default_tax_region}
              name="default_tax_region"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={[
                "AB",
                "BC",
                "MB",
                "NB",
                "NL",
                "NS",
                "NT",
                "NU",
                "ON",
                "PE",
                "QC",
                "SK",
                "YT",
              ].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 1</CustomFormLabel>
            <CustomTextField
              name="tax_1"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_1}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax 2</CustomFormLabel>
            <CustomTextField
              name="tax_2"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={values.tax_2}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel> Tax Exempt </CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.tax_exempt}
              name="tax_exempt"
              onChange={handleChange}
              placeholder="Select one"
              displayEmpty
              options={["Y", "N"].map((template) => {
                return { label: template, value: template };
              })}
            ></ControlledCustomSelect>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <CustomFormLabel>Tax Exempt Reason</CustomFormLabel>
            <CustomTextField
              name="reason"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.reason}
              // error={errors.name ? true : false}
              // helperText={errors.name}
              // disabled={isUpdateLead}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <RoleBasedCustomButton 
          moduleId={MODULE_IDS.AUTOMOBILE}
          type="submit" disabled={saveLoading} variant="contained">
            Save
          </RoleBasedCustomButton>
        </Box>
      </form>
    </PageLoader>
  );
};
