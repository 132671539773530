import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { LoadState } from "../../constants/enums";

export interface IOpportunityState {
  list: IDirectOpportunity[];
  totalRecords: number;
  loading: LoadState;
  opportinity: IDirectOpportunity;
  oppLoading: LoadState;
  error: string | null;
}

export interface IDirectOpportunity {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  lead_id: string;
  lead_source: string | null;
  insurance_type: string | null;
  insurance_sub_type: string | null;
  notes: string | null;
  date: string | null;
  comment: string | null;
  time_to_contact: string | null;
  assigned_to_id: number;
  due_date: string | null;
  amount: string | null;
  type: string | null;
  source: string | null;
  specify: string | null;
  probability: string | null;
  business_name: string | null;
  next_step: string | null;
  description: string | null;
  file_upload: IFileUpload[] | null;
  status: string;
}

export const inititalOpportunityState: IOpportunityState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  opportinity: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    lead_id: "",
    lead_source: null,
    insurance_type: null,
    insurance_sub_type: null,
    notes: null,
    date: "",
    comment: null,
    time_to_contact: null,
    assigned_to_id: 0,
    due_date: null,
    amount: null,
    type: null,
    source: null,
    specify: null,
    probability: null,
    business_name: null,
    next_step: null,
    description: null,
    file_upload: null,
    status: "ACTIVE",
  },
  oppLoading: LoadState.NotLoaded,
  error: null,
};
