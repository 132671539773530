import { ThunkAction } from "redux-thunk";
import {
  IBusinessPolicyMarketingHistory,
  IBusinessPolicyMarketingBinderPdf,
  IBusinessPolicyMatketingAttachment,
  IBusinessBinder,
} from "./businessPolicyMarketing.types";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { action } from "typesafe-actions";
import { ERROR_MESSAGES } from "../../../constants/enums";
import { IInsurerUnderWriter } from "../../insurer/insurer.types";
import { IMail } from "../../common/common.types";
import { sendEmail } from "../../common/commonActions";
import { getUniqueId, uploadFile } from "../../../helpers";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { uploadMultipleFile } from "../../../components/FileUpload/utils";

export const businessPolicyPreviewMarketingAsync =
  (
    data: IBusinessPolicyMarketingBinderPdf,
    onCallback: (isSuccess: boolean, html?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post(
        `/policy/market-binder-submission-pdf-or-preview`,
        data
      );

      onCallback(true, res.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const businessPolicyGenerateMarketCoveragePdfAsync =
  (
    data: IBusinessPolicyMarketingBinderPdf,
    onCallback: (isSuccess: boolean, data?: any) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post(
        `/policy/market-binder-submission-pdf-or-preview`,
        data
      );

      onCallback(true, res?.data);
      //dispatch(fetchBusinessMarketingAttachmentsAsync(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const businessPolicySendCoverageMailAndHistAsync =
  (
    data: IBusinessPolicyMarketingBinderPdf,
    keys: Array<string>,
    bccEmail: string,
    subject: string,
    text: string,
    underWriters: IInsurerUnderWriter[],
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const bufferJson = await api.post("/general/download-files", {
        type: "json",
        keys,
      });
      const mailPayload: IMail = {
        emails: [],
        bcc: bccEmail ? [bccEmail] : [],
        cc: [],
        subject: subject,
        body: text,
        templateName: null,
        objectVariables: {},
        attachments: bufferJson?.data?.map((ele: any, index: number) => ({
          content: ele,
          filename: keys[index].split("/").at(-1),
        })),
      };

      const historyPayload: IBusinessPolicyMarketingHistory[] = [];

      // mailPayload.attachments = attachmentResponse.data;
      // const a = Buffer.from(attachmentResponse.data);
      // console.log(a);

      for (const item of underWriters) {
        mailPayload.emails.push(item.email);
        historyPayload.push({
          customer_policy_id: data.customer_policy_id,
          quote_number: null,
          serial_number: null,
          premium: null,
          date_received: null,
          attachment: [],
          notes: null,
          status: "ACTIVE",
          underwriter: item.name,
          insurer: item.insurer_code,
          submission_date: item.insert_ts,
          effective_date: null,
          quote_status: "Submitted",
          customer_name: "",
        });
      }
      await api.post("/general/send-email", mailPayload);

      dispatch(
        showMessage({
          type: "success",
          message: "Email and Marketing history saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_PROGRESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_PROGRESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_SUCCESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_SUCCESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_FAILED =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_FAILED";

export const fetchBusinessMarketingAttachmentsProgress = () =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_PROGRESS);
export const fetchBusinessMarketingAttachmentsSuccesss = (
  data: IBusinessPolicyMatketingAttachment[]
) =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_SUCCESS, { data });

export const fetchBusinessMarketingAttachmentsFailed = () =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_ATTACHMENTS_FAILED);

export const fetchBusinessMarketingAttachmentsAsync =
  (
    payload: IBusinessPolicyMarketingBinderPdf
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessMarketingAttachmentsProgress());
      const res = await api.get(
        `/customer/get-customer-marketing-history-details?customer_policy_id=${payload.customer_policy_id}`
      );
      const data: IBusinessPolicyMatketingAttachment[] = res.data.data;
      dispatch(
        fetchBusinessMarketingAttachmentsSuccesss(
          data.filter((x) => x.attachment !== null)
        )
      );
    } catch (err: any) {
      dispatch(fetchBusinessMarketingAttachmentsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_FAILED";

export const fetchBusinessPolicyMarketingHistListProgress = () =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_PROGRESS);
export const fetchBusinessPolicyMarketingHistListSuccess = (
  data: IBusinessPolicyMarketingHistory[]
) => action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_SUCCESS, { data });
export const fetchBusinessPolicyMarketingHistListFailed = () =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_LIST_FAILED);

export const fetchBusinessPolicyMarketingHistListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyMarketingHistListProgress());
      const res = await api.get(
        `/policy/get-marketing-history?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessPolicyMarketingHistory[] = res.data.data;
      dispatch(fetchBusinessPolicyMarketingHistListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyMarketingHistListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_BUSINESS_POLICY_BINDER_LIST_PROGRESS =
  "FETCH_BUSINESS_POLICY_BINDER_LIST_PROGRESS";
export const FETCH_BUSINESS_POLICY_BINDER_LIST_SUCCESS =
  "FETCH_BUSINESS_POLICY_BINDER_LIST_SUCCESS";
export const FETCH_BUSINESS_POLICY_BINDER_LIST_FAILED =
  "FETCH_BUSINESS_POLICY_BINDER_LIST_FAILED";

export const fetchBusinessPolicyBindertListProgress = () =>
  action(FETCH_BUSINESS_POLICY_BINDER_LIST_PROGRESS);
export const fetchBusinessPolicyBindertListSuccess = (
  data: IBusinessBinder[]
) => action(FETCH_BUSINESS_POLICY_BINDER_LIST_SUCCESS, { data });
export const fetchBusinessPolicyBindertListFailed = () =>
  action(FETCH_BUSINESS_POLICY_BINDER_LIST_FAILED);

export const fetchBusinessPolicyBinderistListAsync =
  (customerPolicyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyBindertListProgress());
      const res = await api.get(
        `/policy/get-binder-details?customer_policy_id=${customerPolicyId}`
      );
      const data: IBusinessBinder[] = res.data.data;
      dispatch(fetchBusinessPolicyBindertListSuccess(data));
    } catch (err: any) {
      dispatch(fetchBusinessPolicyBindertListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const addBusinesPolicyMarketingHistAsync =
  (
    data: IBusinessPolicyMarketingHistory,
    ID: number,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const asPayload = {
        customer_id: ID,
        policy_type: "BUSINESS",
        customer_policy_id: data.customer_policy_id,
        page_name: "Marketing",
      };
      const { paths } = await uploadMultipleFile(
        data.attachment,
        "POLICY",
        asPayload
      );
      await api.post(`/policy/edit-policy-add-marketing-history`, {
        ...data,
        attachment: paths,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Marketing History saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_PROGRESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_PROGRESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_SUCCESS =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_SUCCESS";
export const FETCH_BUSINESS_POLICY_MARKETING_HISTORY_FAILED =
  "FETCH_BUSINESS_POLICY_MARKETING_HISTORY_FAILED";

export const fetchBusinessPolicyMarketingHistProgress = () =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_PROGRESS);
export const fetchBusinessPolicyMarketingHistSuccess = (
  data: IBusinessPolicyMarketingHistory
) => action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_SUCCESS, { data });
export const fetchBusinessPolicyMarketingHistFailed = (errorMessage: string) =>
  action(FETCH_BUSINESS_POLICY_MARKETING_HISTORY_FAILED, { errorMessage });

export const fetchBusinessPolicyMarketingHistAsync =
  (
    customerPolicyId: number,
    marketingHistNumber: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBusinessPolicyMarketingHistProgress());
      const res = await api.get(
        `/policy/get-marketing-history?customer_policy_id=${customerPolicyId}&column=serial_number&value=${marketingHistNumber}`
      );
      const data: IBusinessPolicyMarketingHistory[] = res.data.data;
      if (data.length > 0) {
        const attachments: IFileUpload[] = [];
        for (const item of data[0].attachment as any) {
          attachments.push({
            file: null,
            key: getUniqueId(),
            path: item,
          });
        }
        dispatch(
          fetchBusinessPolicyMarketingHistSuccess({
            ...data[0],
            attachment: attachments,
          })
        );
      } else {
        dispatch(
          fetchBusinessPolicyMarketingHistFailed(
            "Oops! We couldn't find any records associated with your number at the moment. Please ensure that the provided information is accurate and try again."
          )
        );
      }
    } catch (err: any) {
      dispatch(
        fetchBusinessPolicyMarketingHistFailed(ERROR_MESSAGES.SERVER_ERROR)
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const businessPolicyBinderSendCoverageMailAndHistAsync =
  (
    data: IBusinessPolicyMarketingBinderPdf,
    keys: Array<string>,
    bccEmail: string,
    subject: string,
    text: string,
    customerMail: string,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const bufferJson = await api.post("/general/download-files", {
        type: "json",
        keys,
      });
      const mailPayload: IMail = {
        emails: [customerMail],
        bcc: bccEmail ? [bccEmail] : [],
        cc: [],
        subject: subject,
        body: text,
        templateName: null,
        objectVariables: {},
        attachments: bufferJson?.data?.map((ele: any, index: number) => ({
          content: ele,
          filename: keys[index].split("/").at(-1),
        })),
      };

      const historyPayload: IBusinessPolicyMarketingHistory[] = [];

      // mailPayload.attachments = attachmentResponse.data;
      // const a = Buffer.from(attachmentResponse.data);
      // console.log(a);

      await api.post("/general/send-email", mailPayload);

      for (const payload of historyPayload) {
        // await api.post(`/policy/edit-policy-add-marketing-history`, payload);
      }
      dispatch(
        showMessage({
          type: "success",
          message: "Email and Marketing history saved successfully!",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
      onCallback(false);
    }
  };

export const CLEAR_BUSINESS_POLICY_MARKETING_STATE =
  "CLEAR_BUSINESS_POLICY_MARKETING_STATE";
export const CLEAR_BUSINESS_POLICY_MARKETING_HISTORY =
  "CLEAR_BUSINESS_POLICY_MARKETING_HISTORY";

export const clearBusinessPolicyMarketingState = () =>
  action(CLEAR_BUSINESS_POLICY_MARKETING_STATE);
export const clearBusinessPolicyMarketingHistory = () =>
  action(CLEAR_BUSINESS_POLICY_MARKETING_HISTORY);
