/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  IDataTableProps,
  RenderType,
} from "../../../../../../../components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../../../../components/Table/DataTable";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { LoadState } from "../../../../../../../constants/enums";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import { ICommAutomobileDriverActivity } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriver.types";
import { fetchCommAutomobileDriverTasksListAsync } from "../../../../../../../redux/commAutoPolicy/commAutoDriver/commAutoDriverActions";

export const CommAutomobileDriverActivityList: React.FC<{
  customerId: number;
  customerPolicyId: number;
}> = (props) => {
  const { customerId, customerPolicyId } = props;
  const navigate = useNavigate();
  const { driverActivityList, driverActivityListLoading } = useSelector(
    (storeState: IStoreState) => storeState.commAuto.driver.driverActivity
  );

  const dispatch = useDispatchWrapper();


  const automobileDriverTableProps: IDataTableProps = {
    isPagination: false,
    isDataLoading: driverActivityListLoading === LoadState.InProgress,
    tableCommandBarProps: {
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task`
                  );
                }}
              >
                Order Insurance Report
              </Button>
            ),
          },
        ],
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        onRowCellRender: (value, row: ICommAutomobileDriverActivity) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "driver_name",
        headerName: "Driver Name",
        fieldName: "driver_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        onRowCellRender: (value, row: ICommAutomobileDriverActivity) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            >
              {row.driver_name}
            </Link>
          );
        },
      },
      {
        key: "license_number",
        headerName: "License Number",
        fieldName: "license_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
      },

      {
        key: "business_reason",
        headerName: "Business Reason",
        fieldName: "business_reason",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },
      {
        key: "transaction_type",
        headerName: "Transaction Type",
        fieldName: "transaction_type",
        exportCellWidth: 25,
        renderType: RenderType.CHIP_WARNING,
      },
      {
        key: "request_status",
        headerName: "Report Requested",
        fieldName: "request_status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign:'center',
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: ICommAutomobileDriverActivity) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/comm-automobile-policy/${customerId}/driver-tab/${customerPolicyId}/activity-task/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
    ],
    items: driverActivityList,
  };

  React.useEffect(() => {
    dispatch(fetchCommAutomobileDriverTasksListAsync(customerPolicyId));
  }, [customerPolicyId]);


  return (
    <Box sx={{ mt: 2 }}>
      <DataTable {...automobileDriverTableProps} />
    </Box>
  );
};
