import { api } from "../../../api/api";
import { IFileUpload } from "../FileUpload.type";

export const uploadMultipleFile = async (
  files: IFileUpload[],
  module_name: string,
  asPayload: {
    [key: string]: string | number | null;
  }
) => {
  let filesCount = 0;
  const formdata = new FormData();
  for (let file of files) {
    if (file.file) {
      filesCount++;
      formdata.append("files", file.file);
    }
  }
  formdata.append("module_name", module_name);
  formdata.append("as_payload", JSON.stringify(asPayload));
  if (filesCount === 0) {
    return {files,
        paths: files.map((item) => item.path),
    };
  }
  const res = await api.post("/general/upload", formdata);
  const data: string[] = res.data.data;
  const finalList = [];
  let i = 0;
  if (data.length > 0) {
    for (let item of files) {
      const url = data[i];
      const obj = { ...item };
      if (item.file) {
        obj.file = null;
        obj.path = url;
        i++;
      }
      finalList.push(obj);
    }
    return {
        files: finalList,
        paths: finalList.map((item) => item.path),
      };
  }

  return {
    files,
    paths: files.map((item) => item.path),
  };
};
