import { action } from "typesafe-actions";
import { IInterestedParty } from "./IntrestedParties.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  ADD_ADDITIONAL_INTEREST,
  ADD_ADDITIONAL_INTEREST_PROGRESS,
  ADD_INTERESTED_PARTY,
  ADD_INTERESTED_PARTY_PROGRESS,
  CLEAR_ADDITIONAL_INTEREST_STATE,
  CLEAR_INTERESTED_PARTY_STATE,
  FETCH_ADDITIONAL_INTEREST_PROGRESS,
  FETCH_ADDITIONAL_INTEREST_SUCCESS,
  FETCH_INTERESTED_PARTY_PROGRESS,
  FETCH_INTERESTED_PARTY_SUCCESS,
} from "../constants";
import { getUserAuth } from "../../helpers";

export const FETCH_INTRESTED_PARTIES_LIST_PROGRESS =
  "FETCH_INTRESTED_PARTIES_LIST_PROGRESS";
export const FETCH_INTRESTED_PARTIES_LIST_SUCCESS =
  "FETCHE_INTRESTED_PARTIES_LIST_SUCCESS";
export const FETCH_INTRESTED_PARTIES_LIST_FAILED =
  "FETCH_INTRESTED_PARTIES_LIST_FAILED";

export const fetchBrokerageIntrestedPartiesProgress = () =>
  action(FETCH_INTRESTED_PARTIES_LIST_PROGRESS);
export const fetchBrokerageIntrestedPartiesSuccess = (
  data: IInterestedParty[],
  totalRecords: number
) => action(FETCH_INTRESTED_PARTIES_LIST_SUCCESS, { data, totalRecords });
export const fetchBrokerageIntrestedPartiesFailed = () =>
  action(FETCH_INTRESTED_PARTIES_LIST_FAILED);

export const fetchBrokerageIntrestedAllPartiesAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBrokerageIntrestedPartiesProgress());

      const res = await api.get(
        `/insurance/get-interested-party?status=ACTIVE`
      );
      const data: IInterestedParty[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBrokerageIntrestedPartiesSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBrokerageIntrestedPartiesFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

// export const upsertBrokerageInterestedPartyeAsync =
//   (
//     data: IInterestedParty,
//     onCallback: (isSuccess: boolean) => void
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       await api.post("/insurance/create-interested-party", data);

//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Interested Party saved successfully!",
//           displayAs: "snackbar",
//         })
//       );
//       onCallback(true);
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         })
//       );
//     }
//   };

/** */

export const INTERESTED_PARTY_ERROR = "INTERESTED_PARTY_ERROR";
export const ADD_INTERESTED_PARTY_ERROR = "ADD_INTERESTED_PARTY_ERROR";

export const addInterestedPartyProgress = () =>
  action(ADD_INTERESTED_PARTY_PROGRESS);

export const addInterestedParty = (data: IInterestedParty) =>
  action(ADD_INTERESTED_PARTY, { data: data });

export const addInterestedPartyAsync =
  (
    data: IInterestedParty,
    onSuccess: () => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      dispatch(addInterestedPartyProgress());
      await api.post("/insurance/create-interested-party", data);
      dispatch(addInterestedParty(data));
      dispatch(
        showMessage({
          type: "success",
          message: "Interested Party saved successfully!",
          displayAs: "snackbar",
        })
      );
      onSuccess();
    } catch (err: any) {
      dispatch(InterestedPartyError(null));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchInterestedPartyProgress = () =>
  action(FETCH_INTERESTED_PARTY_PROGRESS);

export const fetchInterestedPartySuccess = (data: IInterestedParty) =>
  action(FETCH_INTERESTED_PARTY_SUCCESS, { data: data });

export const fetchInterestedPartyAsync =
  (InterestedPartyId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();
    try {
      if (token) {
        dispatch(fetchInterestedPartyProgress());
        const res = await api.get(
          `/insurance/get-interested-party?value=${InterestedPartyId}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        const data: IInterestedParty[] = res.data.data;
        if (data.length > 0) {
          dispatch(addInterestedParty(data[0]));
        } else {
          dispatch(
            InterestedPartyError(
              "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
            )
          );
        }
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const InterestedPartyError = (message: string | null) =>
  action(INTERESTED_PARTY_ERROR, {
    message: message,
  });

export const clearInterestedPartyState = () =>
  action(CLEAR_INTERESTED_PARTY_STATE);

// export const ADDITIONAL_INTEREST_ERROR = "ADDITIONAL_INTEREST_ERROR";
// export const ADD_ADDITIONAL_INTEREST_ERROR = "ADD_ADDITIONAL_INTEREST_ERROR";

// export const addAdditionalInterestProgress = () =>
//   action(ADD_ADDITIONAL_INTEREST_PROGRESS);

// export const addAdditionalInterest = (data: IAdditionalInterest) =>
//   action(ADD_ADDITIONAL_INTEREST, { data: data });

// export const addAdditionalInterestAsync =
//   (
//     data: IAdditionalInterest,
//     onSuccess: () => void
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(addAdditionalInterestProgress());
//       await api.post("/insurance/create-additional-interest", data);
//       dispatch(addAdditionalInterest(data));
//       dispatch(
//         showMessage({
//           type: "success",
//           message: "Additional Interest saved successfully!",
//           displayAs: "snackbar",
//         })
//       );
//       onSuccess();
//     } catch (err: any) {
//       dispatch(AdditionalInterestError(null));
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         })
//       );
//     }
//   };

// export const fetchAdditionalInterestProgress = () =>
//   action(FETCH_ADDITIONAL_INTEREST_PROGRESS);

// export const fetchAdditionalInterestSuccess = (data: IAdditionalInterest) =>
//   action(FETCH_ADDITIONAL_INTEREST_SUCCESS, { data: data });

// export const fetchAdditionalInterestAsync =
//   (
//     AdditionalInterestId: string
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     const token = getUserAuth();
//     try {
//       if (token) {
//         dispatch(fetchAdditionalInterestProgress());
//         const res = await api.get(
//           `/insurance/get-additional-interest?value=${AdditionalInterestId}`,
//           {
//             headers: {
//               "auth-Token": token,
//             },
//           }
//         );
//         const data: IAdditionalInterest[] = res.data.data;
//         if (data.length > 0) {
//           dispatch(addAdditionalInterest(data[0]));
//         } else {
//           dispatch(
//             InterestedPartyError(
//               "Oops! We couldn't find any records associated with your branch office at the moment. Please ensure that the provided information is accurate and try again."
//             )
//           );
//         }
//       }
//     } catch (err: any) {
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         })
//       );
//     }
//   };

// export const AdditionalInterestError = (message: string | null) =>
//   action(ADDITIONAL_INTEREST_ERROR, {
//     message: message,
//   });

// export const clearAdditionalInterestState = () =>
//   action(CLEAR_ADDITIONAL_INTEREST_STATE);
