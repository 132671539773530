/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  useCommAutoCustomerBasicDetails,
  useCustomerFullInfo,
  useDispatchWrapper,
  useFetchPolicies,
} from "../../../../../../hooks";

import { showMessage } from "../../../../../../redux/messages/messagesActions";
import { Dialog } from "../../../../../../components/Dialogs/Dialog";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import {
  CustomCheckBoxWithLabel,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import { api } from "../../../../../../api/api";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { ICommAutoPolicyMarketingBinderPdf } from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistory.types";
import {
  commAutoPolicyBinderSendCoverageMailAndHistAsync,
  commAutoPolicyGenerateMarketCoveragePdfAsync,
  commAutoPolicyPreviewMarketingAsync,
} from "../../../../../../redux/commAutoPolicy/commAutoMarketingHistory/commAutoMarketingHistoryActions";
import { downLoadFile } from "../../../../../../components/FileUpload/utils";

const INITIAL_MARKETING_PREVIEW: ICommAutoPolicyMarketingBinderPdf = {
  customer_policy_id: 0,
  isMarketSubmissionPreview: false,
  pdfOrPreviewFor: "",
  isBinderPreview: false,
  isBinderPdf: false,
  generalInformation: true,
  schedule: true,
  priorPolicy: true,
  claims: true,
  convictions: true,
  vehicleSchedule: true,
  coverageSummary: true,
};

const steps = ["Content Selection", "Send Proposal to Insurer"];

export const BinderDialog: React.FC<{
  open: boolean;
  customerPolicyId: number;
  onClose: () => void;
  onComplete: () => void;
}> = (props) => {
  const { open, customerPolicyId, onClose } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingPreview, setOpenLoadingPreview] = React.useState(false);
  const { policies, getPolicySubtypes } = useFetchPolicies();

  const dispatch = useDispatchWrapper();
  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [filesUrl, setFilesUrl] = useState<string[]>([]);
  const data = useCustomerFullInfo();
  const customerBasicDetail = useCommAutoCustomerBasicDetails(customerPolicyId);

  let subType = getPolicySubtypes(
    customerBasicDetail.data.line_of_business || ""
  ).find((item) => {
    return Number(customerBasicDetail.data.sub_type) === item.policy_id
      ? item.name
      : "";
  });

  if(subType?.name === "IRCA/Scheduled"){
    subType = {...subType, name: "IRCA"};
  } else if (subType?.name === "Blanket Fleet"){
    // subType.name = "Fleet";
    subType = {...subType, name: "Fleet"}
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      req_for_quotation: true,
      preview: INITIAL_MARKETING_PREVIEW,

      email_body: "",
      subject: "",
      bcc_email: "",
    },
    validate: (values) => {
      if (activeStep === 1) {
        const errors: any = {};
        if (!values.subject) {
          errors.subject = "*Subject is required.";
        } else if (!values.email_body) {
          errors.email_body = "*Body is required.";
        }
        return errors;
      }
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      if (activeStep === 0 && subType) {
        dispatch(
          commAutoPolicyGenerateMarketCoveragePdfAsync(
            {
              ...values.preview,
              customer_policy_id: customerPolicyId,
              isBinderPdf: true,
              pdfOrPreviewFor: subType.name,
              isMarketSubmissionPreview: false,
            },
            (isSuccess: boolean, data: any) => {
              if (isSuccess) {
                setActiveStep(1);
                setFilesUrl([...data?.data]);
              }
              setSaveLoading(false);
            }
          )
        );
      } else {
        if (data) {
          dispatch(
            commAutoPolicyBinderSendCoverageMailAndHistAsync(
              { ...values.preview, customer_policy_id: customerPolicyId },
              filesUrl,
              values.bcc_email,
              values.subject,
              values.email_body,
              data.email,
              (isSuccess: any) => {
                if (isSuccess) {
                  props.onComplete();
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleNext = (step: number) => () => {
    if (activeStep === 1) {
      setActiveStep(step);
    }
  };

  const handlePreview = async () => {
    setOpenLoadingPreview(true);
    if (subType) {
      dispatch(
        commAutoPolicyPreviewMarketingAsync(
          {
            ...values.preview,
            customer_policy_id: customerPolicyId,
            isBinderPreview: true,
            pdfOrPreviewFor: subType.name,
            isMarketSubmissionPreview: false,
          },
          (isSuccess, htmlJson) => {
            if (isSuccess && htmlJson) {
              for (const key in htmlJson.data) {
                const newTab = window.open("", "_blank");
                if (newTab) {
                  newTab.document.write(htmlJson.data[key]);
                  newTab.document.close();
                }
              }
            }
            setOpenLoadingPreview(false);
          }
        )
      );
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/policy/get-is-record-exist-in-every-commauto-module?customer_policy_id=${customerPolicyId}`
        );
        setValues({
          ...values,
          preview: {
            customer_policy_id: customerPolicyId,
            ...response.data.data,
          },
        });
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      open={open}
      title="Binder Submission"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: loadingPreview ? "Wait..." : "Preview",
          variant: "contained",
          disabled: loadingPreview,
          onClick: handlePreview,
        },
        {
          type: "submit",
          label:
            activeStep === 0 ? (
              "Save & Next"
            ) : saveLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Save"
            ),
          disabled: !values.req_for_quotation || saveLoading,
          variant: "contained",
        },
      ]}
    >
      <PageLoader loading={loading}>
        <>
          <Stepper activeStep={activeStep} variant="outlined">
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps} onClick={handleNext(index)}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ mt: 2 }}>
            {activeStep === 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Request for Quotation"
                      name="req_for_quotation"
                      checked={values.req_for_quotation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="General Information"
                      name="preview.generalInformation"
                      checked={values.preview.generalInformation}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Schedule"
                      name="preview.schedule"
                      checked={values.preview.schedule}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Prior Policies"
                      name="preview.priorPolicy"
                      checked={values.preview.priorPolicy}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Claims"
                      name="preview.claims"
                      checked={values.preview.claims}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Convictions"
                      name="preview.convictions"
                      checked={values.preview.convictions}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Vehicle Schedule"
                      name="preview.vehicleSchedule"
                      checked={values.preview.vehicleSchedule}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCheckBoxWithLabel
                      label="Coverage Summary"
                      name="preview.coverageSummary"
                      checked={values.preview.coverageSummary}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h4" fontWeight={"bold"}>
                  Enter the Subject and Message Body Below
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>BCC Emails</CustomFormLabel>
                    <CustomTextField
                      name="bcc_email"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.bcc_email}
                      // error={errors.name ? true : false}
                      // helperText={errors.name}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>Subject</CustomFormLabel>
                    <CustomTextField
                      name="subject"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.subject}
                      error={errors.subject ? true : false}
                      helperText={errors.subject}
                      // disabled={isUpdateLead}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CustomFormLabel>Email Body</CustomFormLabel>
                    <CustomTextField
                      name="email_body"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={values.email_body}
                      error={errors.email_body ? true : false}
                      helperText={errors.email_body}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h4" fontWeight={"bold"} sx={{ mt: 2 }}>
                  Download All Files
                </Typography>
                {/* {attachmentLoading === LoadState.InProgress && (
                  <Typography sx={{ color: "primary.main" }}>
                    Please wait....
                  </Typography>
                )} */}
                {filesUrl.map((attachment) => {
                  // const data = attachment.split("/");
                  return (
                    <Typography
                      fontSize={"1rem"}
                      sx={{ color: "primary.main", cursor: "pointer" }}
                      onClick={() => {
                        downLoadFile(attachment);
                      }}
                    >
                      {attachment}
                    </Typography>
                  );
                })}
              </>
            )}
          </Box>
        </>
      </PageLoader>
    </Dialog>
  );
};
