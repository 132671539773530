import { api } from "../api/api";
import { IPolicy } from "../views/Policies/Policies.types";

export const getAllCustomerPolicyId = async (
  customerId: number | string | undefined
) => {
  if (customerId) {
    const res = await api.get(
      `/policy/get-all-customer-policy-details?customer_id=${customerId}`
    );
    const data: IPolicy[] = res.data.data;
    return data;
    
  }
};
