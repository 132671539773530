import { Avatar, Box, Button, Link, MenuItem, Typography } from "@mui/material";
import React from "react";
import { StandadCard } from "../../components/Cards";
import { DataTable } from "../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { useDispatchWrapper } from "../../hooks";
import { ILegal, initialLegal } from "../../redux/legals/legals.types";

import { AddOutlined } from "@mui/icons-material";
import moment from "moment";
import { RoleBasedCSVExportPlugin } from "../../security/RoleBasedComponents/RoleBasedCSVExportPlugin/RoleBasedCSVExportPlugin";
import { StandardTableActions } from "../../components/StandardTableActions/StandardTableActions";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { RefreshPlugin, SearchPlugin } from "../../components/Table/plugins";
import { CustomSelect } from "../../components/formsComponents";
import { CustomDatePicker } from "../../components/formsComponents/CustomDatePicker";
import {
  clearLegalsState,
  fetchLegalsListAsync,
} from "../../redux/legals/legalsActions";
import { AddEDocsDialog } from "./AddEDocsDialog/AddEDocsDialog";
import { IEDocs, initialEDocs } from "../../redux/eDocs/eDocs.types";
import { MODULE_IDS } from "../../constants/enums";

export const EDocs: React.FC = () => {
  //   const { list, loading } = useSelector(
  //     (storeState: IStoreState) => storeState.legals
  //   );
  const dispatch = useDispatchWrapper();
  const [openLegal, setOpenLegal] = React.useState<IEDocs | null>(null);
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [status, setStatus] = React.useState<ILegal["status"] | "-1">("-1");
  const [searchType, setSearchType] = React.useState<string[]>([
    "customer_name",
  ]);
  const [search, setSearch] = React.useState<string>("");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "lastWeek" });
  const handleComplete = () => {
    dispatch(
      fetchLegalsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
    setOpenLegal(null);
  };
  const eDocsTableProps: IDataTableProps = {
    isPagination: false,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    setDate({ ...date, fromDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    setDate({ ...date, toDate: newValue });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <RoleBasedCSVExportPlugin
                columns={columns}
                items={items}
                moduleId={MODULE_IDS.LEGAL}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Customer Name", value: "customer_name" },
                    { label: "Policy Number", value: "policy_number" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#000",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
        enableSorting: true,
      },
      {
        key: "incremental_number",
        headerName: "Document type",
        fieldName: "incremental_number",
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 15,
      },
      {
        key: "policy_number",
        headerName: "Policy",
        fieldName: "policy_number",
        renderType: RenderType.CHIP_WARNING,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "document_type",
        headerName: "Insured",
        fieldName: "document_type",
        enableSorting: false,
        renderType: RenderType.CUSTOM_RENDER,
        exportCellWidth: 25,
        onRowCellRender: (value, row: IEDocs) => {
          return (
            <Link sx={{ cursor: "pointer" }} onClick={() => setOpenLegal(row)}>
              {row.customer_name}
            </Link>
          );
        },
      },
      {
        key: "customer",
        headerName: "Descripition",
        fieldName: "customer",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "effective_date",
        headerName: "Producer",
        fieldName: "effective_date",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "effective_date",
        headerName: "CSR",
        fieldName: "effective_date",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "expiry_date",
        headerName: "Branch",
        fieldName: "expiry_date",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        enableSorting: false,
      },
      {
        key: "defendant",
        headerName: "Policy Type",
        fieldName: "defendant",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: false,
      },
      {
        key: "plaintiff",
        headerName: "Status",
        fieldName: "plaintiff",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        enableSorting: false,
      },
      {
        key: "plaintiff",
        headerName: "Type",
        fieldName: "plaintiff",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        enableSorting: false,
      },
      {
        key: "plaintiff",
        headerName: "Effective on",
        fieldName: "plaintiff",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        enableSorting: false,
      },
      {
        key: "plaintiff",
        headerName: "Issued on",
        fieldName: "plaintiff",
        renderType: RenderType.TEXT,
        exportCellWidth: 25,
        enableSorting: false,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                setOpenLegal(row);
              }}
            />
          );
        },
      },
    ],
    items: [],
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  const fetchList = () => {
    dispatch(
      fetchLegalsListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType
      )
    );
  };
  React.useEffect(() => {
    fetchList();
  }, [status, date, search, searchType, tablePagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearLegalsState());
    };
  }, []);

  return (
    <>
      <StandadCard
        heading="eDocs"
        rightHeading={
          <Button
            variant="contained"
            startIcon={<AddOutlined />}
            onClick={() => {
              setOpenLegal(initialEDocs);
            }}
          >
            Create
          </Button>
        }
      >
        <DataTable {...eDocsTableProps} />
        {openLegal && (
          <AddEDocsDialog
            open={true}
            eDocs={openLegal}
            onClose={() => setOpenLegal(null)}
            onComplete={handleComplete}
          />
        )}
      </StandadCard>
    </>
  );
};
