import moment, { Moment } from "moment";
import { LoadState } from "../../../constants/enums";
import { getUniqueId } from "../../../helpers";

export interface IHabitationalNonCsioBinderState {
  data: IHabitationalNonCsioBinder[];
  loading: LoadState;
  nonCsio: IHabitationalNonCsioBinder;
  nonCsioLoading: LoadState;
  error: string | null;
}

export interface IHabitationalNonCsioBinder {
    customer_policy_id: number,
    incremental_number: number | null,
    insurance_company: string | null,
    policy_binder_number: string | null,
    binder_effective_date: string,
    binding_time: Moment,
    applicant_full_name: string | null,
    applicant_address: string | null,
    applicant_postal_code: string | null,
    applicant_phone: string | null,
    applicant_mobile: string | null,
    applicant_preferred_document_language: string | null,
    applicant_email_address: string | null,
    applicant_website_address: string | null,
    brokerage_full_name: string | null,
    brokerage_address: string | null,
    brokerage_postal_code: string | null,
    brokerage_code: string | null,
    brokerage_contact_name: string | null,
    brokerage_phone: string | null,
    brokerage_fax: string | null,
    brokerage_contract_number: string | null,
    brokerage_sub_contract_number: string | null,
    brokerage_group_name: string | null,
    brokerage_group_id: string | null,
    broker_client_id: string | null,
    brokerage_company_client_id: string | null,
    policy_effective_date: string,
    policy_effective_time: Moment,
    policy_expiry_date: string,
    risk_location_and_coverage:[
      {
        key: string,
        Risk_Location_Address: string | null,
        Package_Form_and_Type: string | null,
        Deductible: string | null,
        Single_Limit: string | null,
        Dwelling_Limit: string | null,
        Detached_Private_Structure: string | null,
        Personal_Property: string | null,
        Additional_Living_Expense: string | null,
        Legal_Liability: string | null,
        Voluntary_Medical_Payments: string | null,
        Voluntary_Property_Damage: string | null      
      }
    ],
    mortgagee_one: string | null,
    nature_of_interest_one: string | null,
    mortgagee_two: string | null,
    nature_of_interest_two: string | null,
    mortgagee_three: string | null,
    nature_of_interest_three: string | null,
    remarks: string | null,
    other_broker_name: string | null,
    signature_of_broker: string | null,
    other_date: string,
    status: string | null
  }
  

export const initialHabitationalNonCsioBinder: IHabitationalNonCsioBinder = {
    customer_policy_id: 0,
    incremental_number: null,
    insurance_company: null,
    policy_binder_number: null,
    binder_effective_date: moment().format("YYYY-MM-DD"),
    binding_time: moment(),
    applicant_full_name: null,
    applicant_address: null,
    applicant_postal_code: null,
    applicant_phone: null,
    applicant_mobile: null,
    applicant_preferred_document_language: null,
    applicant_email_address: null,
    applicant_website_address: null,
    brokerage_full_name: null,
    brokerage_address: null,
    brokerage_postal_code: null,
    brokerage_code: null,
    brokerage_contact_name: null,
    brokerage_phone: null,
    brokerage_fax: null,
    brokerage_contract_number: null,
    brokerage_sub_contract_number: null,
    brokerage_group_name: null,
    brokerage_group_id: null,
    broker_client_id: null,
    brokerage_company_client_id: null,
    policy_effective_date: moment().format("YYYY-MM-DD"),
    policy_effective_time: moment(),
    policy_expiry_date: moment().add(1, "year").format("YYYY-MM-DD"),
    risk_location_and_coverage:[
      
      {
        key: getUniqueId(),
        Risk_Location_Address: null,
        Package_Form_and_Type: null,
        Deductible: null,
        Single_Limit: null,
        Dwelling_Limit: null,
        Detached_Private_Structure: null,
        Personal_Property: null,
        Additional_Living_Expense: null,
        Legal_Liability: null,
        Voluntary_Medical_Payments: null,
        Voluntary_Property_Damage: null
      }
    ],
    mortgagee_one: null,
    nature_of_interest_one: null,
    mortgagee_two: null,
    nature_of_interest_two: null,
    mortgagee_three: null,
    nature_of_interest_three: null,
    remarks: null,
    other_broker_name: null,
    signature_of_broker: null,
    other_date: moment().format("YYYY-MM-DD"),
    status: "ACTIVE"
  }
  

export const initialHabitationalNonCsioBinderState: IHabitationalNonCsioBinderState =
  {
    data: [],
    loading: LoadState.NotLoaded,
    nonCsio: initialHabitationalNonCsioBinder,
    nonCsioLoading: LoadState.NotLoaded,
    error: null,
  };
